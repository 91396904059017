import React from "react";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import PaymentPolicies from "./PaymentPolicies";

// Image Imports
import mastercard from "../assets/images/mastercard.png";
import Visa from "../assets/images/visa.png";
import AmericanExpress from "../assets/images/AmericanExpressLogo.png";

function RenderMokaPayment({
  submitPayment,
  mokaOnChange,
  submitButtonLoading,
}) {
  let width = window.innerWidth;
  function ButtonState() {
    if (submitButtonLoading === false) {
      return (
        <Button
          type="submit"
          variant="contained"
          style={{
            marginTop: 40,
            marginBottom: 50,
            height: 50,
            width: 200,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
            textTransform: "none",
          }}
        >
          Siparişi Onayla
        </Button>
      );
    } else {
      return (
        <Grid>
          <Button
            type="submit"
            variant="contained"
            disabled
            style={{
              marginTop: 40,
              marginBottom: 50,
              height: 50,
              width: 200,
              backgroundColor: "#EFF5F4",
              color: "#fff",
              borderRadius: 10,
              textTransform: "none",
            }}
          >
            <CircularProgress size={24} />
          </Button>
        </Grid>
      );
    }
  }
  if (width > 800) {
    return (
      <Grid>
        <Paper style={{ padding: 50, marginTop: 80, marginBottom: 200 }}>
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h4">Kart Bilgileri</Typography>
            <Grid item>
              <img
                src={mastercard}
                alt="Mastercard Logo"
                style={{ height: 30 }}
              />
              <img
                src={Visa}
                alt="logo"
                style={{ height: 20, marginBottom: 5 }}
              />
              <img
                src={AmericanExpress}
                alt="Amex Logo"
                style={{ height: 30 }}
              />
            </Grid>
          </Grid>
          <Divider
            style={{
              marginTop: 20,
              marginBottom: 40,
              width: "100%",
              maxWidth: 800,
            }}
          />
          <form onSubmit={submitPayment}>
            <TextField
              name="card_holder"
              type="text"
              onChange={(e) => mokaOnChange(e)}
              required
              label="Kart Üzerindeki İsim"
              fullWidth
              inputProps={{ maxLength: 42 }}
              style={{ marginBottom: 20, maxWidth: 420 }}
            />
            <TextField
              name="card_number"
              type="number"
              onChange={(e) => mokaOnChange(e)}
              required
              label="Kart Numarası"
              fullWidth
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 16);
              }}
              style={{ marginBottom: 20, maxWidth: 420 }}
            />
            <Grid>
              <FormControl>
                <InputLabel id="card_expiry_month">Ay</InputLabel>
                <Select
                  name="card_expiry_month"
                  label="Ay"
                  onChange={mokaOnChange}
                  autoWidth
                  style={{ width: 120, marginRight: 30 }}
                >
                  <MenuItem value={"0" + 1}>01</MenuItem>
                  <MenuItem value={"0" + 2}>02</MenuItem>
                  <MenuItem value={"0" + 3}>03</MenuItem>
                  <MenuItem value={"0" + 4}>04</MenuItem>
                  <MenuItem value={"0" + 5}>05</MenuItem>
                  <MenuItem value={"0" + 6}>06</MenuItem>
                  <MenuItem value={"0" + 7}>07</MenuItem>
                  <MenuItem value={"0" + 8}>08</MenuItem>
                  <MenuItem value={"0" + 9}>09</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                  <MenuItem value={12}>12</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="Year-select-label">Yıl</InputLabel>
                <Select
                  name="card_expiry_year"
                  label="Yıl"
                  onChange={mokaOnChange}
                  autoWidth
                  style={{ width: 120, marginRight: 30 }}
                >
                  <MenuItem value={"2023"}>2023</MenuItem>
                  <MenuItem value={"2024"}>2024</MenuItem>
                  <MenuItem value={"2025"}>2025</MenuItem>
                  <MenuItem value={"2026"}>2026</MenuItem>
                  <MenuItem value={"2027"}>2027</MenuItem>
                  <MenuItem value={"2028"}>2028</MenuItem>
                  <MenuItem value={"2029"}>2029</MenuItem>
                  <MenuItem value={"2030"}>2030</MenuItem>
                  <MenuItem value={"2031"}>2031</MenuItem>
                  <MenuItem value={"2032"}>2032</MenuItem>
                  <MenuItem value={"2033"}>2033</MenuItem>
                  <MenuItem value={"2034"}>2034</MenuItem>
                  <MenuItem value={"2035"}>2035</MenuItem>
                  <MenuItem value={"2036"}>2036</MenuItem>
                  <MenuItem value={"2037"}>2037</MenuItem>
                  <MenuItem value={"2038"}>2038</MenuItem>
                  <MenuItem value={"2039"}>2039</MenuItem>
                  <MenuItem value={"2040"}>2040</MenuItem>
                </Select>
              </FormControl>

              <TextField
                name="card_cvv"
                className="form-control"
                type="number"
                onChange={(e) => mokaOnChange(e)}
                required
                label="CVV"
                // onInput={(e) => {
                //   e.target.value = Math.max(parseInt(e.target.value))
                //     .toString()
                //     .slice(0, 4);
                // }}
                style={{ width: 120, marginRight: 30 }}
              />
            </Grid>
            <PaymentPolicies />
            {ButtonState()}
          </form>
        </Paper>
      </Grid>
    );
  } else {
    return (
      <Grid container style={{ padding: 20, marginTop: 50 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">Kart Bilgileri</Typography>
          <Grid item>
            <img
              src={mastercard}
              alt="Mastercard Logo"
              style={{ height: 20 }}
            />
            <img
              src={Visa}
              alt="logo"
              style={{ height: 15, marginBottom: 2 }}
            />
            <img src={AmericanExpress} alt="Amex Logo" style={{ height: 20 }} />
          </Grid>
        </Grid>
        <Divider
          style={{
            marginTop: 10,
            marginBottom: 30,
            width: "100%",
          }}
        />
        <form onSubmit={submitPayment}>
          <TextField
            name="card_holder"
            type="text"
            onChange={(e) => mokaOnChange(e)}
            required
            label="Kart Üzerindeki İsim"
            fullWidth
            inputProps={{ maxLength: 42 }}
            style={{ marginBottom: 20, maxWidth: 420 }}
          />
          <TextField
            name="card_number"
            type="number"
            onChange={(e) => mokaOnChange(e)}
            required
            label="Kart Numarası"
            fullWidth
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 16);
            }}
            style={{ marginBottom: 20, maxWidth: 420 }}
          />
          <Grid>
            <FormControl>
              <InputLabel id="card_expiry_month">Ay</InputLabel>
              <Select
                name="card_expiry_month"
                label="Ay"
                onChange={mokaOnChange}
                autoWidth
                style={{ width: 90, marginRight: 10 }}
              >
                <MenuItem value={"0" + 1}>01</MenuItem>
                <MenuItem value={"0" + 2}>02</MenuItem>
                <MenuItem value={"0" + 3}>03</MenuItem>
                <MenuItem value={"0" + 4}>04</MenuItem>
                <MenuItem value={"0" + 5}>05</MenuItem>
                <MenuItem value={"0" + 6}>06</MenuItem>
                <MenuItem value={"0" + 7}>07</MenuItem>
                <MenuItem value={"0" + 8}>08</MenuItem>
                <MenuItem value={"0" + 9}>09</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="Year-select-label">Yıl</InputLabel>
              <Select
                name="card_expiry_year"
                label="Yıl"
                onChange={mokaOnChange}
                autoWidth
                style={{ width: 90, marginRight: 10 }}
              >
                <MenuItem value={"2023"}>2023</MenuItem>
                <MenuItem value={"2024"}>2024</MenuItem>
                <MenuItem value={"2025"}>2025</MenuItem>
                <MenuItem value={"2026"}>2026</MenuItem>
                <MenuItem value={"2027"}>2027</MenuItem>
                <MenuItem value={"2028"}>2028</MenuItem>
                <MenuItem value={"2029"}>2029</MenuItem>
                <MenuItem value={"2030"}>2030</MenuItem>
                <MenuItem value={"2031"}>2031</MenuItem>
                <MenuItem value={"2032"}>2032</MenuItem>
                <MenuItem value={"2033"}>2033</MenuItem>
                <MenuItem value={"2034"}>2034</MenuItem>
                <MenuItem value={"2035"}>2035</MenuItem>
                <MenuItem value={"2036"}>2036</MenuItem>
                <MenuItem value={"2037"}>2037</MenuItem>
                <MenuItem value={"2038"}>2038</MenuItem>
                <MenuItem value={"2039"}>2039</MenuItem>
                <MenuItem value={"2040"}>2040</MenuItem>
              </Select>
            </FormControl>

            <TextField
              name="card_cvv"
              className="form-control"
              type="number"
              onChange={(e) => mokaOnChange(e)}
              required
              label="CVV"
              // onInput={(e) => {
              //   e.target.value = Math.max(parseInt(e.target.value))
              //     .toString()
              //     .slice(0, 4);
              // }}
              style={{ width: 90 }}
            />
          </Grid>
          <PaymentPolicies />
          {ButtonState()}
        </form>
      </Grid>
    );
  }
}

export default RenderMokaPayment;
