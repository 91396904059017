import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { check_coupon } from "../actions/coupons";
import { refresh } from "../actions/auth";
import { get_shipping_options } from "../actions/shipping";
import {
  get_payment_total,
  get_client_token,
  process_payment,
  moka_direct_payment,
} from "../actions/payment";
import { create_order } from "../actions/orders";
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Snackbar,
  Button,
  Alert,
} from "@mui/material";
import CartItem from "../components/CartItem";
import RenderMokaPayment from "../components/RenderMokaPayment";

// Image Imports
import UbicroLogo from "../assets/images/logo.png";

//Icon Imports
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SellIcon from "@mui/icons-material/Sell";
import MokaCheckoutForm from "../components/MokaCheckoutForm";
import CloseIcon from "@mui/icons-material/Close";

const steps = ["Sepet Onay", "Adres ve Ödeme"];

const Checkout = ({
  items,
  total_items,
  refresh,
  get_shipping_options,
  shipping,
  get_payment_total,
  get_client_token,
  process_payment,
  moka_direct_payment,
  user,
  profile,
  made_payment,
  original_price,
  total_after_coupon,
  total_amount,
  shipping_cost,
  check_coupon,
  coupon,
}) => {
  ///////////////// Address Field Form Data Begin /////////////////
  const [formData, setFormData] = useState({
    full_name: "",
    address_line_1: "",
    city: "",
    district: "",
    telephone_number: "",
    coupon_name: "",
    shipping_id: 0,
  });

  const {
    full_name,
    address_line_1,
    city,
    district,
    telephone_number,
    coupon_name,
    shipping_id,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  ///////////////// Address Field Form Data End /////////////////

  ///////////////// Moka Form Begin /////////////////
  const [mokaPay, setMokaPay] = useState({
    card_holder: "",
    card_number: "",
    card_expiry_month: "",
    card_expiry_year: "",
    card_cvv: "",
  });

  function mokaOnChange(e) {
    setMokaPay({ ...mokaPay, [e.target.name]: e.target.value });
  }

  const {
    card_holder,
    card_number,
    card_expiry_month,
    card_expiry_year,
    card_cvv,
  } = mokaPay;
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  ///////////////// Moka Form End /////////////////

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function Popup() {
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} severity="info">
          Lütfen kargo seçimi yapınız.
        </Alert>
      </Snackbar>
    );
  }

  // Send payment via Moka
  const submitPayment = async (e) => {
    e.preventDefault();
    setSubmitButtonLoading(true);

    if (shipping_id !== 0) {
      if (coupon && coupon !== null && coupon !== undefined) {
        await moka_direct_payment(
          shipping_id,
          coupon.name,
          card_holder,
          card_number,
          card_expiry_month,
          card_expiry_year,
          card_cvv
        );
      } else {
        await moka_direct_payment(
          shipping_id,
          "",
          card_holder,
          card_number,
          card_expiry_month,
          card_expiry_year,
          card_cvv
        );
      }
    } else {
      setOpen(true);
    }

    setSubmitButtonLoading(false);
  };

  ///////////////// Fill User Saved Info Begin /////////////////
  useEffect(() => {
    if (
      profile &&
      profile !== null &&
      profile !== undefined &&
      user &&
      user !== null &&
      user !== undefined
    ) {
      setFormData({
        ...formData,
        full_name: user.first_name + " " + user.last_name,
        address_line_1: profile.address_line_1,
        city: profile.city,
        district: profile.district,
        telephone_number: profile.phone,
      });
    }
  }, [profile, user]);
  ///////////////// Fill User Saved Info End /////////////////

  const apply_coupon = async (e) => {
    e.preventDefault();

    check_coupon(coupon_name);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    refresh();
    get_shipping_options();
  }, []);

  useEffect(() => {
    get_client_token();
  }, [user]);

  useEffect(() => {
    if (coupon && coupon !== null && coupon !== undefined) {
      get_payment_total(shipping_id, coupon.name);
    } else {
      get_payment_total(shipping_id, "");
    }
  }, [shipping_id, coupon]);

  const showItems = () => {
    return (
      <div>
        <Typography gutterBottom variant="h4" style={{ margin: 10 }}>
          Sepetinizde {total_items} ürün var
        </Typography>
        <Divider />
        {items &&
          items !== null &&
          items !== undefined &&
          items.length !== 0 &&
          items.map((item, index) => {
            let count = item.count;

            return (
              <div key={index}>
                <CartItem
                  item={item}
                  count={count}
                  showViewProduct={false}
                  showRemoveProduct={false}
                  showUpdateProduct={false}
                  showQuantity
                />
              </div>
            );
          })}
      </div>
    );
  };

  const renderShipping = () => {
    if (shipping && shipping !== null && shipping !== undefined) {
      return (
        <div>
          {shipping.map((shipping_option, index) => (
            <Grid
              container
              direction="row"
              alignItems="center"
              key={index}
              style={{ paddingLeft: 20, paddingTop: 5 }}
            >
              <Radio
                onChange={(e) => onChange(e)}
                value={shipping_option.id}
                name="shipping_id"
                type="radio"
                required
              />
              <Typography>
                {shipping_option.name} -{" "}
                {shipping_option.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                TL ({shipping_option.time_to_delivery})
              </Typography>
            </Grid>
          ))}
        </div>
      );
    }
  };

  const displayTotal = () => {
    let result = [];

    if (coupon && coupon !== null && coupon !== undefined) {
      result.push(
        <Grid container direction="column">
          <Grid container direction="row">
            <Typography gutterBottom style={{ marginRight: 10 }}>
              Ürün Toplamı:
            </Typography>
            <Typography>
              {original_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              TL
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Typography gutterBottom style={{ marginRight: 10 }}>
              Yeni toplam:
            </Typography>
            <Typography>
              {total_after_coupon
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              TL
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      result.push(
        <Grid container direction="row">
          <Typography gutterBottom style={{ marginRight: 10 }}>
            Ürün toplamı:
          </Typography>
          <Typography>
            {original_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} TL
          </Typography>
        </Grid>
      );
    }

    if (shipping && shipping_id !== 0) {
      result.push(
        <Grid container direction="row">
          <Typography gutterBottom style={{ marginRight: 10 }}>
            Kargo ücreti:
          </Typography>
          <Typography>
            {shipping_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} TL
          </Typography>
        </Grid>
      );
    } else {
      result.push(
        <Grid container direction="row">
          <Typography gutterBottom style={{ marginRight: 10 }}>
            Kargo tutarı:
          </Typography>
          <Typography style={{ color: "red" }}>
            Lütfen kargo seçimi yapın
          </Typography>
        </Grid>
      );
    }

    result.push(
      <Grid container direction="row">
        <Typography style={{ marginRight: 10 }}>Toplam tutar:</Typography>
        <Typography>
          {total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} TL
        </Typography>
      </Grid>
    );

    return result;
  };

  const renderMokaPaymentForm = () => {
    return (
      <MokaCheckoutForm
        full_name={full_name}
        address_line_1={address_line_1}
        city={city}
        district={district}
        telephone_number={telephone_number}
        onChange={onChange}
        buy={buy}
        renderShipping={renderShipping}
        user={user}
        profile={profile}
      />
    );
  };

  const renderMokaCreditCardForm = () => {
    return (
      <RenderMokaPayment
        card_holder={card_holder}
        card_number={card_number}
        card_expiry_month={card_expiry_month}
        card_expiry_year={card_expiry_year}
        card_cvv={card_cvv}
        submitPayment={submitPayment}
        mokaOnChange={mokaOnChange}
        submitButtonLoading={submitButtonLoading}
      />
    );
  };

  // Send address fields to backend & send the order to backend then redirect to thank you page
  const buy = async (e) => {
    if (coupon && coupon !== null && coupon !== undefined) {
      process_payment(
        shipping_id,
        coupon.name,
        full_name,
        address_line_1,
        city,
        district,
        telephone_number
      );
    } else {
      process_payment(
        shipping_id,
        "",
        full_name,
        address_line_1,
        city,
        district,
        telephone_number
      );
    }
  };

  if (made_payment) {
    buy();
    return <Redirect to="/thankyou" />;
  }

  let width = window.innerWidth;

  if (width > 800) {
    return (
      <div>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: 30, marginBottom: 100 }}
        >
          {Popup()}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ maxWidth: 800 }}
          >
            <IconButton href="/" style={{ marginLeft: 30, borderRadius: 10 }}>
              <img
                src={UbicroLogo}
                alt="logo"
                className="logo"
                style={{ marginBottom: 10 }}
              />
              <Typography
                style={{
                  fontSize: 24,
                  marginLeft: 20,
                  fontWeight: 200,
                }}
              >
                Ubicro
              </Typography>
            </IconButton>
            <Stepper activeStep={1} style={{ marginRight: 30, marginLeft: 30 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              style={{ maxWidth: 800, marginTop: 70 }}
            >
              <Typography style={{ marginLeft: 30 }}>
                Teslimat Bilgileriniz
              </Typography>
              <Typography style={{ marginRight: 30 }}>
                Toplam Tutar:{" "}
                {total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                TL
              </Typography>
            </Grid>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />
            <Grid
              container
              direction="column"
              style={{ maxWidth: 800, marginLeft: 30, marginBottom: 30 }}
            >
              <Typography gutterBottom variant="h4">
                Sipariş Özeti
              </Typography>
              <Grid>{displayTotal()}</Grid>
            </Grid>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />
            <Grid
              item
              container
              direction="column"
              style={{ maxWidth: 800, marginLeft: 30 }}
            >
              <Typography variant="h5">Kupon kodu</Typography>
              <form onSubmit={(e) => apply_coupon(e)}>
                <Grid container direction="row" alignItems="center">
                  <TextField
                    name="coupon_name"
                    type="text"
                    label="İndirim kodu"
                    onChange={(e) => onChange(e)}
                    value={coupon_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SellIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton color="primary" type="submit">
                            <ArrowForwardIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ marginBottom: 30, marginTop: 30, width: 350 }}
                  />
                  {coupon &&
                  coupon.discount_price &&
                  coupon !== null &&
                  coupon !== undefined ? (
                    <Typography style={{ color: "green", marginLeft: 30 }}>
                      {coupon.discount_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      TL indirim işlendi.
                    </Typography>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {coupon &&
                  coupon.discount_percentage &&
                  coupon !== null &&
                  coupon !== undefined ? (
                    <Typography style={{ color: "green", marginLeft: 30 }}>
                      % {coupon.discount_percentage} indirim işlendi.
                    </Typography>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Grid>
              </form>
            </Grid>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />
            {renderMokaPaymentForm()}
            {renderMokaCreditCardForm()}
            {showItems()}
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: 30, marginBottom: 100 }}
        >
          {Popup()}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ maxWidth: 800 }}
          >
            <IconButton href="/" style={{ marginLeft: 30, borderRadius: 10 }}>
              <img
                src={UbicroLogo}
                alt="logo"
                className="logo"
                style={{ marginBottom: 10 }}
              />
              <Typography
                style={{
                  fontSize: 24,
                  marginLeft: 20,
                  fontWeight: 200,
                }}
              >
                Ubicro
              </Typography>
            </IconButton>

            <Stepper
              activeStep={1}
              style={{ marginRight: 30, marginLeft: 30, marginTop: 20 }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Divider
              style={{
                marginTop: 30,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />

            <Grid
              container
              direction="column"
              style={{ maxWidth: 800, marginLeft: 30, marginBottom: 30 }}
            >
              <Typography gutterBottom variant="h4">
                Sipariş Özeti
              </Typography>
              <Grid>{displayTotal()}</Grid>
            </Grid>

            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />

            <Grid
              item
              container
              direction="column"
              style={{ maxWidth: 800, marginLeft: 30 }}
            >
              <Typography variant="h5">Kupon kodu</Typography>
              <form onSubmit={(e) => apply_coupon(e)}>
                <Grid container direction="row" alignItems="center">
                  <TextField
                    name="coupon_name"
                    type="text"
                    label="İndirim kodu"
                    onChange={(e) => onChange(e)}
                    value={coupon_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SellIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton color="primary" type="submit">
                            <ArrowForwardIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ marginBottom: 30, marginTop: 30 }}
                  />
                  {coupon &&
                  coupon.discount_price &&
                  coupon !== null &&
                  coupon !== undefined ? (
                    <Typography style={{ color: "green", marginLeft: 30 }}>
                      {coupon.discount_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      TL indirim işlendi.
                    </Typography>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {coupon &&
                  coupon.discount_percentage &&
                  coupon !== null &&
                  coupon !== undefined ? (
                    <Typography style={{ color: "green", marginLeft: 30 }}>
                      % {coupon.discount_percentage} indirim işlendi.
                    </Typography>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Grid>
              </form>
            </Grid>

            <Divider
              style={{
                marginTop: 10,
                marginBottom: 50,
                width: "100%",
                maxWidth: 800,
              }}
            />
            {renderMokaPaymentForm()}
            {renderMokaCreditCardForm()}
            {showItems()}
          </Grid>
        </Grid>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  profile: state.profile.profile,
  items: state.cart.items,
  total_items: state.cart.total_items,
  coupon: state.coupons.coupon,
  shipping: state.shipping.shipping,
  clientToken: state.payment.clientToken,
  made_payment: state.payment.made_payment,
  loading: state.payment.loading,
  original_price: state.payment.original_price,
  total_after_coupon: state.payment.total_after_coupon,
  total_amount: state.payment.total_amount,
  total_compare_amount: state.payment.total_compare_amount,
  estimated_tax: state.payment.estimated_tax,
  shipping_cost: state.payment.shipping_cost,
  clientSecret: state.payment.clientSecret,
  create_order_loading: state.orders.loading,
});

export default connect(mapStateToProps, {
  check_coupon,
  create_order,
  refresh,
  get_shipping_options,
  get_payment_total,
  get_client_token,
  process_payment,
  moka_direct_payment,
})(Checkout);
