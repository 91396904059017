import React from "react";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";

const userProfileForm = ({
  address_line_1,
  city,
  district,
  phone,
  onChange,
  onSubmit,
  profile,
}) => (
  <Grid>
    <Typography variant="h6">Adres ve İletişim Bilgileri</Typography>
    <Paper
      variant="outlined"
      style={{ borderRadius: 20, padding: 20, marginTop: 30 }}
    >
      <form onSubmit={(e) => onSubmit(e)}>
        <TextField
          className="form-control"
          type="text"
          name="phone"
          label="Telefon"
          defaultValue={`${profile.phone}`}
          onChange={(e) => onChange(e)}
          value={phone}
          fullWidth
          margin="normal"
        />
        <TextField
          type="text"
          name="city"
          label="Şehir"
          defaultValue={`${profile.city}`}
          onChange={(e) => onChange(e)}
          value={city}
          fullWidth
          margin="normal"
        />
        <TextField
          type="text"
          name="district"
          label="İlçe"
          defaultValue={`${profile.district}`}
          onChange={(e) => onChange(e)}
          value={district}
          fullWidth
          margin="normal"
        />
        <TextField
          type="text"
          name="address_line_1"
          label="Adres"
          defaultValue={`${profile.address_line_1}`}
          onChange={(e) => onChange(e)}
          value={address_line_1}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />
        <Button
          type="submit"
          variant="contained"
          style={{
            margin: 30,
            height: 50,
            width: 200,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
          }}
        >
          Güncelle
        </Button>
      </form>
    </Paper>
  </Grid>
);

export default userProfileForm;
