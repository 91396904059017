import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

// Image Imports
import UbiHouseProOrtakMutak from "../assets/images/UbiHouseProOrtakMutfak.png";
import Chip from "../assets/images/Chip.png";
import Drought from "../assets/images/Drought.png";
import WhereToPut from "../assets/images/WhereToPut.png";
import fogAnimation from "../assets/images/sis_animated.gif";
import AeroponicImage from "../assets/images/Aeroponik.jpg";

const UbiHouseNedir = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Yeni nesil topraksız ve ilaçsız tarım ile tanışın. Bu sefer sebze ve meyveler seralarda değil evinizde yetişecek"
        />
        <title>UbiHouse Nedir - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/ubihouse-nedir" />
      </Helmet>
      <Header />
      <Grid
        container
        direction="column"
        style={{ backgroundColor: "#F5F5F7", marginBottom: 80 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
          style={{ padding: 30 }}
        >
          <Typography
            variant="h3"
            component="h1"
            style={{ color: "#1D1D1F", marginRight: 8 }}
          >
            UbiHouse
          </Typography>
          <Typography variant="h3" style={{ color: "#6E6E73" }}>
            Nedir?
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid
          container
          rowSpacing={1}
          justifyContent="center"
          columnSpacing={{ xs: 1 }}
          style={{ padding: 10, maxWidth: 1600 }}
        >
          <Grid item xs={12} md={5}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                height: 600,
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                Kendi sebze ve meyvenizi üretmeye başlayın...
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                UbiHouse, Ubicro’nun aeroponik teknolojisi kullanarak her yerde
                ve her zaman zahmetsizce meyve-sebze yetiştiren cihazıdır.
              </Typography>
              <img
                src={UbiHouseProOrtakMutak}
                alt="TumUrunler"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginTop: 40,
                  maxWidth: 600,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                height: 600,
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                Aeroponik üretim nedir?
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                <strong>Aeroponik (aeroponics) sistem, </strong>
                bitki köklerine sis şeklinde besin çözeltisi verilen bitki
                yetiştirme tekniğidir.
                <br /> <br />
                Bu yetiştirme tekniği, NASA tarafından 90’lı yıllarda uzayda
                bitki yetiştirmenin etkili yollarını ararken keşfedildi.
                Böylelikle “topraksız ve çok az su içeren bir hava / sis
                ortamında yetişen bitkiler” olarak tanımlanan
                <strong>“aeroponik”</strong> terimi kullanıldı.
              </Typography>
              <img
                src={AeroponicImage}
                alt="Aeroponik"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginTop: 40,
                  maxWidth: 500,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={7}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                height: 800,
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                Neden Aeroponik?
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                Yapılan araştırmalar sonucunda aeroponik sistemlerde yetişen
                bitkilerin, onları daha sağlıklı ve potansiyel olarak daha
                besleyici kılan daha fazla mineral ve vitamin aldıkları
                gösterilmiştir. Pratikte kökler üzerine belirli aralıklarla
                besleyici bir çözelti püskürtülür ve kökler sis bulutu
                içerisinde kalır.
                <br />
                <br />
                <strong>Aeroponik sistemin avantajları</strong> <br />
                Bitkiler yeterli miktarda besin ve oksijen aldığın için
                geleneksel tarıma göre
                <strong> daha hızlı ve yüksek kalitede </strong> verim elde
                edilir. Yetişen bitkilerde pestisit kalıntısı yoktur. Yapay zeka
                ile geliştirilen otomasyon sistemi ile zahmetsiz üretim
                sağlanır.
              </Typography>
              <img
                src={fogAnimation}
                alt="fogAnimation"
                style={{
                  width: "90%",
                  borderRadius: 10,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                height: 800,
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                IoT ve Yapay Zeka
              </Typography>
              <Typography
                gutterBottom
                style={{ fontSize: 18, color: "#6E6E73" }}
              >
                Yapay zeka ve IoT (“Internet of Things” - “Nesnelerin
                İnterneti”) teknolojisiyle istediğiniz yerde ve istediğiniz
                zaman yetiştirdiğiniz bitkilerin durumlarını kontrol
                edebilirsiniz.
                <br />
                <br />
                Hiçbir ön bilgi olmadan seçtiğiniz bitkiyi kolaylıkla
                yetiştirebilirsiniz. Bitkinin ihtiyaç duyduğu tüm gereksinimleri
                UbiHouse cihazı
              </Typography>
              <img
                src={Chip}
                alt="TumUrunler"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={7}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                height: 600,
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                Doğa Dostu
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                Türkiye'nin su tüketiminin 77%'si tarım sektörü kaynaklı. 2000
                yılında kişi başına düşen kullanılabilir yıllık su 1653 m3 iken
                2020 yılında bu oran 1346 m3 ve azalmaya devam ediyor.
              </Typography>
              <img
                src={Drought}
                alt="TumUrunler"
                style={{
                  width: "90%",
                  borderRadius: 10,
                  marginTop: 40,
                  maxHeight: 400,
                  maxWidth: 500,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                height: 600,
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                UbiHouse çalışma koşulları
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                18 - 32 derecelik iç mekan koşullarında çalıştırılmalıdır.
              </Typography>
              <img
                src={WhereToPut}
                alt="TumUrunler"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{
            backgroundColor: "#F5F5F7",
            width: "100%",
            marginTop: 50,
            padding: 20,
          }}
        >
          <Typography variant="h6">Hemen başlamak ister misiniz?</Typography>
          <Button
            variant="contained"
            href="/ubihouse"
            style={{
              marginTop: 30,
              height: 50,
              width: 200,
              backgroundColor: "#1683FC",
              color: "#fff",
              borderRadius: 10,
              textTransform: "none",
            }}
          >
            UbiHouse'ları incele
          </Button>
        </Grid>
      </Grid>

      <Grid style={{ height: 150 }} />

      <Footer />
    </div>
  );
};

export default UbiHouseNedir;
