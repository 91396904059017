import React, { Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
} from "@mui/material";

//Image Imports
import UbiTrayPaket from "../assets/images/UbiTrayPaket.jpg";
import biber from "../assets/images/biber.png";
import biberiye from "../assets/images/biberiye.png";
import feslegen from "../assets/images/feslegen.png";
import lavanta from "../assets/images/lavanta.png";
import lettuce from "../assets/images/lettuce.png";
import marul from "../assets/images/marul.png";
import strawberry from "../assets/images/strawberry.png";
import tomato from "../assets/images/tomato.png";

const ListItemUbiTray = () => {
  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 40 }}
        >
          <Grid item container direction="row">
            <Typography variant="h5">UbiTray.</Typography>
            <Typography
              variant="h5"
              style={{ color: "#6E6E73", marginLeft: 5 }}
            >
              Yetiştirmek hiç bu kadar kolay olmadı.
            </Typography>
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/ubitray-nedir">
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiTray Nedir?
                  </Typography>
                  <Typography color="textSecondary">
                    Teknoloji ve Tohumların Birleşimi.
                  </Typography>
                </Grid>
              </CardContent>
              <CardMedia
                component="img"
                alt="UbiTrayPaket"
                src={UbiTrayPaket}
                title="UbiTrayPaket"
                style={{
                  height: 250,
                  width: 400,
                  marginTop: 70,
                  marginLeft: -20,
                }}
              />
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
              maxHeight: 420,
              marginRight: 50,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/ubitray">
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    Tüm UbiTray’ler
                  </Typography>
                  <Typography color="textSecondary">
                    Tohum çeşitlerinin hepsini inceleyin.
                  </Typography>
                </Grid>
              </CardContent>
              <Grid
                item
                container
                direction="row"
                alignContent="center"
                style={{ marginTop: 100, padding: 20 }}
              >
                <CardMedia
                  component="img"
                  alt="Tüm UbiTray’ler"
                  src={tomato}
                  title="Tüm UbiTray’ler"
                  className="listItemUbiTrayImage"
                />
                <CardMedia
                  component="img"
                  src={biber}
                  className="listItemUbiTrayImage"
                />
                <CardMedia
                  component="img"
                  src={biberiye}
                  className="listItemUbiTrayImage"
                />
                <CardMedia
                  component="img"
                  src={feslegen}
                  className="listItemUbiTrayImage"
                />
                <CardMedia
                  component="img"
                  src={lavanta}
                  className="listItemUbiTrayImage"
                />
                <CardMedia
                  component="img"
                  src={lettuce}
                  className="listItemUbiTrayImage"
                />
                <CardMedia
                  component="img"
                  src={marul}
                  className="listItemUbiTrayImage"
                />
                <CardMedia
                  component="img"
                  src={strawberry}
                  className="listItemUbiTrayImage"
                />
              </Grid>
            </CardActionArea>
          </Card>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 20 }}
        >
          <Grid item container direction="row">
            <Typography variant="h5" style={{ marginRight: 5 }}>
              UbiTray.
            </Typography>
            <Typography variant="h5" style={{ color: "#6E6E73" }}>
              Yetiştirmek hiç bu kadar kolay olmadı.
            </Typography>
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/ubitray-nedir">
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiTray Nedir?
                  </Typography>
                  <Typography color="textSecondary">
                    Teknoloji ve Tohumların Birleşimi.
                  </Typography>
                </Grid>
              </CardContent>
              <CardMedia
                component="img"
                alt="UbiTrayPaket"
                src={UbiTrayPaket}
                title="UbiTrayPaket"
                style={{
                  height: 200,
                  width: 320,
                  marginTop: 70,
                  marginLeft: -25,
                }}
              />
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
              marginRight: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/ubitray">
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    Tüm UbiTray’ler
                  </Typography>
                  <Typography color="textSecondary">
                    Tohum çeşitlerinin hepsini inceleyin.
                  </Typography>
                </Grid>
              </CardContent>
              <Grid
                item
                container
                direction="row"
                alignContent="center"
                style={{ marginTop: 30, padding: 20 }}
              >
                <CardMedia
                  component="img"
                  alt="Tüm UbiTray’ler"
                  src={tomato}
                  title="Tüm UbiTray’ler"
                  className="listItemUbiTrayImageMobile"
                />
                <CardMedia
                  component="img"
                  src={biber}
                  className="listItemUbiTrayImageMobile"
                />
                <CardMedia
                  component="img"
                  src={biberiye}
                  className="listItemUbiTrayImageMobile"
                />
                <CardMedia
                  component="img"
                  src={feslegen}
                  className="listItemUbiTrayImageMobile"
                />
                <CardMedia
                  component="img"
                  src={lavanta}
                  className="listItemUbiTrayImageMobile"
                />
                <CardMedia
                  component="img"
                  src={lettuce}
                  className="listItemUbiTrayImageMobile"
                />
                <CardMedia
                  component="img"
                  src={marul}
                  className="listItemUbiTrayImageMobile"
                />
                <CardMedia
                  component="img"
                  src={strawberry}
                  className="listItemUbiTrayImageMobile"
                />
              </Grid>
            </CardActionArea>
          </Card>
        </div>
      </Fragment>
    );
  }
};

export default ListItemUbiTray;
