import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Divider,
  Alert,
  AlertTitle,
} from "@mui/material";
import ProductDetailImageList from "./ProductDetailHardwareList";
import ProductDetailVideos from "./ProductDetailVideos";
import ProductDetailAdvancedDetail from "./ProductDetailAdvanedDetail";
import ImageSlider from "./ProductDetailImageSlider";

// Icon & Image imports
import UbiEnergy from "../assets/images/UbiEnergy.jpg";
import UbiTray from "../assets/images/UbiTrayPaket.jpg";
import CircleIcon from "@mui/icons-material/Circle";

const ProductDetailCard = ({
  product,
  add_item,
  get_items,
  get_total,
  get_item_total,
  setRedirect,
}) => {
  const addToCart = async () => {
    if (
      product &&
      product !== null &&
      product !== undefined &&
      product.quantity > 0
    ) {
      await add_item(product);
      await get_items();
      await get_total();
      await get_item_total();
      setRedirect(true);
    }
  };

  let width = window.innerWidth;

  if (width > 800) {
    return (
      <div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20, padding: 20, maxWidth: 1800 }}
        >
          <Grid item sm={12} md={6} style={{ marginTop: 30 }}>
            <Grid container>
              <Paper
                variant="outlined"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 20,
                  marginRight: 50,
                  padding: 30,
                }}
              >
                {product &&
                product !== null &&
                product !== undefined &&
                product.image ? (
                  <ImageSlider product={product} />
                ) : (
                  <></>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6} style={{ marginTop: 30, maxWidth: 500 }}>
            <Typography variant="h3" gutterBottom>
              {product &&
                product !== null &&
                product !== undefined &&
                product.name}
            </Typography>
            <Typography>
              {product &&
              product !== null &&
              product !== undefined &&
              product.quantity > 0 ? (
                <Grid container direction="row">
                  <Typography
                    style={{
                      color: "green",
                      marginRight: 10,
                    }}
                  >
                    Stokta var,
                  </Typography>
                  <Typography color="#6A727E">Ücretsiz teslimat</Typography>
                  {product && product.kargo_durumu ? (
                    <Grid
                      container
                      flexDirection="row"
                      style={{ alignItems: "center", marginTop: 10 }}
                    >
                      <CircleIcon style={{ marginRight: 10, fontSize: 10 }} />

                      <Typography>
                        {product &&
                          product !== null &&
                          product !== undefined &&
                          product.kargo_durumu}
                      </Typography>
                    </Grid>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Grid>
              ) : (
                <Typography style={{ color: "red" }}>Stokta Yok</Typography>
              )}
            </Typography>
            <Divider light style={{ marginBottom: 20, marginTop: 20 }} />
            <Typography style={{ height: 220 }}>
              {product &&
                product !== null &&
                product !== undefined &&
                product.description}
            </Typography>
            {product && product.presale_price ? (
              <Paper style={{ borderRadius: 20 }}>
                <Alert severity="info" style={{ borderRadius: 20 }}>
                  <AlertTitle>Ön satış bilgilendirme!</AlertTitle>
                  <Typography style={{ marginBottom: 20 }}>
                    Bu ürün ön satışta olduğundan fiyatının 50%'sini sipariş
                    sırasında geri kalan 50%'sini teslimattan 1 hafta önce
                    ödeyebilirsiniz
                  </Typography>
                  <AlertTitle>
                    Satın Alma Fiyatı:{" "}
                    {product.presale_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    TL
                  </AlertTitle>
                  <AlertTitle>
                    Bugün Ödenmesi Gereken:{" "}
                    {product.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    TL
                  </AlertTitle>
                </Alert>
              </Paper>
            ) : (
              <></>
            )}

            <Grid>
              <Grid
                container
                alignItems="center"
                style={{
                  direction: "row",
                  backgroundColor: "#F7F7F7",
                  borderColor: "#E5E5E5",
                  borderRadius: 1,
                  width: 300,
                  height: 70,
                  marginTop: 20,
                }}
              >
                <Typography
                  style={{
                    fontSize: 22,
                    fontWeight: 400,
                    marginLeft: 20,
                  }}
                >
                  {product &&
                    product !== null &&
                    product !== undefined &&
                    product.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  ₺
                </Typography>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={addToCart}
                  style={{
                    marginLeft: 30,
                    backgroundColor: "#2680EB",
                    color: "#FFFFFF",
                    height: 40,
                    textTransform: "none",
                  }}
                >
                  Sepete Ekleyin
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            style={{ marginTop: 100, maxWidth: 1350 }}
          >
            {product && product.birlikte_gelenler_ubitray ? (
              <>
                <Typography
                  style={{ marginTop: 150, marginBottom: 50 }}
                  variant="h2"
                >
                  Birlikte Gelenler
                </Typography>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  direction="row"
                  style={{
                    borderRadius: 10,
                    padding: 20,
                    paddingTop: 60,
                    paddingBottom: 100,
                  }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        style={{
                          width: 300,
                          marginBottom: 20,
                          borderRadius: 10,
                        }}
                      />
                      <Typography>{product.name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src={UbiTray}
                        alt="UbiTray Görsel"
                        style={{
                          width: 300,
                          marginBottom: 20,
                        }}
                      />
                      <Typography style={{ maxWidth: 300 }}>
                        {product.birlikte_gelenler_ubitray}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src={UbiEnergy}
                        alt="UbiEnergy Görsel"
                        style={{
                          width: 300,
                          marginBottom: 20,
                          borderRadius: 10,
                        }}
                      />
                      <Typography>UbiEnergy</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
          {product && product.category === 1 ? (
            <Fragment>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Typography
                  style={{ marginTop: 150, marginBottom: 50 }}
                  variant="h2"
                >
                  Donanım
                </Typography>

                <ProductDetailImageList />
                <ProductDetailVideos />
              </Grid>

              <ProductDetailAdvancedDetail product={product} />
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Grid item sm={12} md={6} style={{ marginTop: 30, padding: 10 }}>
            <Grid container>
              {product &&
              product !== null &&
              product !== undefined &&
              product.image ? (
                <ImageSlider product={product} />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            md={6}
            style={{ marginTop: 30, maxWidth: 500, padding: 20 }}
          >
            <Grid container direction="row">
              <Typography variant="h3" gutterBottom>
                {product &&
                  product !== null &&
                  product !== undefined &&
                  product.name}
              </Typography>

              <Grid container>
                {product &&
                product !== null &&
                product !== undefined &&
                product.quantity > 0 ? (
                  <Grid container direction="row">
                    <Typography
                      style={{
                        color: "green",
                        marginRight: 10,
                      }}
                    >
                      Stokta var,
                    </Typography>
                    <Typography color="#6A727E">Ücretsiz teslimat,</Typography>
                  </Grid>
                ) : (
                  <Typography style={{ color: "red", marginRight: 5 }}>
                    Stokta Yok,
                  </Typography>
                )}

                {product && product.kargo_durumu ? (
                  <Grid
                    container
                    flexDirection="row"
                    style={{ alignItems: "center", marginTop: 10 }}
                  >
                    <CircleIcon style={{ marginRight: 10, fontSize: 10 }} />

                    <Typography>
                      {product &&
                        product !== null &&
                        product !== undefined &&
                        product.kargo_durumu}
                    </Typography>
                  </Grid>
                ) : (
                  <Fragment></Fragment>
                )}
              </Grid>
            </Grid>

            <Divider light style={{ marginBottom: 20, marginTop: 20 }} />

            <Typography style={{ height: 300 }}>
              {product &&
                product !== null &&
                product !== undefined &&
                product.description}
            </Typography>

            {product && product.presale_price ? (
              <Paper style={{ borderRadius: 20 }}>
                <Alert severity="info" style={{ borderRadius: 20 }}>
                  <AlertTitle>Ön satış bilgilendirme!</AlertTitle>
                  <Typography style={{ marginBottom: 20 }}>
                    Bu ürün ön satışta olduğundan fiyatının 50%'sini sipariş
                    sırasında geri kalan 50%'sini teslimattan 1 hafta önce
                    ödeyebilirsiniz
                  </Typography>
                  <AlertTitle>
                    Satın Alma Fiyatı:{" "}
                    {product.presale_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    TL
                  </AlertTitle>
                  <AlertTitle>
                    Bugün Ödenmesi Gereken:{" "}
                    {product.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    TL
                  </AlertTitle>
                </Alert>
              </Paper>
            ) : (
              <></>
            )}

            <Grid>
              <Grid
                container
                alignItems="center"
                style={{
                  direction: "row",
                  backgroundColor: "#F7F7F7",
                  borderColor: "#E5E5E5",
                  borderRadius: 1,
                  width: "100%",
                  height: 80,
                  marginTop: 20,
                }}
              >
                <Typography
                  style={{
                    fontSize: 22,
                    fontWeight: 400,
                    marginLeft: 20,
                  }}
                >
                  {product &&
                    product !== null &&
                    product !== undefined &&
                    product.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  ₺
                </Typography>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={addToCart}
                  style={{
                    marginLeft: 30,
                    backgroundColor: "#2680EB",
                    color: "#FFFFFF",
                    height: 40,
                    textTransform: "none",
                  }}
                >
                  Sepete Ekleyin
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {product && product.birlikte_gelenler_ubitray ? (
            <>
              <Typography
                style={{ marginTop: 150, marginBottom: 50 }}
                variant="h4"
              >
                Birlikte Gelenler
              </Typography>
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                direction="row"
                style={{
                  padding: 20,
                  paddingTop: 20,
                  paddingBottom: 50,
                }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={product.image}
                      alt={product.name}
                      style={{
                        width: 250,
                        marginBottom: 20,
                        borderRadius: 10,
                        marginTop: 20,
                      }}
                    />
                    <Typography>{product.name}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={UbiTray}
                      alt="UbiTray Görsel"
                      style={{
                        width: 250,
                        marginBottom: 20,
                        marginTop: 50,
                      }}
                    />
                    <Typography style={{ maxWidth: 300 }}>
                      {product.birlikte_gelenler_ubitray}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={UbiEnergy}
                      alt="UbiEnergy Görsel"
                      style={{
                        width: 250,
                        marginBottom: 20,
                        borderRadius: 10,
                        marginTop: 50,
                      }}
                    />
                    <Typography>UbiEnergy</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {product && product.category === 1 ? (
            <Fragment>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Typography
                  style={{ marginTop: 150, marginBottom: 50 }}
                  variant="h4"
                >
                  Donanım
                </Typography>

                <ProductDetailImageList />
                <ProductDetailVideos />
              </Grid>

              <ProductDetailAdvancedDetail product={product} />
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
        </Grid>
      </div>
    );
  }
};

export default ProductDetailCard;
