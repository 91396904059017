import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get_order_detail } from "../actions/orders";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as locales from "dayjs/locale/tr";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import {
  Card,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Button,
} from "@mui/material";

// Icon imports
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

dayjs.extend(localizedFormat);
dayjs.locale("tr");

const OrderItemDetail = ({ match, get_order_detail, order, status }) => {
  useEffect(() => {
    const transaction_id = match.params.transaction_id;

    get_order_detail(transaction_id);
  }, [match.params.transaction_id]);

  const showStatus = (status) => {
    if (status === "not_processed") {
      return <Typography style={{ color: "orange" }}>Onay bekliyor</Typography>;
    } else if (status === "processed") {
      return <Typography style={{ color: "#4DA2E6" }}>Onaylandı</Typography>;
    } else if (status === "shipped") {
      return <Typography style={{ color: "#F2B34B" }}>Kargoda</Typography>;
    } else if (status === "delivered") {
      return <Typography style={{ color: "green" }}>Gönderildi</Typography>;
    } else if (status === "cancelled") {
      return <Typography style={{ color: "red" }}>İptal edildi</Typography>;
    }
  };
  let width = window.innerWidth;

  if (width > 800) {
    return (
      <Grid>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Sipariş detay sayfası" />
          <title>Sipariş Detay - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/order-detail" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "#F3F6FB" }}
        >
          <Paper
            item
            variant="outlined"
            style={{ marginTop: 50, borderRadius: 20, width: 500, padding: 30 }}
          >
            <Grid container>
              <LightbulbIcon style={{ color: "#EEAE3D", marginRight: 20 }} />
              {order && order !== null && order !== undefined && (
                <Typography variant="subtitle1" style={{ color: "grey" }}>
                  Sipariş kodu: <strong>{order.transaction_id}</strong> olan
                  sipariş için detay
                </Typography>
              )}
            </Grid>
          </Paper>

          <Card
            variant="outlined"
            style={{
              borderRadius: 20,
              width: 500,
              padding: 30,
              paddingLeft: 40,
              margin: 10,
              marginBottom: 50,
              marginTop: 30,
            }}
          >
            <Button
              variant="outlined"
              href="/dashboard"
              startIcon={<ArrowBackIosNewIcon />}
              style={{ textTransform: "none" }}
            >
              Panele Dön
            </Button>
            <div>
              {order && order !== null && order !== undefined && (
                <div>
                  <Typography variant="h6" style={{ marginTop: 50 }}>
                    Sipariş Durumu
                  </Typography>
                  <Typography style={{ marginTop: 15 }}>
                    {showStatus(order.status)}
                  </Typography>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Sipariş Bilgileri
                  </Typography>
                  <List className="list-group mb-3">
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Sipariş Kodu:</strong> {order.transaction_id}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Toplam Tutar:</strong>{" "}
                        {order.amount.toLocaleString()} TL
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Adres Bilgileri
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Adres:</strong> {order.address_line_1}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Şehir:</strong> {order.city}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>İlçe:</strong> {order.district}
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Ek Bilgiler
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Kargo Ücreti:</strong>{" "}
                        {order.shipping_price.toLocaleString()} TL
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Teslimat Süresi:</strong> {order.shipping_time}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Telefon:</strong> {order.telephone_number}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Sipariş Verildi:</strong>{" "}
                        {dayjs(order.date_issued).format("LLL")}
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography
                    variant="h6"
                    style={{ marginTop: 30, marginBottom: 20 }}
                  >
                    Ürünler
                  </Typography>
                  {order.order_items.map((order_item, index) => (
                    <div key={index}>
                      <Divider style={{ marginBottom: 20 }} />
                      <Typography>{order_item.name}</Typography>
                      <List>
                        <ListItem>
                          <ListItemText style={{ maxWidth: 500 }}>
                            <strong>Tutar:</strong>{" "}
                            {order_item.price.toLocaleString()} TL
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText style={{ maxWidth: 500 }}>
                            <strong>Adet:</strong> {order_item.count}
                          </ListItemText>
                        </ListItem>
                      </List>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Sipariş detay sayfası" />
          <title>Sipariş Detay - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/order-detail" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "#F3F6FB" }}
        >
          <Paper
            item
            variant="outlined"
            style={{ margin: 10, marginTop: 50, borderRadius: 20, padding: 30 }}
          >
            <Grid container direction="row">
              <LightbulbIcon style={{ color: "#EEAE3D", marginRight: 20 }} />
              {order && order !== null && order !== undefined && (
                <Typography variant="subtitle1" style={{ color: "grey" }}>
                  Sipariş kodu: <strong>{order.transaction_id}</strong> <br />{" "}
                  olan sipariş için detay
                </Typography>
              )}
              <Button
                variant="outlined"
                href="/dashboard"
                startIcon={<ArrowBackIosNewIcon />}
                style={{ textTransform: "none", marginTop: 30 }}
              >
                Panele Dön
              </Button>
            </Grid>
          </Paper>

          <Card
            variant="outlined"
            style={{
              borderRadius: 20,
              padding: 30,
              margin: 10,
              marginBottom: 50,
              marginTop: 30,
            }}
          >
            <div>
              {order && order !== null && order !== undefined && (
                <div>
                  <Typography variant="h6" style={{ marginTop: 20 }}>
                    Sipariş Durumu
                  </Typography>
                  <Typography style={{ marginTop: 15 }}>
                    {showStatus(order.status)}
                  </Typography>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Sipariş Bilgileri
                  </Typography>
                  <List className="list-group mb-3">
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Sipariş Kodu:</strong> {order.transaction_id}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Toplam Tutar:</strong>{" "}
                        {order.amount.toLocaleString()} TL
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Adres Bilgileri
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Adres:</strong> {order.address_line_1}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Şehir:</strong> {order.city}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>İlçe:</strong> {order.district}
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography variant="h6" style={{ marginTop: 30 }}>
                    Ek Bilgiler
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Kargo Ücreti:</strong>{" "}
                        {order.shipping_price.toLocaleString()} TL
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Teslimat Süresi:</strong> {order.shipping_time}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Telefon:</strong> {order.telephone_number}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText style={{ maxWidth: 500 }}>
                        <strong>Sipariş Verildi:</strong>{" "}
                        {dayjs(order.date_issued).format("LLL")}
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography
                    variant="h6"
                    style={{ marginTop: 30, marginBottom: 20 }}
                  >
                    Ürünler
                  </Typography>
                  {order.order_items.map((order_item, index) => (
                    <div key={index}>
                      <Divider style={{ marginBottom: 20 }} />
                      <Typography>{order_item.name}</Typography>
                      <List>
                        <ListItem>
                          <ListItemText style={{ maxWidth: 500 }}>
                            <strong>Tutar:</strong>{" "}
                            {order_item.price.toLocaleString()} TL
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText style={{ maxWidth: 500 }}>
                            <strong>Adet:</strong> {order_item.count}
                          </ListItemText>
                        </ListItem>
                      </List>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

const mapStateToProps = (state) => ({
  order: state.orders.order,
});

export default connect(mapStateToProps, {
  get_order_detail,
})(OrderItemDetail);
