import React from "react"
import { Typography, Grid, Button } from "@mui/material"
import Header from "../components/Header"
import Footer from "../components/Footer"
import lottie from "lottie-web";
import notFound from '../assets/animations/lonely-404.json'
import { Helmet } from "react-helmet"


const NotFound = () => {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#shopping-cart"),
          animationData: notFound
        });
      }, []);

 return(
  <div>
    <Helmet>
      <meta charSet='utf-8' />
      <meta name='description' content='Aradığınız sayfa bulunamadı' />
      <title>Sayfa Bulunamadı - Ubicro</title>
      <link rel='canonical' href='https://www.ubicro.com/not-found' />
    </Helmet>
    <Header/>
    <Grid container style={{ backgroundColor: "#F5F5F7" }}>
        <Grid item container direction="column" alignItems="center" style={{ marginTop: 60, marginBottom: 60 }}>
            <div id='shopping-cart' style={{ width: 200, height: 200}} />
            <Typography variant="h4" style={{ color: "#6E6E73", marginLeft: 30 }}>
                Üzgünüz, aradığınız sayfa bulunamadı.
            </Typography>
            <Button 
                variant="contained"
                href="/"
                style={{ 
                  margin: 30, 
                  height: 50, 
                  width: 200, 
                  backgroundColor:"#1683FC", 
                  color:"#fff", 
                  borderRadius: 10 
                }}
                >
                  Anasayfaya geri dön
              </Button>
        </Grid>
    </Grid>
    <Footer/>
  </div>
 )
}

export default NotFound