import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../actions/alert";
import {
  remove_item,
  update_item,
  get_items,
  get_item_total,
  get_total,
} from "../actions/cart";
import CartItem from "../components/CartItem";
import { Button, Typography, Grid, Divider } from "@mui/material";
import Header from "../components/Header";
import SepetComponent from "../components/Sepet";
import { Helmet } from "react-helmet";

const Cart = ({
  isAuthenticated,
  items,
  amount,
  total_items,
  remove_item,
  update_item,
  get_items,
  get_item_total,
  get_total,
}) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    get_items();
    get_item_total();
    get_total();
  }, [render]);

  const showItems = () => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Sepet sayfası" />
          <title>Sepetim - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/sepetim" />
        </Helmet>
        <Typography variant="h3">Sepet Toplamı</Typography>
        <Typography
          variant="h6"
          style={{
            color: "#AFAFAF",
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          Sepetinizde {total_items} ürün var
        </Typography>
        <Typography variant="h4" style={{ color: "#196DDD" }}>
          {amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ₺
        </Typography>
        {checkoutButton()}
        <Divider
          light
          flexItem
          style={{ height: 0.1, marginTop: 50, marginBottom: 50 }}
        />
        {items &&
          items !== null &&
          items !== undefined &&
          items.length !== 0 &&
          items.map((item, index) => {
            let count = item.count;

            return (
              <div key={index}>
                <CartItem
                  item={item}
                  count={count}
                  update_item={update_item}
                  remove_item={remove_item}
                  setAlert={setAlert}
                  render={render}
                  setRender={setRender}
                />
              </div>
            );
          })}
      </Grid>
    );
  };

  const checkoutButton = () => {
    if (total_items < 1) {
      return (
        <Button
          variant="contained"
          href="/"
          style={{
            margin: 30,
            height: 50,
            width: 200,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
            textTransform: "none",
          }}
        >
          Anasayfaya geri dön
        </Button>
      );
    } else if (!isAuthenticated) {
      return (
        <Button
          variant="contained"
          href="/login"
          style={{
            margin: 30,
            height: 50,
            width: 200,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
          }}
        >
          SEPETİ ONAYLA
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          href="/checkout"
          style={{
            margin: 30,
            height: 50,
            width: 200,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
          }}
        >
          ÖDEMEYE İLERLEYİN
        </Button>
      );
    }
  };

  const cartRender = () => {
    if (total_items < 1) {
      return (
        <div>
          <Header />
          <SepetComponent />
        </div>
      );
    } else {
      return (
        <div>
          <Header />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 60, marginBottom: 30 }}
          >
            <Grid container justifyContent="center" alignItems="center">
              {showItems()}
              <Grid style={{ marginTop: 100 }}>
                <Divider
                  light
                  style={{
                    marginTop: 20,
                    marginBottom: 30,
                    width: "100%",
                    maxWidth: 900,
                    height: 0.1,
                  }}
                />
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    justifySelf: "flex-end",
                    maxWidth: 800,
                  }}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      marginRight: 30,
                      paddingLeft: 20,
                      maxWidth: 400,
                    }}
                  >
                    <Typography>Ürün Toplamı</Typography>
                    <Typography>{amount.toLocaleString()} ₺</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      marginRight: 30,
                      paddingLeft: 20,
                      maxWidth: 400,
                    }}
                  >
                    <Typography>Kargo Toplamı</Typography>
                    <Typography style={{ color: "green" }}>Ücretsiz</Typography>
                  </Grid>
                  <Divider
                    light
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      width: "100%",
                      maxWidth: 450,
                      height: 0.1,
                    }}
                  />
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      marginRight: 30,
                      paddingLeft: 20,
                      maxWidth: 400,
                    }}
                  >
                    <Typography variant="h5">Toplam</Typography>
                    <Typography variant="h5">
                      {amount !== 0 ? (
                        <span>{amount.toLocaleString()} ₺</span>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    style={{ maxWidth: 400 }}
                  >
                    {checkoutButton()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  return <div>{cartRender()}</div>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  items: state.cart.items,
  amount: state.cart.amount,
  total_items: state.cart.total_items,
});

export default connect(mapStateToProps, {
  remove_item,
  update_item,
  get_items,
  get_item_total,
  get_total,
  setAlert,
})(Cart);
