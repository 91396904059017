import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_device_detail } from "../actions/devices";
import DeviceDetailCard from "../components/DeviceDetailCard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, CircularProgress, Typography, Paper } from "@mui/material";
import { DynamoDBClient, QueryCommand } from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

const DeviceDetail = ({ match, product, get_device_detail }) => {
  const [webSocketConnected, setWebSocketConnected] = useState(false);
  const [iotData, setIotdata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Only fetch product data here
    const productId = match.params.id;
    get_device_detail(productId);
  }, [match.params.id, get_device_detail]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const REGION = "us-west-2";

    const ddb = new DynamoDBClient({
      region: REGION,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: REGION },
        identityPoolId: "us-west-2:12b6a200-48b9-46c7-b2e5-f9bde01a7701",
      }),
    });

    let roomId =
      product &&
      product !== null &&
      product !== undefined &&
      product.connection_id !== "" &&
      product.connection_id;

    if (roomId) {
      let params = {
        ExpressionAttributeValues: {
          ":v1": {
            S: roomId,
          },
        },
        KeyConditionExpression: "topic = :v1",
        ProjectionExpression: "payload",
        TableName: "ubihouseState",
      };

      const command = new QueryCommand(params);

      async function DatabaseScan() {
        try {
          const data = await ddb.send(command);
          if (data.Items && data.Items[0]) {
            var scan_data = data.Items[0].payload.M;
            setIotdata(scan_data);
            setLoading(false);
          }
        } catch (error) {
          console.log("Error: ", error);
        }
      }

      DatabaseScan();

      let webSocketURL =
        "wss://6ootync14e.execute-api.us-west-2.amazonaws.com/production";

      let socket;

      let devicePayload = function (data) {
        try {
          let obj = JSON.parse(data);
          setIotdata(obj);
        } catch (error) {
          console.log("Error parsing JSON: ", error);
        }
      };

      function openWebSocket() {
        socket = new WebSocket(webSocketURL);
        socket.addEventListener("message", (event) => {
          devicePayload(event.data);
        });
        socket.addEventListener("open", () => {
          socket.send(`{"action": "enterroom", "data": "${roomId}"}`);
          console.log("Succesfully connected to UbiHouse ID:", roomId);
          setWebSocketConnected(true);
        });
      }

      openWebSocket();

      window.onbeforeunload = function () {
        socket.send(`{"action": "exitroom", "data": "${roomId}"}`);
        socket.close();
      };

      return () => {
        socket.removeEventListener("message", devicePayload);
        socket.close();
      };
    }
  }, [product]);

  return (
    <div>
      <Header />
      <Grid container justifyContent="center" alignItems="center">
        {loading === false ? (
          <DeviceDetailCard
            product={product}
            webSocketConnected={webSocketConnected}
            iotData={iotData}
          />
        ) : (
          <Paper
            variant="outlined"
            style={{
              height: 300,
              width: 340,
              borderRadius: 20,
              margin: 30,
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ height: "100%", width: "100%" }}
            >
              <CircularProgress
                size={50}
                style={{
                  marginBottom: 30,
                }}
              />
              <Typography variant="h6">
                Cihaz Bilgileri Yükleniyor...
              </Typography>
            </Grid>
          </Paper>
        )}
      </Grid>
      <Grid style={{ marginTop: 250 }} />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.devices.product,
});

export default connect(mapStateToProps, {
  get_device_detail,
})(DeviceDetail);
