import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import lottie from "lottie-web";
import shoppingCart from '../assets/animations/shopping-cart.json'


function Sepet(props) {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#shopping-cart"),
          animationData: shoppingCart
        });
      }, []);

    return (
        <div>
            <Grid 
                container 
                direction='column' 
                justifyContent='center' 
                alignItems='center'
                style={{ marginTop: 30, marginBottom: 100 }}
                >
                <div id='shopping-cart' style={{ width: 200, height: 200, marginBottom: 20 }} />
                <Typography variant='h4' gutterBottom>Sepetiniz boş.</Typography>
                <Typography style={{ color: '#959595' }}>
                    ürünleri incelemeye başlayabilirsin.
                </Typography>
                <Button 
                    variant="contained"
                    href="/"
                    style={{ 
                      margin: 30, 
                      height: 50, 
                      width: 200, 
                      backgroundColor:"#1683FC", 
                      color:"#fff", 
                      borderRadius: 10
                    }}
                    >
                      Anasayfaya geri dön
                  </Button>
            </Grid>
        </div>
    );
}

export default Sepet;