import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Image Imports
import UbiTrayPaket from "../assets/images/UbiTrayPaket.jpg";
import { Helmet } from "react-helmet";
import CropVariety from "../assets/images/CropVariety.png";
import ThrashBin from "../assets/images/ThrashBin.png";
import Seed from "../assets/images/Seed.png";
import Planting from "../assets/images/Planting.png";
import Digging from "../assets/images/Digging.png";

const UbiTrayNedir = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="UbiTray nedir ve nasıl çalışır hemen öğrenin"
        />
        <title>UbiTray Nedir - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/ubitray-nedir" />
      </Helmet>
      <Header />
      <Grid
        container
        direction="column"
        style={{ backgroundColor: "#F5F5F7", marginBottom: 80 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
          style={{ padding: 30 }}
        >
          <Typography
            variant="h3"
            component="h1"
            style={{ color: "#1D1D1F", marginRight: 8 }}
          >
            UbiTray
          </Typography>
          <Typography variant="h3" style={{ color: "#6E6E73" }}>
            Nedir?
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid
          container
          rowSpacing={3}
          justifyContent="center"
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ padding: 20, maxWidth: 1600 }}
        >
          <Grid item xs={12} md={7}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                Ubicro teknolojisi GDO’suz tohumlarla birleşti!
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                Teknoloji yeni, tohumlar ise atalarımızın GDO'suz orjinal
                tohumları.
              </Typography>
              <img
                src={UbiTrayPaket}
                alt="TumUrunler"
                style={{
                  width: "90%",
                  borderRadius: 10,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                Ekme, biçme ile uğraşmayın.
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                UbiTray’i UbiHouse üzerine yerleştirin, mobil uygulama üzerinden
                üretimi başlatın, hasat zamanı geldiğinde toplayın ve tüketin.
                Hepsi bu kadar!
              </Typography>
              <img
                src={Digging}
                alt="TumUrunler"
                style={{
                  width: "90%",
                  borderRadius: 10,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography style={{ fontSize: 24 }}>
                Toz yok, toprak yok, dert yok!
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                Toz toprak bırakın tarlada kalsın. Eviniz veya iş yeriniz temiz
                kalsın, aynı zamanda taptaze ürünleri evinizin topraksız yeni
                teknoloji tarlasından toplayın ve birkaç adımda mutfağınıza
                taşıyın.
              </Typography>
              <img
                src={Planting}
                alt="TumUrunler"
                style={{
                  width: "90%",
                  borderRadius: 10,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography style={{ fontSize: 24 }}>
                Hasat sonrası UbiTray’i ne yapmalıyım?
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                Geri dönüştürülmüş ürünlerden elde edilen UbiTray’leri gönül
                rahatlığıyla plastik geri dönüşüm kutularına atabilirsiniz,
                böylece çevreyi kirletmeden doğaya yeniden kazandırılmış
                olurlar.
              </Typography>
              <img
                src={ThrashBin}
                alt="TumUrunler"
                style={{
                  width: "90%",
                  borderRadius: 10,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={7}>
            <Grid
              container
              justifyContent="center"
              direction="column"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography style={{ fontSize: 24 }}>
                Birçok ürünü yetiştirin.
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                Hiçbir yetiştirme bilgisine sahip olmadan 40’ın üzerinde ürünü
                elinizin altında yetiştirebilirsiniz.
              </Typography>
              <img
                src={CropVariety}
                alt="TumUrunler"
                style={{
                  width: "90%",
                  borderRadius: 10,
                  marginTop: 40,
                  marginLeft: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography style={{ fontSize: 24 }}>
                UbiTray’inizi özenle hazırlıyoruz.
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                UbiTray üzerindeki tüm tohumların yeşerebilmesi için tohumları
                özel olarak seçerek UbiTray’leri üretiyoruz.
              </Typography>
              <img
                src={Seed}
                alt="TumUrunler"
                style={{
                  width: "90%",
                  borderRadius: 10,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ height: 150 }} />

      <Footer />
    </div>
  );
};

export default UbiTrayNedir;
