import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { google_authenticate } from '../../actions/auth';
import queryString from 'query-string';
import { Grid, CircularProgress } from '@mui/material';


const Google = ({ google_authenticate }) => {
    const [redirect, setRediect] = useState(false);
    let location = useLocation();

    useEffect(() => {
        const values = queryString.parse(location.search);
        const state = values.state ? values.state : null;
        const code = values.code ? values.code : null;

        if (state && code) {
            const fetchData = async () => {
                await google_authenticate(state, code);
                setRediect(true);
            };

            fetchData();
        }
    }, [location]);

    if (redirect)
        return <Redirect to='/' />;
    return (
        <Grid container justifyContent= 'center' alignItems= 'center'>
            <Grid item>
                <CircularProgress
                  size={68}
                />
            </Grid>
        </Grid>
    );
};

export default connect(null, { google_authenticate })(Google);