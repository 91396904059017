import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, Paper, Button, Typography, Divider, Alert } from "@mui/material";

// Image imports
import UbiHouse from "../assets/images/UbiHouse.jpg";
import UbiHouseMini from "../assets/images/UbiHouseMini.jpg";
import UbiHouseProMini from "../assets/images/UbiHouseProMini.jpg";
import UbiHousePro from "../assets/images/UbiHousePro.jpg";

// Icon Imports
import { Helmet } from "react-helmet";

const Abonelik = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Abonelik sayfası" />
        <title>Abonelik - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/abonelik" />
      </Helmet>
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          height: 200,
          width: "100%",
          backgroundColor: "#fbfbfb",
        }}
      >
        <Typography variant="h2" component="h1">
          Abonelik
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ marginBottom: 20, marginTop: 100, padding: 20 }}
      >
        <Typography
          style={{
            maxWidth: 900,
            marginBottom: 30,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Abonelik sistemimizle ödeme kolaylığı sunuyoruz ve Ubicro
          teknolojisine erişiminizi kolaylaştırıyoruz.
        </Typography>
        <Typography
          style={{
            color: "#717478",
            letterSpacing: 1,
            fontWeight: 100,
            fontSize: 18,
            maxWidth: 800,
          }}
        >
          UbiHouse’unuzu tek seferde ödeyerek satın almak yerine, 12 aylık
          abonelik sistemi sayesinde taksitler ödeyerek sahip olabilirsiniz.
          Aboneliğinize seçtiğiniz UbiTray’leri ekleyerek her ay seçtiğiniz
          ürünlere abonelik paketi içerisinde de sahip olabilirsiniz ve UbiTray
          tercihinizi dilediğiniz zaman değiştirebilirsiniz.
        </Typography>
        <Alert severity="warning" style={{ marginTop: 50 }}>
          Henüz abonelik servisimiz açık değil
        </Alert>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: 100, padding: 20 }}
      >
        <Paper variant="outlined" style={{ padding: 20, borderRadius: 15 }}>
          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: 320 }}
                >
                  <img
                    alt="ubihouse mini görsel"
                    src={UbiHouseMini}
                    style={{
                      maxHeight: 150,
                      maxWidth: 150,
                    }}
                  />
                  <Button
                    href="/product/ubihouse-mini"
                    style={{
                      textTransform: "none",
                      color: "#000",
                      fontSize: 16,
                    }}
                  >
                    UbiHouse Mini
                  </Button>
                </Grid>
                <Typography style={{ marginLeft: 150 }}>680 TL / Ay</Typography>
                <Typography
                  style={{
                    marginLeft: 150,
                    marginRight: 80,
                    color: "#717478",
                  }}
                >
                  12 Ay
                </Typography>
                <Button disabled>Satın Al</Button>
              </Grid>
            </Grid>

            <Divider
              variant="fullWidth"
              flexItem
              style={{ marginBottom: 10, marginTop: 10 }}
            />

            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: 320 }}
                >
                  <img
                    alt="ubihouse görsel"
                    src={UbiHouse}
                    style={{
                      maxHeight: 150,
                      maxWidth: 150,
                    }}
                  />
                  <Button
                    href="/product/ubihouse"
                    style={{
                      textTransform: "none",
                      color: "#000",
                      fontSize: 16,
                    }}
                  >
                    UbiHouse
                  </Button>
                </Grid>
                <Typography style={{ marginLeft: 150 }}>
                  1,400 TL / Ay
                </Typography>
                <Typography
                  style={{
                    marginLeft: 150,
                    marginRight: 80,
                    color: "#717478",
                  }}
                >
                  12 Ay
                </Typography>
                <Button disabled>Satın Al</Button>
              </Grid>
            </Grid>

            <Divider
              variant="fullWidth"
              flexItem
              style={{ marginBottom: 10, marginTop: 10 }}
            />

            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: 320 }}
                >
                  <img
                    alt="ubihouse pro görsel"
                    src={UbiHouseProMini}
                    style={{
                      maxHeight: 150,
                      maxWidth: 150,
                    }}
                  />
                  <Button
                    href="/product/ubihouse-pro"
                    style={{
                      textTransform: "none",
                      color: "#000",
                      fontSize: 16,
                    }}
                  >
                    UbiHouse Pro
                  </Button>
                </Grid>
                <Typography style={{ marginLeft: 150 }}>
                  2,600 TL / Ay
                </Typography>
                <Typography
                  style={{
                    marginLeft: 140,
                    marginRight: 80,
                    color: "#717478",
                  }}
                >
                  12 Ay
                </Typography>
                <Button disabled>Satın Al</Button>
              </Grid>
            </Grid>

            <Divider
              variant="fullWidth"
              flexItem
              style={{ marginBottom: 10, marginTop: 10 }}
            />

            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: 320 }}
                >
                  <img
                    alt="ubihouse pro max görsel"
                    src={UbiHousePro}
                    style={{
                      maxHeight: 150,
                      maxWidth: 150,
                    }}
                  />
                  <Button
                    href="/product/ubihouse-pro-max"
                    style={{
                      textTransform: "none",
                      color: "#000",
                      fontSize: 16,
                    }}
                  >
                    UbiHouse Pro Max
                  </Button>
                </Grid>
                <Typography style={{ marginLeft: 150 }}>
                  4,500 TL / Ay
                </Typography>
                <Typography
                  style={{
                    marginLeft: 140,
                    marginRight: 80,
                    color: "#717478",
                  }}
                >
                  12 Ay
                </Typography>
                <Button disabled>Satın Al</Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Footer />
    </div>
  );
};

export default Abonelik;
