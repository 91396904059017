import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";

// Image Imports
import UbiEnergyGorsel from "../assets/images/UbiEnergy.jpg";

const UbiEnergyNedir = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="UbiEnergy nedir, ne işe yarar öğrenin"
        />
        <title>UbiEnergy Nedir - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/ubienergy-nedir" />
      </Helmet>
      <Header />
      <Grid
        container
        direction="column"
        style={{ backgroundColor: "#F5F5F7", marginBottom: 80 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
          style={{ padding: 30 }}
        >
          <Typography
            variant="h3"
            component="h1"
            style={{ color: "#1D1D1F", marginRight: 8 }}
          >
            UbiEnergy
          </Typography>
          <Typography variant="h3" style={{ color: "#6E6E73" }}>
            Nedir?
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid
          container
          rowSpacing={3}
          justifyContent="center"
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ padding: 20, maxWidth: 1200 }}
        >
          <Grid item xs={12} md={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography gutterBottom style={{ fontSize: 24 }}>
                Başka bir şeye ihtiyacınız yok
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                UBIENERGY paketi bitkilerinizin ihtiyacı olan tüm içeriklere
                sahiptir. Ekstra bir içeriği eklemenize gerek kalmaz.
              </Typography>
              <img
                src={UbiEnergyGorsel}
                alt="TumUrunler"
                style={{
                  borderRadius: 10,
                  maxHeight: 200,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography style={{ fontSize: 24 }}>Besin</Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                Bitkilerinizin ihtiyacı olan tüm besinler bu şişede.
              </Typography>
              <img
                src={UbiEnergyGorsel}
                alt="TumUrunler"
                style={{
                  borderRadius: 10,
                  maxHeight: 220,
                  marginTop: 40,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#F5F5F7",
                borderRadius: 20,
                padding: 30,
                textAlign: "center",
              }}
            >
              <Typography style={{ fontSize: 24 }}>
                Ph derecesi Dengeleyici
              </Typography>
              <Typography style={{ fontSize: 18, color: "#6E6E73" }}>
                UbiHouse’unuzun deposuna koyduğunuz suyu bitkileri yetiştirmeye
                uygun hale getirir.
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={UbiEnergyGorsel}
                  alt="PhDengeleyici"
                  style={{
                    borderRadius: 10,
                    maxHeight: 200,
                    marginTop: 40,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ height: 150 }} />

      <Footer />
    </div>
  );
};

export default UbiEnergyNedir;
