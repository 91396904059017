import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { get_ubitray, get_filtered_products } from "../actions/products";
import {
  add_item,
  get_items,
  get_total,
  get_item_total,
} from "../actions/cart";
import { get_categories } from "../actions/categories";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Grid,
  Card as MuiCard,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import UbiTrayCard from "../components/UbiTrayCard";
import { Helmet } from "react-helmet";

const UbiTray = ({
  categories,
  get_categories,
  products,
  get_ubitray,
  filtered_products,
  get_filtered_products,
  add_item,
  get_items,
  get_total,
  get_item_total,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [filtered] = useState(false);

  useEffect(() => {
    get_categories();
    get_ubitray();
    window.scrollTo(0, 0);
  }, []);

  const showProducts = () => {
    let results = [];

    if (!filtered && products && products !== null && products !== undefined) {
      products.map((product, index) => {
        return results.push(
          <div key={index}>
            <UbiTrayCard
              product={product}
              add_item={add_item}
              get_items={get_items}
              get_total={get_total}
              get_item_total={get_item_total}
              setRedirect={setRedirect}
            />
          </div>
        );
      });
    }

    return results;
  };

  if (redirect) return <Redirect to="/cart-or-continue-shopping" />;

  let width = window.innerWidth;

  if (width > 800) {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="UbiTray ile yeni nesil tohumlar ile tanışın ekme, biçme gibi konularla uğraşmadan sebze ve meyve üretin"
          />
          <title>UbiTray Liste - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/ubitray" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          style={{ backgroundColor: "#F5F5F7" }}
        >
          <Grid
            item
            container
            direction="row"
            style={{ paddingLeft: 40, maxWidth: 1600, alignSelf: "center" }}
          >
            <Grid
              lg={8}
              item
              container
              direction="column"
              style={{ marginTop: 40 }}
            >
              <Typography
                variant="h3"
                component="h1"
                style={{ color: "#1D1D1F", marginBottom: 15 }}
              >
                UbiTray.
              </Typography>
              <Typography variant="h4" style={{ color: "#6E6E73" }}>
                Sağlıklı hayat sağlıklı beslenmeden başlar.
              </Typography>
            </Grid>

            <Grid lg={4} item container direction="row">
              <MuiCard
                className="scrollMenuComponent"
                style={{
                  width: 400,
                  borderRadius: 20,
                  marginTop: 40,
                }}
              >
                <CardContent>
                  <Grid>
                    <Typography gutterBottom variant="h5">
                      Abonelik
                    </Typography>
                    <Typography color="textSecondary">
                      Ücretsiz kargo imkanı.
                    </Typography>
                    <Typography color="textSecondary">
                      İstediğiniz zaman iptal edin.
                    </Typography>
                    <Typography color="textSecondary">
                      Her ay size yetecek kadar alın ve ona göre ödeyin.
                    </Typography>
                  </Grid>
                </CardContent>
                <Button
                  href="/abonelik"
                  style={{
                    margin: 20,
                    marginLeft: 70,
                    textTransform: "none",
                  }}
                >
                  Abonelik Seçeneklerini İnceleyin
                </Button>
              </MuiCard>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 50, marginBottom: 100 }}
            >
              {showProducts()}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="UbiTray ile yeni nesil tohumlar ile tanışın ekme, biçme gibi konularla uğraşmadan sebze ve meyve üretin"
          />
          <title>UbiTray Liste - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/ubitray" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          style={{ backgroundColor: "#F5F5F7" }}
        >
          <Grid item container direction="row" style={{ padding: 10 }}>
            <Grid
              lg={8}
              item
              container
              direction="column"
              style={{ marginTop: 40 }}
            >
              <Typography
                variant="h3"
                component="h1"
                style={{ color: "#1D1D1F" }}
              >
                UbiTray.
              </Typography>
              <Typography variant="h3" style={{ color: "#6E6E73" }}>
                Sağlıklı bir hayat için ilk adım.
              </Typography>
            </Grid>

            <Grid lg={4} item container direction="row">
              <MuiCard
                className="scrollMenuComponent"
                style={{
                  width: 400,
                  borderRadius: 20,
                  marginTop: 40,
                }}
              >
                <CardContent>
                  <Grid>
                    <Typography gutterBottom variant="h5">
                      Abonelik
                    </Typography>
                    <Typography color="textSecondary">
                      Ücretsiz kargo imkanı.
                    </Typography>
                    <Typography color="textSecondary">
                      İstediğiniz zaman iptal edin.
                    </Typography>
                    <Typography color="textSecondary">
                      Her ay size yetecek kadar alın ve ona göre ödeyin.
                    </Typography>
                  </Grid>
                </CardContent>
                <Button
                  href="/abonelik"
                  style={{
                    margin: 20,
                    marginLeft: 70,
                    textTransform: "none",
                  }}
                >
                  Abonelik Seçeneklerini İnceleyin
                </Button>
              </MuiCard>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 50, marginBottom: 100 }}
            >
              {showProducts()}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  products: state.products.products,
  filtered_products: state.products.filtered_products,
});

export default connect(mapStateToProps, {
  get_categories,
  get_ubitray,
  get_filtered_products,
  add_item,
  get_items,
  get_total,
  get_item_total,
})(UbiTray);
