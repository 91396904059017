import React, { useState } from "react";
import axios from "axios";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ReviewsIcon from "@mui/icons-material/Reviews";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import SendIcon from "@mui/icons-material/Send";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const actions = [
  {
    icon: <WhatsAppIcon style={{ color: "#5DCA45" }} />,
    name: "WhatsApp",
    link: "https://wa.me/905303332050",
  },
  { icon: <ReviewsIcon />, name: "Chatbot" },
];

function ChatBot() {
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // New state for loading status

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleActionClick = (action) => {
    if (action.name === "WhatsApp") {
      window.open(action.link);
    } else if (action.name === "Chatbot") {
      setOpen(true);
    }
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleButtonClick = (questionText) => {
    setQuestion(questionText);
    handleSubmitQuestion(questionText);
  };

  const handleSubmitQuestion = (questionText = question) => {
    setIsLoading(true); // Set loading to true before making request
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/chatbot/chatbot`, {
        question: questionText,
      })
      .then((response) => {
        setHistory([
          ...history,
          { question: questionText, answer: response.data.answer },
        ]);
        setQuestion("");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after getting response
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // This function splits a given text into chunks of text and URLs
  function splitTextAndLinks(text) {
    // Updated regex: ensures the URL doesn't end with a period
    const urlRegex = /(https?:\/\/[\S]+)(?<!\.)\b/gi;
    let result = [];
    let lastIndex = 0;

    let match;
    while ((match = urlRegex.exec(text)) !== null) {
      result.push(text.slice(lastIndex, match.index)); // Push text before the URL
      result.push(match[0]); // Push the URL
      lastIndex = urlRegex.lastIndex;
    }

    result.push(text.slice(lastIndex)); // Push remaining text after the last URL

    return result;
  }

  return (
    <div>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
        icon={<AutoAwesomeIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleActionClick(action)}
          />
        ))}
      </SpeedDial>

      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { display: "flex", justifyContent: "center" } }}
      >
        <DialogTitle>
          Ubi Bot
          <IconButton
            style={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ width: "70%", minWidth: 400 }}>
            <List>
              {history.length > 0 ? (
                history.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={item.question}
                      secondary={
                        // Process the answer to extract and render URLs as buttons
                        <div>
                          {splitTextAndLinks(item.answer).map(
                            (chunk, chunkIndex) => {
                              // If chunk is a URL, render it as a button
                              if (/^https?:\/\/[\S]+$/i.test(chunk)) {
                                return (
                                  <Button
                                    key={chunkIndex}
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    style={{ margin: 5, textTransform: "none" }}
                                    onClick={() => window.open(chunk, "_blank")}
                                  >
                                    {/* Display the URL itself inside the button */}
                                    {chunk}
                                  </Button>
                                );
                              } else {
                                // Else, render the chunk as plain text
                                return <span key={chunkIndex}>{chunk}</span>;
                              }
                            }
                          )}
                        </div>
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  style={{ marginBottom: 20 }}
                >
                  <ListItem>
                    <ListItemText primary="Merhaba, ben Ubicro'nun chatbot'uyum. Yardımcı olmamı istediğiniz soruyu aşağıya yazabilirsiniz" />
                  </ListItem>
                  <Button
                    variant="text"
                    color="primary"
                    disabled={isLoading}
                    style={{ marginTop: 10, textTransform: "none" }}
                    onClick={() =>
                      handleButtonClick(
                        "UbiHouse Mini hakkında bilgi almak istiyorum"
                      )
                    }
                  >
                    UbiHouse Mini hakkında bilgi almak istiyorum
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    disabled={isLoading}
                    style={{ marginTop: 10, textTransform: "none" }}
                    onClick={() => handleButtonClick("Neler yetiştirebilirim?")}
                  >
                    Neler yetiştirebilirim?
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    disabled={isLoading}
                    style={{ marginTop: 10, textTransform: "none" }}
                    onClick={() => handleButtonClick("Ne zaman teslim edilir?")}
                  >
                    Ne zaman teslim edilir?
                  </Button>
                </Grid>
              )}
            </List>
          </div>
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent="center">
            <TextField
              autoFocus
              margin="dense"
              label="Sorunuz"
              type="text"
              fullWidth
              value={question}
              onChange={handleQuestionChange}
              style={{ width: "70%", minWidth: 400 }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSubmitQuestion();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleSubmitQuestion(question)}
                      disabled={isLoading}
                      edge="end"
                      style={{ marginRight: 5 }}
                      color="primary"
                    >
                      {isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <SendIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChatBot;
