import {
    ADD_DEVICE,
    GET_DEVICE_TOTAL,
    GET_DEVICE,
    REMOVE_DEVICE,
    ADD_DEVICE_SUCCESS,
    ADD_DEVICE_FAIL,
    GET_DEVICE_TOTAL_SUCCESS,
    GET_DEVICE_TOTAL_FAIL,
    GET_DEVICES_SUCCESS,
    GET_DEVICES_FAIL,
    REMOVE_DEVICE_SUCCESS,
    REMOVE_DEVICE_FAIL,
    SYNCH_DEVICE_LIST_SUCCESS,
    SYNCH_DEVICE_LIST_FAIL,
    GET_DEVICE_DETAIL_SUCCESS,
    GET_DEVICE_DETAIL_FAIL,
} from "../actions/types";

const initialState = {
    items: null,
    total_items: 0,
    product: null,
};

export default function device(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_DEVICE_SUCCESS:
            return {
                ...state,
                items: payload.cart,
            };
        case ADD_DEVICE_FAIL:
            return {
                ...state,
                items: null,
            };
        case ADD_DEVICE:
            localStorage.setItem("cart", JSON.stringify(payload));
            return {
                ...state,
                items: JSON.parse(localStorage.getItem("cart")),
            };
        case GET_DEVICES_SUCCESS:
            return {
                ...state,
                items: payload.cart,
            };
        case GET_DEVICES_FAIL:
            return {
                ...state,
                items: null,
            };
        case GET_DEVICE_DETAIL_SUCCESS:
            return {
                ...state,
                product: payload.product,
            };
        case GET_DEVICE_DETAIL_FAIL:
            return {
                ...state,
                product: null,
            };
        case GET_DEVICE:
            return {
                ...state,
                items: JSON.parse(localStorage.getItem("cart")),
            };
        case GET_DEVICE_TOTAL_SUCCESS:
            return {
                ...state,
                total_items: payload.total_items,
            };
        case GET_DEVICE_TOTAL_FAIL:
            return {
                ...state,
                total_items: 0,
            };
        case GET_DEVICE_TOTAL:
            return {
                ...state,
                total_items: payload,
            };
        case REMOVE_DEVICE_SUCCESS:
            return {
                ...state,
                items: payload.cart,
            };
        case REMOVE_DEVICE_FAIL:
            return {
                ...state,
            };
        case REMOVE_DEVICE:
            localStorage.setItem("cart", JSON.stringify(payload));
            return {
                ...state,
                items: JSON.parse(localStorage.getItem("cart")),
            };
        case SYNCH_DEVICE_LIST_SUCCESS:
        case SYNCH_DEVICE_LIST_FAIL:
            localStorage.removeItem("cart");
            return {
                ...state,
            };
        default:
            return state;
    }
}
