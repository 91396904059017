import axios from "axios";
import { setAlert } from "./alert";
import { get_item_total } from "./cart";
import {
  GET_PAYMENT_TOTAL_SUCCESS,
  GET_PAYMENT_TOTAL_FAIL,
  LOAD_BT_TOKEN_SUCCESS,
  LOAD_BT_TOKEN_FAIL,
  PAYMENT_SUCCESS,
  PAYMENT_FAIL,
  RESET_PAYMENT_INFO,
  SET_PAYMENT_LOADING,
  REMOVE_PAYMENT_LOADING,
  // SEND_TO_3D_SUCCESS,
  // SEND_TO_3D_FAIL,
  MOKA_DIRECT_PAYMENT_SUCCESS,
  MOKA_DIRECT_PAYMENT_FAIL,
} from "./types";

export const get_payment_total =
  (shipping_id, coupon_name) => async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/payment/get-payment-total?shipping_id=${shipping_id}&coupon_name=${coupon_name}`,
        config
      );

      if (res.status === 200 && !res.data.error) {
        dispatch({
          type: GET_PAYMENT_TOTAL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_PAYMENT_TOTAL_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_PAYMENT_TOTAL_FAIL,
      });
    }
  };

export const get_client_token = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/payment/get-token`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: LOAD_BT_TOKEN_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: LOAD_BT_TOKEN_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: LOAD_BT_TOKEN_FAIL,
    });
  }
};

export const process_payment =
  (
    shipping_id,
    coupon_name,
    full_name,
    address_line_1,
    city,
    district,
    telephone_number
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify({
      shipping_id,
      coupon_name,
      full_name,
      address_line_1,
      city,
      district,
      telephone_number,
    });

    dispatch({
      type: SET_PAYMENT_LOADING,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/moka/make-payment`,
        body,
        config
      );

      if (res.status === 200 && res.data.success) {
        dispatch({
          type: PAYMENT_SUCCESS,
        });
        dispatch(setAlert(res.data.success, "success"));
        dispatch(get_item_total());
      } else {
        dispatch({
          type: PAYMENT_FAIL,
        });
        dispatch(setAlert(res.data.error, "error"));
      }
    } catch (err) {
      dispatch({
        type: PAYMENT_FAIL,
      });
      dispatch(setAlert("İşlem gerçekleştirilemedi bir hata oluştu", "error"));
    }

    dispatch({
      type: REMOVE_PAYMENT_LOADING,
    });
    window.scrollTo(0, 0);
  };

// export const moka_send_to_3d =
//   (
//     shipping_id,
//     coupon_name,
//     card_holder,
//     card_number,
//     card_expiry_month,
//     card_expiry_year,
//     card_cvv
//   ) =>
//   async (dispatch) => {
//     const config = {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `JWT ${localStorage.getItem("access")}`,
//       },
//     };

//     const body = JSON.stringify({
//       shipping_id,
//       coupon_name,
//       card_holder,
//       card_number,
//       card_expiry_month,
//       card_expiry_year,
//       card_cvv,
//     });

//     dispatch({
//       type: SET_PAYMENT_LOADING,
//     });

//     try {
//       const res = await axios.post(
//         `${process.env.REACT_APP_API_URL}/api/moka/send-to-3d`,
//         body,
//         config
//       );

//       if (res.status === 200 && res.data.success) {
//         dispatch({
//           type: SEND_TO_3D_SUCCESS,
//         });
//         dispatch(setAlert(res.data.success, "success"));
//         dispatch(get_item_total());
//       } else {
//         dispatch({
//           type: SEND_TO_3D_FAIL,
//         });
//         dispatch(setAlert(res.data.error, "error"));
//       }
//     } catch (err) {
//       dispatch({
//         type: SEND_TO_3D_FAIL,
//       });
//       dispatch(
//         setAlert("3D yönlendirmesi yapılamadı, bir hata oluştu", "error")
//       );
//     }

//     dispatch({
//       type: REMOVE_PAYMENT_LOADING,
//     });
//     window.scrollTo(0, 0);
//   };

export const moka_direct_payment =
  (
    shipping_id,
    coupon_name,
    card_holder,
    card_number,
    card_expiry_month,
    card_expiry_year,
    card_cvv
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify({
      shipping_id,
      coupon_name,
      card_holder,
      card_number,
      card_expiry_month,
      card_expiry_year,
      card_cvv,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/moka/make-direct-payment`,
        body,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: MOKA_DIRECT_PAYMENT_SUCCESS,
        });
        dispatch(setAlert("İşlem onaylandı", "success"));
      } else {
        dispatch({
          type: MOKA_DIRECT_PAYMENT_FAIL,
        });
        dispatch(setAlert(res.error, "error"));
      }
    } catch (error) {
      // Log the entire error object for debugging purposes
      console.error("Error object:", error);

      // If the server returned a response, log that too
      if (error.response) {
        console.error("Server response:", error.response.data);
      }

      dispatch({
        type: MOKA_DIRECT_PAYMENT_FAIL,
      });
      dispatch(setAlert("Ödeme alınamadı lütfen tekrar deneyin", "error"));
    }
  };

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET_PAYMENT_INFO,
  });
};
