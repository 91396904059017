import React, { Fragment } from "react";
import { Grid, Card, CardContent, CardMedia, Typography } from "@mui/material";

// Image imports
import firstImage from "../assets/images/1AsamaSiyah.jpg";
import secondImage from "../assets/images/2AsamaSiyah.jpg";
import thirdImage from "../assets/images/3AsamaSiyah.jpg";
import fourthImage from "../assets/images/4AsamaSiyah.jpg";
import fifthImage from "../assets/images/5AsamaSiyah.jpg";

const ListItemAdimlar = () => {
  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 40 }}
        >
          <Grid item container direction="row">
            <Typography variant="h5">5 Adımda Üretim</Typography>
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography
                  gutterBottom
                  color="textSecondary"
                  style={styles.textTitle}
                >
                  1. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  Prize tak ve çalıştır
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="1. Adım"
              src={firstImage}
              title="1. Adım"
              style={{
                height: 240,
                marginTop: 70,
              }}
            />
          </Card>
          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography
                  gutterBottom
                  color="textSecondary"
                  style={styles.textTitle}
                >
                  2. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  Su ve UbiEnergy ekle
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="2. Adım"
              src={secondImage}
              title="2. Adım"
              style={{
                height: 240,
                marginTop: 70,
              }}
            />
          </Card>
          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography
                  gutterBottom
                  color="textSecondary"
                  style={styles.textTitle}
                >
                  3. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  UbiTray'leri yerleştir
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="3. Adım"
              src={thirdImage}
              title="3. Adım"
              style={{
                height: 240,
                marginTop: 70,
              }}
            />
          </Card>
          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography
                  gutterBottom
                  color="textSecondary"
                  style={styles.textTitle}
                >
                  4. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  Bitkilerin büyüsün
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="4. Adım"
              src={fourthImage}
              title="4. Adım"
              style={{
                height: 240,
                marginTop: 70,
              }}
            />
          </Card>
          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
              marginRight: 50,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography
                  gutterBottom
                  color="textSecondary"
                  style={styles.textTitle}
                >
                  5. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  Afiyet Olsun!
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="5. Adım"
              src={fifthImage}
              title="5. Adım"
              style={{
                height: 240,
                marginTop: 70,
              }}
            />
          </Card>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 20 }}
        >
          <Grid item container direction="row">
            <Typography variant="h5" style={{ marginRight: 5 }}>
              5 Adımda üretim
            </Typography>
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography gutterBottom style={styles.textTitle}>
                  1. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  Prize tak ve çalıştır
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="1. Adım"
              src={firstImage}
              title="1. Adım"
              style={{
                height: 190,
                width: 320,
                marginTop: 70,
              }}
            />
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography gutterBottom style={styles.textTitle}>
                  2. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  Su ve UbiEnergy ekle
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="2. Adım"
              src={secondImage}
              title="2. Adım"
              style={{
                height: 190,
                width: 320,
                marginTop: 70,
              }}
            />
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography gutterBottom style={styles.textTitle}>
                  3. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  UbiTray'leri yerleştir
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="3. Adım"
              src={thirdImage}
              title="3. Adım"
              style={{
                height: 190,
                width: 320,
                marginTop: 70,
              }}
            />
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
              marginRight: 20,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography gutterBottom style={styles.textTitle}>
                  4. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  Bitkilerin büyüsün
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="4. Adım"
              src={fourthImage}
              title="4. Adım"
              style={{
                height: 190,
                width: 320,
                marginTop: 70,
              }}
            />
          </Card>
          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
              marginRight: 20,
              backgroundColor: "#000",
            }}
            className="scrollMenuComponent"
          >
            <CardContent>
              <Grid>
                <Typography gutterBottom style={styles.textTitle}>
                  5. Adım
                </Typography>
                <Typography variant="h5" style={styles.textBody}>
                  Afiyet Olsun!
                </Typography>
              </Grid>
            </CardContent>
            <CardMedia
              component="img"
              alt="5. Adım"
              src={fifthImage}
              title="5. Adım"
              style={{
                height: 190,
                width: 320,
                marginTop: 70,
              }}
            />
          </Card>
        </div>
      </Fragment>
    );
  }
};

const styles = {
  textBody: {
    color: "#fff",
  },
  textTitle: {
    color: "silver",
  },
};

export default ListItemAdimlar;
