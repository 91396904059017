import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const Hakkimizda = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Hakkımızda sayfası" />
        <title>Hakkımızda - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/hakkimizda" />
      </Helmet>
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: 300, width: "100%", backgroundColor: "#fbfbfb" }}
      >
        <Typography variant="h2" component="h1">
          Hakkımızda
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{ marginTop: 40, padding: 20 }}
      >
        <Grid item container direction="column" md={6} xs={12}>
          <Typography
            style={{
              color: "#5AC496",
              letterSpacing: 1,
              fontSize: 22,
              fontWeight: 100,
            }}
          >
            ŞİRKET OLARAK HEDEFİMİZ
          </Typography>
          <Typography variant="h4" style={{ marginTop: 20 }}>
            Ubicro Vizyonu
          </Typography>
        </Grid>
        <Grid item container md={4} xs={12}>
          <Typography style={{ color: "#717478", marginTop: 60 }}>
            Ubicro, insanların her zaman ve her yerde, taze, pestisitsiz ve
            yüksek besin değerine sahip mahsullerin yetiştirilebilmesini
            amaçlamaktadır. İnsan gücüne gerek kalmadan IoT teknolojisi
            kullanarak topraksız tarım yapan teknolojiler geliştiriyor.
          </Typography>
        </Grid>
      </Grid>

      <Grid style={{ height: 200, width: " 100% " }} />
      <Footer />
    </div>
  );
};

export default Hakkimizda;
