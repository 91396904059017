import React from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";

// Image imports
import AppHomePage from "../assets/images/app-login.jpg";
import AppleIcon from "@mui/icons-material/Apple";
import GooglePlayIcon from "../assets/images/google-play-icon.png";

function AppCard() {
  let width = window.innerWidth;
  if (width > 1300) {
    return (
      <>
        <Grid
          container
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 40,
          }}
        >
          <Grid
            item
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginRight: 200,
              marginLeft: 40,
              maxWidth: 800,
            }}
          >
            <Typography variant="h5">
              İstediğiniz yerde üretime başlayın
            </Typography>
            <Typography
              style={{
                color: "grey",
                fontSize: 18,
                marginTop: 20,
                marginBottom: 80,
              }}
            >
              Yanı başınızda taze ve sağlıklı üretim ile yeni bir tarım çağına
              beraber geçmeye ne dersiniz.
            </Typography>
            <Button
              variant="outlined"
              href="https://apps.apple.com/app/id1659111277"
              startIcon={<AppleIcon style={{ color: "silver" }} />}
              style={{
                textTransform: "None",
                marginRight: 30,
                fontSize: 18,
              }}
            >
              App Store’dan İndirin
            </Button>

            <Button
              variant="outlined"
              href="https://play.google.com/store/apps/details?id=com.ubicro.ubicro"
              startIcon={
                <img
                  src={GooglePlayIcon}
                  alt="Google Play Button"
                  style={styles.icon}
                />
              }
              style={{ textTransform: "None", fontSize: 18 }}
            >
              Google Play'den İndirin
            </Button>
          </Grid>
          <img src={AppHomePage} alt="App Home Page" style={styles.logos} />
        </Grid>
        <Divider style={{ margin: 40 }} />
      </>
    );
  }
  if ((width > 500) & (width <= 1300)) {
    return (
      <>
        <Grid
          container
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 40,
          }}
        >
          <Grid
            item
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5">
                İstediğiniz yerde üretime başlayın
              </Typography>
              <Typography
                style={{
                  color: "grey",
                  fontSize: 18,
                  marginTop: 20,
                  marginBottom: 80,
                  textAlign: "center",
                }}
              >
                Yanı başınızda taze ve sağlıklı üretim ile yeni bir tarım çağına
                beraber geçmeye ne dersiniz. Mobil uygulama çok yakında.
              </Typography>
              <Button
                variant="outlined"
                href="https://apps.apple.com/app/id1659111277"
                startIcon={<AppleIcon style={{ color: "silver" }} />}
                style={{
                  textTransform: "None",
                  marginRight: 30,
                  fontSize: 18,
                }}
              >
                App Store’dan İndirin
              </Button>

              <Button
                variant="outlined"
                href="https://play.google.com/store/apps/details?id=com.ubicro.ubicro"
                startIcon={
                  <img
                    src={GooglePlayIcon}
                    alt="Google Play Button"
                    style={styles.icon}
                  />
                }
                style={{ textTransform: "None", fontSize: 18 }}
              >
                Google Play'den İndirin
              </Button>
            </Grid>
          </Grid>
          <img
            src={AppHomePage}
            alt="App Home Page"
            style={{ maxWidth: 200, marginTop: 50 }}
          />
        </Grid>
        <Divider style={{ margin: 40 }} />
      </>
    );
  } else {
    return (
      <>
        <Grid
          container
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 40,
          }}
        >
          <Grid
            item
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5">
                İstediğiniz yerde üretime başlayın
              </Typography>
              <Typography
                style={{
                  color: "grey",
                  fontSize: 18,
                  marginTop: 20,
                  marginBottom: 50,
                }}
              >
                Yanı başınızda taze ve sağlıklı üretim ile yeni bir tarım çağına
                beraber geçmeye ne dersiniz. Mobil uygulama çok yakında.
              </Typography>
              <Button
                variant="outlined"
                href="https://apps.apple.com/app/id1659111277"
                startIcon={<AppleIcon style={{ color: "silver" }} />}
                style={{
                  textTransform: "None",
                  fontSize: 16,
                  marginRight: 10,
                }}
              >
                App Store
              </Button>

              <Button
                variant="outlined"
                href="https://play.google.com/store/apps/details?id=com.ubicro.ubicro"
                startIcon={
                  <img
                    src={GooglePlayIcon}
                    alt="Google Play Button"
                    style={styles.icon}
                  />
                }
                style={{ textTransform: "None", fontSize: 16 }}
              >
                Google Play
              </Button>
            </Grid>
          </Grid>
          <img
            src={AppHomePage}
            alt="App Home Page"
            style={{ maxWidth: 150, marginTop: 50 }}
          />
        </Grid>
        <Divider style={{ margin: 40 }} />
      </>
    );
  }
}

const styles = {
  logos: {
    maxWidth: 200,
    borderRadius: 30,
  },
  icon: {
    width: 20,
  },
};

export default AppCard;
