import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const CartItem = ({
  item,
  product,
  count,
  update_item,
  remove_item,
  setAlert,
  render,
  setRender,
  showViewProduct = true,
  showRemoveProduct = true,
  showUpdateProduct = true,
  showQuantity = false,
}) => {
  const [formData, setFormData] = useState({
    item_count: 1,
  });

  const { item_count } = formData;

  useEffect(() => {
    if (count) setFormData({ ...formData, item_count: count });
  }, [count]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        if (item.product.quantity >= item_count) {
          await update_item(item, item_count);
        } else {
          setAlert("Bu üründe yeterli kontenjan bulunmamakta", "error");
        }
        setRender(!render);
      } catch (err) {}
    };

    fetchData();
  };

  const quantityOrdered = () => {
    if (showQuantity && count) {
      return <Typography>Adet: {count}</Typography>;
    }
  };

  const showAdjustQuantityButton = () => {
    if (showUpdateProduct) {
      return (
        <form onSubmit={(e) => onSubmit(e)}>
          <Select
            name="item_count"
            value={item_count}
            label="item_count"
            onChange={(e) => onChange(e)}
            sx={{ width: 35, marginRight: 1, height: 35 }}
            IconComponent="False"
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
          </Select>
          <Button type="submit">Güncelle</Button>
        </form>
      );
    }
  };

  const removeItemHandler = async () => {
    await remove_item(item);
    setRender(!render);
  };

  const showRemoveProductButton = () => {
    if (showRemoveProduct) {
      return (
        <Grid item>
          <IconButton color="error" onClick={removeItemHandler}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      );
    }
  };

  const QuantitiyShown = () => {
    return (
      <Grid item>
        <Typography>
          {item &&
          item !== null &&
          item !== undefined &&
          item.product &&
          item.product !== null &&
          item.product !== undefined &&
          item.product.quantity > 0 ? (
            <Typography variant="subtitle2" style={{ color: "#548346" }}>
              Stokta var
            </Typography>
          ) : (
            <Typography style={{ color: "red" }}>Stokta yok</Typography>
          )}
        </Typography>
      </Grid>
    );
  };

  const ProductName = () => {
    return (
      <Grid>
        <Typography variant="h6">
          {item &&
            item !== null &&
            item !== undefined &&
            item.product &&
            item.product !== null &&
            item.product !== undefined &&
            item.product.name}
        </Typography>
      </Grid>
    );
  };

  const realPrice = () => {
    return (
      <Grid>
        <Typography>
          {item &&
            item !== null &&
            item !== undefined &&
            item.product &&
            item.product !== null &&
            item.product !== undefined &&
            item.product.price
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          ₺
        </Typography>
      </Grid>
    );
  };

  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Grid container justifyContent="center">
        <Grid container justifyContent="center" alignItems="center">
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              width: 800,
              height: 150,
              marginTop: 5,
              padding: 2,
            }}
          >
            <CardActionArea
              href={`/product/${
                item &&
                item !== null &&
                item !== undefined &&
                item.product &&
                item.product !== null &&
                item.product !== undefined
                  ? item.product.slug
                  : 0
              }`}
            >
              <CardMedia
                component="img"
                style={{
                  maxWidth: 150,
                  minWidth: 150,
                  minHeight: 150,
                  maxHeight: 150,
                }}
                image={
                  item &&
                  item !== null &&
                  item !== undefined &&
                  item.product &&
                  item.product !== null &&
                  item.product !== undefined
                    ? item.product.image
                    : ""
                }
                alt="Ürün görseli"
              />
            </CardActionArea>
            <Grid
              item
              style={{
                marginLeft: 20,
                minWidth: 200,
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              {ProductName()}
              {QuantitiyShown()}
              {item &&
              item !== null &&
              item !== undefined &&
              item.product.presale_price !== null &&
              item.product.presale_price !== undefined ? (
                <Alert severity="info" style={{ width: 100, marginTop: 10 }}>
                  Ön satışta
                </Alert>
              ) : (
                <></>
              )}
            </Grid>
            <Grid
              item
              style={{
                minWidth: 200,
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              {quantityOrdered()}
              {showAdjustQuantityButton()}
            </Grid>
            <Grid
              item
              style={{
                minWidth: 150,
                alignSelf: "center",
                justifyContent: "center",
                marginRight: 20,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>{realPrice()}</Grid>
                <Grid item style={{ marginRight: 10, marginLeft: 15 }}>
                  {showRemoveProductButton()}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container justifyContent="center">
        <Grid container justifyContent="center" alignItems="center">
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              width: "95%",
              minHeight: 150,
              marginTop: 5,
              padding: 2,
            }}
          >
            <CardActionArea
              sx={{ width: "30%" }}
              href={`/product/${
                item &&
                item !== null &&
                item !== undefined &&
                item.product &&
                item.product !== null &&
                item.product !== undefined
                  ? item.product.slug
                  : 0
              }`}
            >
              <CardMedia
                component="img"
                style={{ width: "100%", marginTop: 20 }}
                image={
                  item &&
                  item !== null &&
                  item !== undefined &&
                  item.product &&
                  item.product !== null &&
                  item.product !== undefined
                    ? item.product.image
                    : ""
                }
                alt="Ürün görseli"
              />
            </CardActionArea>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                {ProductName()}
                {QuantitiyShown()}
                {item &&
                item !== null &&
                item !== undefined &&
                item.product.presale_price !== null &&
                item.product.presale_price !== undefined ? (
                  <Alert
                    severity="info"
                    style={{
                      width: 100,
                      height: 34,
                      fontSize: 12,
                      marginBottom: 5,
                    }}
                  >
                    Ön satışta
                  </Alert>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" direction="row">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      style={{ marginRight: 80 }}
                    >
                      {quantityOrdered()}
                      {showAdjustQuantityButton()}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" direction="row">
                      {realPrice()}
                      {showRemoveProductButton()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

export default CartItem;
