import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';


const Alert = ({ alert }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = ({ alert }) => {
    setOpen(alert);
  };

    const displayAlert = () => {
        if (alert !== null) {
            return (
                <Snackbar open={handleClick}>
                    <MuiAlert severity={alert.alertType} variant="filled">
                        {alert.msg}
                    </MuiAlert>
                </Snackbar>
            );
        } else {
            return (
                <Fragment></Fragment>
            );
        }
    };

    return (
        <Fragment>
                {displayAlert()}
        </Fragment>
    );
};

const mapStateToProps = state => ({
    alert: state.alert.alert
});

export default connect(mapStateToProps)(Alert);