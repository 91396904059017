import React from "react";
import { Grid, Typography, Button, Divider } from "@mui/material";

const card = ({ product }) => {
  console.log(product.presale_price);
  return (
    <div>
      <Grid container>
        <Grid
          container
          direction="column"
          xs={12}
          md={6}
          justifyContent="center"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="h4" style={{ textAlign: "center" }}>
            {product.name}
          </Typography>
          {product.presale_price === null ? (
            <Typography style={{ color: "#6E6E6E", marginTop: 10 }}>
              {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ₺
            </Typography>
          ) : (
            <Typography style={{ color: "#6E6E6E", marginTop: 10 }}>
              {product.presale_price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              ₺
            </Typography>
          )}

          <Button
            variant="contained"
            href={`/product/${product.slug}`}
            color="secondary"
            style={{
              height: 50,
              width: 120,
              backgroundColor: "#196DDD",
              marginTop: 20,
            }}
          >
            İncele
          </Button>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <img
            alt="Product Visual"
            src={product.image}
            style={{ maxWidth: 350, maxHeight: 400 }}
          />
        </Grid>
        <Divider
          light
          style={{
            width: "100%",
            marginTop: 50,
            marginBottom: 50,
            height: 0.1,
          }}
        />
      </Grid>
    </div>
  );
};

export default card;
