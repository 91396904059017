import axios from "axios";
import { setAlert } from "./alert";
import {
  ADD_DEVICE,
  GET_DEVICE_TOTAL,
  GET_DEVICE,
  REMOVE_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAIL,
  GET_DEVICE_TOTAL_SUCCESS,
  GET_DEVICE_TOTAL_FAIL,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAIL,
  REMOVE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAIL,
  SYNCH_DEVICE_LIST_SUCCESS,
  SYNCH_DEVICE_LIST_FAIL,
  GET_DEVICE_DETAIL_SUCCESS,
  GET_DEVICE_DETAIL_FAIL,
} from "./types";

export const add_device =
  (product_serial_number, product_password) => async (dispatch) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      const body = JSON.stringify({ product_serial_number, product_password });

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/device-list/add-device`,
          body,
          config
        );

        if (res.status === 201) {
          dispatch({
            type: ADD_DEVICE_SUCCESS,
            payload: res.data,
          });
          dispatch(setAlert("Cihaz eklendi", "success"));
        } else {
          dispatch({
            type: ADD_DEVICE_FAIL,
          });
          dispatch(setAlert("Cihaz zaten ekli", "warning"));
        }
      } catch (err) {
        dispatch({
          type: ADD_DEVICE_FAIL,
        });
        dispatch(setAlert("Cihaz eklenemedi, bir hata oluştu", "error"));
      }
    } else {
      let cart = [];

      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem("cart"));
      }

      let shouldAddItem = true;

      cart.map((item) => {
        if (
          product_serial_number.toString() ===
          item.product.serial_number.toString()
        ) {
          shouldAddItem = false;
        }
      });

      const order_item = {
        product: product_serial_number,
        count: 1,
      };

      if (shouldAddItem) {
        cart.push(order_item);
      }

      dispatch({
        type: ADD_DEVICE,
        payload: cart,
      });
      dispatch(setAlert("Cihaz eklendi", "success"));
    }
  };

export const get_device_detail = (productId) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/device-list/device/${productId}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_DEVICE_DETAIL_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_DEVICE_DETAIL_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DEVICE_DETAIL_FAIL,
    });
  }
};

export const get_device = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/device-list/device-list`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_DEVICES_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_DEVICES_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_DEVICES_FAIL,
      });
    }
  } else {
    dispatch({
      type: GET_DEVICE,
    });
  }
};

export const get_device_total = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/device-list/get-device-total`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: GET_DEVICE_TOTAL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_DEVICE_TOTAL_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_DEVICE_TOTAL_FAIL,
      });
    }
  } else {
    let total = 0;

    if (localStorage.getItem("cart")) {
      total = JSON.parse(localStorage.getItem("cart")).length;
    }

    dispatch({
      type: GET_DEVICE_TOTAL,
      payload: total,
    });
  }
};

export const remove_device = (item) => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const product_id = item.product.id;
    const body = JSON.stringify({ product_id });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      data: body,
    };

    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/device-list/remove-device`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: REMOVE_DEVICE_SUCCESS,
          payload: res.data,
        });
        dispatch(setAlert("Cihaz listenizden çıkarıldı", "success"));
      } else {
        dispatch({
          type: REMOVE_DEVICE_FAIL,
        });
        dispatch(
          setAlert("Cihaz listeden çıkarılamadı bir hata oluştu", "error")
        );
      }
    } catch (err) {
      dispatch({
        type: REMOVE_DEVICE_FAIL,
      });
      dispatch(
        setAlert("Cihaz listeden çıkarılamadı bir hata oluştu", "error")
      );
    }
  } else {
    let cart = [];
    let new_cart = [];

    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));

      cart.map((cart_item) => {
        if (cart_item.product.id.toString() !== item.product.id.toString()) {
          new_cart.push(cart_item);
        }
      });
    }

    dispatch({
      type: REMOVE_DEVICE,
      payload: new_cart,
    });
  }
};

export const synch_device_list = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  let cart_items = [];

  if (localStorage.getItem("cart")) {
    let cart = JSON.parse(localStorage.getItem("cart"));

    cart.map((cart_item) => {
      const item = {};
      item.product_id = cart_item.product.id;
      item.count = cart_item.count;
      cart_items.push(item);
    });
  }

  const body = JSON.stringify({ cart_items });

  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/device-list/synch`,
      body,
      config
    );

    if (res.status === 201) {
      dispatch({
        type: SYNCH_DEVICE_LIST_SUCCESS,
      });
    } else {
      dispatch({
        type: SYNCH_DEVICE_LIST_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: SYNCH_DEVICE_LIST_FAIL,
    });
  }
};
