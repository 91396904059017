import React, { useState } from "react";
import { Grid, Button, TextField } from "@mui/material";
import { connect } from "react-redux";
import { add_device } from "../actions/devices";

const AddDevicePopup = ({ add_device }) => {
  const [formData, setFormData] = useState({
    product_serial_number: "",
    product_password: "",
  });

  const { product_serial_number, product_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    add_device(product_serial_number, product_password);
  };
  let width = window.innerWidth;

  if (width > 800) {
    return (
      <div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ padding: 20 }}
        >
          <form onSubmit={(e) => onSubmit(e)}>
            <Grid style={{ minWidth: 380 }}>
              <TextField
                name="product_serial_number"
                label="Cihaz Seri Numarası"
                onChange={(e) => onChange(e)}
                required
                margin="normal"
                fullWidth
              />
              <TextField
                name="product_password"
                label="Cihaz Şifresi"
                onChange={(e) => onChange(e)}
                required
                margin="normal"
                fullWidth
              />
            </Grid>

            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              style={{ marginBottom: 30, marginTop: 35 }}
            >
              CİHAZ EKLE
            </Button>
          </form>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Grid container justifyContent="center" alignItems="center">
          <form onSubmit={(e) => onSubmit(e)}>
            <Grid>
              <TextField
                name="product_serial_number"
                label="Cihaz Seri Numarası"
                onChange={(e) => onChange(e)}
                required
                margin="normal"
                fullWidth
              />
              <TextField
                name="product_password"
                label="Cihaz Şifresi"
                onChange={(e) => onChange(e)}
                required
                margin="normal"
                fullWidth
              />
            </Grid>

            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              style={{ marginTop: 15 }}
            >
              CİHAZ EKLE
            </Button>
          </form>
        </Grid>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { add_device })(AddDevicePopup);
