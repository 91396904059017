import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
    get_ubienergy, 
    get_filtered_products 
} from '../actions/products';
import {
    add_item,
    get_items,
    get_total,
    get_item_total
} from '../actions/cart';
import { get_categories } from '../actions/categories';
import Card from '../components/Card';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Grid } from "@mui/material"
import { Helmet } from 'react-helmet';


const UbiEnergyListe = ({
    categories,
    get_categories,
    products,
    get_ubienergy,
    filtered_products,
    get_filtered_products,
    add_item,
    get_items,
    get_total,
    get_item_total,
}) => {
    const [redirect, setRedirect] = useState(false);
    const [filtered] = useState(false);


    useEffect(() => {
      get_categories();
      get_ubienergy();
      window.scrollTo(0, 0);
  }, []);


    const showProducts = () => {
        let results = [];
        let display = [];

        if (
            filtered_products &&
            filtered_products !== null &&
            filtered_products !== undefined &&
            filtered
        ) {
            filtered_products.map((product, index) => {
                return display.push(
                    <div key={index}>
                        <Card
                            product={product}
                            add_item={add_item}
                            get_items={get_items}
                            get_total={get_total}
                            get_item_total={get_item_total}
                            setRedirect={setRedirect}
                        />
                    </div>
                );
            });
        } else if (
            !filtered && 
            products &&
            products !== null && 
            products !== undefined
        ) {
            products.map((product, index) => {
                return display.push(
                    <div key={index}>
                        <Card
                            product={product}
                            add_item={add_item}
                            get_items={get_items}
                            get_total={get_total}
                            get_item_total={get_item_total}
                            setRedirect={setRedirect}
                        />
                    </div>
                );
            });
        }

        for (let i = 0; i < display.length; i += 3) {
            results.push(
                <div key={i}>
                    {display[i] ? display[i] : <div></div>}
                    {display[i+1] ? display[i+1] : <div></div>}
                    {display[i+2] ? display[i+2] : <div></div>}
                </div>
            );
        }

        return results;
    };

    if (redirect)
        return <Redirect to='/cart-or-continue-shopping' />;

    return (
        <div>
          <Helmet>
              <meta charSet='utf-8' />
              <meta name='description' content='Bitkileriniz için gerekli olan her şey tek pakette' />
              <title>UbiEnergy Liste - Ubicro</title>
              <link rel='canonical' href='https://www.ubicro.com/ubienergy' />
          </Helmet>
          <Header />
            <Grid container justifyContent='center' alignItems='center' style={{ marginTop: 50 }}>
              <Grid>
                {showProducts()}
              </Grid>
            </Grid>
            <Footer />
        </div>
    );
};

const mapStateToProps = state => ({
    categories: state.categories.categories,
    products: state.products.products,
    filtered_products: state.products.filtered_products
});

export default connect(mapStateToProps, {
    get_categories,
    get_ubienergy,
    get_filtered_products,
    add_item,
    get_items,
    get_total,
    get_item_total,
})(UbiEnergyListe);
