import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../../actions/auth';
import { Grid, Typography, Paper, Container, Button, IconButton, TextField, CircularProgress } from '@mui/material';
import Header from "../../components/Header"
import Alert from '../../components/Alert'
import { Helmet } from 'react-helmet'

// Icon Imports
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const ResetPasswordConfirm = ({ match, reset_password_confirm, loading }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });

    const { new_password, re_new_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        const uid = match.params.uid
        const token = match.params.token

        reset_password_confirm(uid, token, new_password, re_new_password);
        if (new_password === re_new_password)
        setRequestSent(true);
    };

    if (requestSent && !loading) {
        return <Redirect to='/Login' />
    }

    let width = window.innerWidth;
      if (width > 500) {
        return (
          <div>
            <Helmet>
              <meta charSet='utf-8' />
              <meta name='description' content='Şifre yenileme onay sayfası' />
              <title>Şifre Yenileme Onay - Ubicro</title>
              <link rel='canonical' href='https://www.ubicro.com/reset-password-confirm' />
            </Helmet>
            <Header />
            <Grid container style={{ justifyContent: "center", padding: 20 }}>
              <Paper maxWidth="xs" variant="outlined" style={{ marginTop: 40, marginBottom: 50, borderRadius: 10, padding: 50 }}>
                <Container maxWidth="xs">
                  <IconButton href='/login' color='default' >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                    <Typography 
                      variant="h4"
                      gutterBottom
                      style={{ marginTop: 20 }}
                    >
                      Şifre belirleyin
                    </Typography>
                    <Typography 
                      variant="body1"
                      style={{ color: "#6E6E73", marginBottom: 20 }}
                      >
                      Şifre yenilemek için yeni şifrenizi girin.
                    </Typography>
                    <Alert />
                    <form onSubmit={e => onSubmit(e)}>
                      <Grid>
                          <TextField
                              type='password'
                              placeholder='yeni şifre'
                              name='new_password'
                              value={new_password}
                              onChange={e => onChange(e)}
                              required
                              variant='outlined'
                              fullWidth
                              style={{ marginBottom: 20, marginTop: 20 }}
                          />
                      </Grid>
                      <Grid>
                          <TextField
                              type='password'
                              placeholder='şifreyi tekrarla'
                              name='re_new_password'
                              value={re_new_password}
                              onChange={e => onChange(e)}
                              required
                              variant='outlined'
                              fullWidth
                              style={{ marginBottom: 20 }}
                          />
                      </Grid>
                      {
                          loading ? (
                              <div>
                                  <CircularProgress
                                      size={68}
                                      style={{ marginLeft: 50, marginTop: 20 }}
                                  />
                              </div>
                          ) : (
                                <Button 
                                  type='submit'
                                  fullWidth
                                  variant='contained'
                                  style={{ height: 50 }}>
                                    Kaydet
                                </Button>
                          )
                      }
                  </form>
                </Container>
              </Paper>
            </Grid>
          </div>
      );
      } else {
        return (
          <div>
          <Helmet>
            <meta charSet='utf-8' />
            <meta name='description' content='Şifre yenileme onay sayfası' />
            <title>Şifre Yenileme Onay - Ubicro</title>
            <link rel='canonical' href='https://www.ubicro.com/reset-password-confirm' />
          </Helmet>
          <Header />
          <Grid container style={{ justifyContent: "center", padding: 10 }}>
            <Paper maxWidth="xs" variant="outlined" style={{ marginTop: 20, marginBottom: 50, borderRadius: 10, padding: 10 }}>
              <Container maxWidth="xs">
                <IconButton href='/login' color='default' style={{ marginBottom: 20, marginTop: 20 }}>
                  <ArrowBackIosNewIcon />
                </IconButton>
                  <Typography 
                    variant="h4"
                    gutterBottom
                  >
                    Şifre belirleyin
                  </Typography>
                  <Typography 
                    variant="body1"
                    style={{ color: "#6E6E73", marginBottom: 20 }}
                    >
                    Şifre yenilemek için yeni şifrenizi girin.
                  </Typography>
                  <Alert />
                  <form onSubmit={e => onSubmit(e)}>
                    <Grid>
                        <TextField
                            type='password'
                            placeholder='yeni şifre'
                            name='new_password'
                            value={new_password}
                            onChange={e => onChange(e)}
                            required
                            variant='outlined'
                            fullWidth
                            style={{ marginBottom: 20, marginTop: 20 }}
                        />
                    </Grid>
                    <Grid>
                        <TextField
                            type='password'
                            placeholder='şifreyi tekrarla'
                            name='re_new_password'
                            value={re_new_password}
                            onChange={e => onChange(e)}
                            required
                            variant='outlined'
                            fullWidth
                            style={{ marginBottom: 20 }}
                        />
                    </Grid>
                    {
                        loading ? (
                            <div>
                                <CircularProgress
                                    size={68}
                                    style={{ marginLeft: 50, marginTop: 20 }}
                                />
                            </div>
                        ) : (
                              <Button 
                                type='submit'
                                fullWidth
                                variant='contained'
                                style={{ height: 50 }}>
                                  Kaydet
                              </Button>
                        )
                    }
                </form>
              </Container>
            </Paper>
          </Grid>
        </div>
      );
    }
    
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);