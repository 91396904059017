import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { activate } from "../../actions/auth";
import {
  Button,
  Typography,
  Grid,
  CircularProgress,
  Paper,
} from "@mui/material";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";

// Icon imports
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

const Activate = ({ match, loading, activate }) => {
  const [activated, setActivated] = useState(false);

  const activate_account = () => {
    const uid = match.params.uid;
    const token = match.params.token;

    activate(uid, token);
    setActivated(true);
  };

  if (activated && !loading) return <Redirect to="/Login" />;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Ubicro hesap aktivasyon sayfası" />
        <title>Aktivasyon - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/activate" />
      </Helmet>
      <Header />
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        style={{ marginTop: 100 }}
      >
        <Paper variant="outlined" style={{ padding: 50, borderRadius: 20 }}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            direction="column"
          >
            <Typography gutterBottom variant="h4">
              Hesabınızı Aktif Edin
            </Typography>
            <Typography style={{ color: "grey" }}>
              Son olarak bu sayfadan hesabınızı aktif etmeniz gerekiyor <br />{" "}
              ve sonra hazırsınız.
            </Typography>
            {loading ? (
              <Grid>
                <CircularProgress
                  size={68}
                  style={{ marginLeft: 170, marginTop: 20 }}
                />
              </Grid>
            ) : (
              <Button
                style={{ marginTop: "50px" }}
                type="button"
                onClick={activate_account}
                variant="contained"
                size="large"
                startIcon={<MarkEmailReadIcon />}
              >
                Aktive et
              </Button>
            )}
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  activate,
})(Activate);
