import React, { useState } from "react";
import {
  Box,
  CardMedia,
  IconButton,
  Grid,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const ImageSlider = ({ product }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const images = [
    product.image,
    product.image_1,
    product.image_2,
    product.image_3,
    product.image_4,
    product.image_5,
    product.image_6,
    product.image_7,
    // product.image_8,
  ].filter((image) => image !== null);
  const totalImages = images.length;

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handlePrevClick = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? totalImages - 1 : currentImageIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex(
      currentImageIndex === totalImages - 1 ? 0 : currentImageIndex + 1
    );
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const numCols = Math.min(totalImages, 4);
  const gridItemSize = Math.floor(12 / numCols);

  return (
    <>
      <Box display="flex" alignItems="center">
        <IconButton aria-label="previous" onClick={handlePrevClick}>
          <ChevronLeft />
        </IconButton>
        <CardMedia
          component="img"
          image={images[currentImageIndex]}
          onClick={handleDialogOpen}
          style={{
            cursor: "pointer",
            width: "80%",
            maxHeight: 400,
            marginBottom: 30,
            borderRadius: 20,
            objectFit: "contain",
          }}
        />
        <IconButton aria-label="next" onClick={handleNextClick}>
          <ChevronRight />
        </IconButton>
      </Box>
      {totalImages > 1 && (
        <Grid container spacing={2} style={{ width: "90%" }}>
          {images.map((image, index) => (
            <Grid item key={index} xs={gridItemSize}>
              <CardMedia
                component="img"
                image={image}
                onClick={() => handleImageClick(index)}
                style={{
                  cursor: "pointer",
                  opacity: index === currentImageIndex ? 0.5 : 1,
                  borderRadius: 10,
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullScreen>
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#fff" }}
          elevation={0}
        >
          <Toolbar style={{ justifyContent: "flex-end" }}>
            <IconButton
              edge="start"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid
            container
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton aria-label="previous" onClick={handlePrevClick}>
              <ChevronLeft />
            </IconButton>
            <CardMedia
              component="img"
              image={images[currentImageIndex]}
              style={{ width: "70%", maxWidth: 1200 }}
            />
            <IconButton aria-label="next" onClick={handleNextClick}>
              <ChevronRight />
            </IconButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageSlider;
