import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import MesafeliSatisSozlesmesi from "../components/MesafeliSatisSozlesmesi";
import OnBilgilendirme from "../components/OnBilgilendirme";
import Iptalveiade from "../components/iptalveiade";

function PaymentPolicies() {
  // Mesafeli satış sözleşmesi pop-up
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // İptal ve iade bilgileri pop-up
  const [openIptal, setOpenIptal] = useState(false);

  const handleClickOpenIptal = () => {
    setOpenIptal(true);
  };

  const handleCloseIptal = () => {
    setOpenIptal(false);
  };

  // Ön bilgilendirme formu pop-up
  const [openOnBilgi, setOpenOnBilgi] = useState(false);

  const handleClickOpenOnBilgi = () => {
    setOpenOnBilgi(true);
  };

  const handleCloseOnBilgi = () => {
    setOpenOnBilgi(false);
  };

  let width = window.innerWidth;

  if (width > 800) {
    return (
      <Grid container direction="row" style={{ marginTop: 50 }}>
        <Grid item xs={1}>
          <Checkbox
            required
            defaultChecked
            id="sözleşme"
            color="primary"
            style={{ color: "#1683FC" }}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="row" alignItems="center">
            <Button
              variant="text"
              onClick={handleClickOpenOnBilgi}
              style={{ textTransform: "none" }}
            >
              Ön Bilgilendirme Formunu
            </Button>
            <Typography>,</Typography>
            <Button
              variant="text"
              onClick={handleClickOpen}
              style={{ textTransform: "none" }}
            >
              Mesafeli Satış Sözleşmesini
            </Button>
            <Typography>ve</Typography>
            <Button
              variant="text"
              onClick={handleClickOpenIptal}
              style={{ textTransform: "none" }}
            >
              İptal ve İade bilgilerini
            </Button>
            <Typography>okudum, onaylıyorum.</Typography>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">
            {"Mesafeli Satış Sözleşmesi"}
          </DialogTitle>
          <DialogContent>
            <MesafeliSatisSozlesmesi />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openOnBilgi} onClose={handleCloseOnBilgi}>
          <DialogTitle id="alert-dialog-title">
            {"Ön Bilgilendirme Formu"}
          </DialogTitle>
          <DialogContent>
            <OnBilgilendirme />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseOnBilgi} autoFocus>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openIptal} onClose={handleCloseIptal}>
          <DialogTitle id="alert-dialog-title">
            {"İptal ve İade Bilgisi"}
          </DialogTitle>
          <DialogContent>
            <Iptalveiade />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseIptal} autoFocus>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  } else {
    return (
      <Grid container direction="row" style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 5 }} />
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center">
            <Button
              variant="text"
              onClick={handleClickOpenOnBilgi}
              style={{ textTransform: "none", fontSize: 8 }}
            >
              Ön Bilgilendirme Formu
            </Button>
            <Button
              variant="text"
              onClick={handleClickOpen}
              style={{ textTransform: "none", fontSize: 8 }}
            >
              Mesafeli Satış Sözleşmesi
            </Button>
            <Button
              variant="text"
              onClick={handleClickOpenIptal}
              style={{ textTransform: "none", fontSize: 8 }}
            >
              İptal, İade bilgileri
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center">
            <Checkbox
              required
              defaultChecked
              id="sözleşme"
              color="primary"
              style={{ color: "#1683FC" }}
            />
            <Typography>Sözleşmeleri okudum, onaylıyorum.</Typography>
          </Grid>
          <Divider style={{ width: "100%", marginTop: 5 }} />
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">
            {"Mesafeli Satış Sözleşmesi"}
          </DialogTitle>
          <DialogContent>
            <MesafeliSatisSozlesmesi />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openOnBilgi} onClose={handleCloseOnBilgi}>
          <DialogTitle id="alert-dialog-title">
            {"Ön Bilgilendirme Formu"}
          </DialogTitle>
          <DialogContent>
            <OnBilgilendirme />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseOnBilgi} autoFocus>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openIptal} onClose={handleCloseIptal}>
          <DialogTitle id="alert-dialog-title">
            {"İptal ve İade Bilgisi"}
          </DialogTitle>
          <DialogContent>
            <Iptalveiade />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseIptal} autoFocus>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default PaymentPolicies;
