import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Cocopeat from "../assets/images/Cocopeat.jpg";
import RFID from "../assets/images/RFID.jpg";
import AutomationLED from "../assets/images/Automation_LED_Image.jpeg";
import BlackPCB from "../assets/images/Black PCB.jpg";
import MaterialImage from "../assets/images/Material Image.jpg";
import NutrientImage from "../assets/images/Besin Algoritmasi.jpg";
import pHAlgorithm from "../assets/images/pH Algorithm.jpg";
import UltraSonicAtomizers from "../assets/images/Ultrasonic Atomizers.jpg";

export default function ProductDetailImageList() {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let width = window.innerWidth;

  if (width > 800) {
    return (
      <div>
        <ImageList sx={{ width: 1200, marginBottom: 20 }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                style={{ borderRadius: 20, cursor: "pointer" }}
                onClick={() => handleOpen(item)}
              />
              <ImageListItemBar
                title={item.title}
                style={{
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.74)" }}
                    aria-label={`info about ${item.title}`}
                    onClick={() => handleOpen(item)}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{selectedItem?.title}</DialogTitle>
          <DialogContent
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <img
              src={`${selectedItem?.img}?w=248&fit=crop&auto=format`}
              alt={selectedItem?.title}
              loading="lazy"
              style={{ borderRadius: 10, width: 500 }}
            />
            <Grid style={{ marginTop: 20, marginBottom: 10, width: 500 }}>
              <DialogContentText style={{ marginBottom: 10 }}>
                {selectedItem?.detail}
              </DialogContentText>
              <DialogContentText>{selectedItem?.detail2}</DialogContentText>
              <DialogContentText>{selectedItem?.detail3}</DialogContentText>
              <DialogContentText>{selectedItem?.detail4}</DialogContentText>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <ImageList sx={{ width: 350, marginBottom: 20 }} cols={1}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                style={{ borderRadius: 20, cursor: "pointer" }}
                onClick={() => handleOpen(item)}
              />
              <ImageListItemBar
                title={item.title}
                style={{
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.74)" }}
                    aria-label={`info about ${item.title}`}
                    onClick={() => handleOpen(item)}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{selectedItem?.title}</DialogTitle>
          <DialogContent
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <img
              src={`${selectedItem?.img}?w=248&fit=crop&auto=format`}
              alt={selectedItem?.title}
              loading="lazy"
              style={{ borderRadius: 10, width: 280 }}
            />
            <DialogContentText>{selectedItem?.detail}</DialogContentText>
            <DialogContentText>{selectedItem?.detail2}</DialogContentText>
            <DialogContentText>{selectedItem?.detail3}</DialogContentText>
            <DialogContentText>{selectedItem?.detail4}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const itemData = [
  {
    img: BlackPCB,
    title: "Bitkileri sizin için büyüten ana kart",
    detail:
      "İçinde bulunan yapay zeka algoritmaları ve IoT teknolojisi sayesinde:",
    detail2:
      "- Siz dünyanın öbür ucundayken bile bitkilerinizin büyümesini takip edebilirsiniz",
    detail3:
      "- Yapay zeka algoritmaları ile bitkilerinize sadece sevgi göstermeniz yeterli olur",
  },
  {
    img: UltraSonicAtomizers,
    title: "Suyu sise çevirerek bitki büyütme",
    detail:
      "60V ve 2.4mhz frekans ile üstündeki suyu parçalayarak sis haline getirir",
    detail2: "- Her bitki ve büyüme aşaması için sis oranını ayarlar",
    detail3:
      "- Bitkilerin köklerinin daha fazla hava almasını sağlar ve sağlıklı tutar",
    detail4:
      "- Sis haline gelen su parçacıkları bitkilerin daha hızlı suyu absorbe etmelerini sağlar",
  },
  {
    img: RFID,
    title: "Radyo frekansı ile bitki tanıma",
    detail:
      "UbiTray'lerin her birinin üzerinde kendilerine özel bir RFID tag bulunur. bu sayede:",
    detail2: "- Cihaz hangi bitkinin yetiştiğini ve ne zaman konuluğunu anlar",
    detail3:
      "- Bitkinin gelişimini algılamak için sudaki besin oranı, pH seviyesi ve bitkilerin çektiği su miktarı gibi verileri karşılaştırır",
  },

  {
    img: MaterialImage,
    title: "Gıdaya uygun malzeme",
    detail:
      "Ubicro, bitkiler ile temas eden bütün yüzeylerinde Termoplastiklerin özel bir grubu olan 'Polikarbon' ile çalışır.",
    detail2: "- Gıda ile temasa uygun",
    detail3: "- Kurşun ve Kadmiyum içermez",
    detail4: "- Dönüştürülebilir, çevreci ve sağlıklı",
  },
  {
    img: NutrientImage,
    title: "Bitkiye göre besin ayarı",
    detail:
      "TDS ölçümü, EC değeri ve su seviyesi ile bitkilerin gerekli besin ihtiyacını anlar",
    detail2:
      "- Yetişen bitkilere göre sizin ellemenize gerek kalmadan besin ayarı yapar",
    detail3: "- Tek yapmanız gereken besin haznesinin dolu olmasıdır",
    detail4:
      "- Besin haznesi azaldığında size mobil uygulama üzerinden bildirim gönderir",
  },
  {
    img: pHAlgorithm,
    title: "Optimum pH değeri",
    detail:
      "Yetişen bitkilerin optimum pH değerlerine göre sizin ellemenize gerek kalmadan pH ayarı yapar",
    detail3: "- Tek yapmanız gereken pH haznesinin dolu olmasıdır",
    detail4:
      "- pH haznesi azaldığında size mobil uygulama üzerinden bildirim gönderir",
  },
  {
    img: AutomationLED,
    title: "Bitkiye ve büyüme aşamasına göre optimum ışık şiddeti",
    detail:
      "Gün döngüsünü vermek için ışıklar sabah yavaş yavaş açılır ve akşam yavaş yavaş kapanır",
    detail2:
      "- Spectrum ayarı bitkilerin maksimum fotosentez yapacakları şekilde ayarlanmıştır",
    detail3: "- Gözü yormayan 3500k ışık sıcaklığı",
    detail4:
      "- Bitkinin evresine göre ışık şiddeti değişir bu şekilde daha az enerji harcanır",
  },
  {
    img: Cocopeat,
    title: "Toprak yerine cocopeat",
    detail: "Tohumların durduğu bitki medyası olarak cocopeat kullanıyoruz.",
    detail2: "- 100% doğaldır",
    detail3: "- Hindistan cevizi kabuğu ve liflerinden elde edilir",
    detail4: "- İçindeki besin ile filizlenme aşamasını hızlanıdırır",
  },
];
