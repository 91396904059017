import React, { useState } from "react";
import {
  Typography,
  Grid,
  Card,
  Button,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import UbiHouseMini from "../assets/images/UbiHouseMini.jpg";
import UbiHouse from "../assets/images/UbiHouse.jpg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const DeviceListItem = ({
  item,
  product,
  add_device,
  get_device,
  remove_device,
  get_device_total,
  setAlert,
  render,
  setRender,
  showViewProduct = true,
  showRemoveProduct = true,
}) => {
  // Cihaz Sil pop-up  //
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeItemHandler = async () => {
    await remove_device(item);
    handleClose();
    setRender(!render);
  };

  const showRemoveProductButton = () => {
    if (showRemoveProduct) {
      return (
        <Grid item>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">{"Cihazı Kaldır"}</DialogTitle>
            <DialogContent>
              <Typography>
                Cihazı silmek istediğinizden emin misiniz? Cihaz sadece sizin
                kullanıcınızdan silinecektir.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={removeItemHandler} autoFocus>
                CİHAZI SİL
              </Button>
              <Button onClick={handleClose}>KAPAT</Button>
            </DialogActions>
          </Dialog>
          <Button color="error" onClick={handleClickOpen}>
            CİHAZI SİL
          </Button>
        </Grid>
      );
    }
  };

  const ProductName = () => {
    return (
      <Grid>
        <Typography variant="h6">
          {item &&
            item !== null &&
            item !== undefined &&
            item.product &&
            item.product !== null &&
            item.product !== undefined &&
            item.product.name}
        </Typography>
      </Grid>
    );
  };

  const ProductSerialNumber = () => {
    return (
      <Grid>
        <Typography>
          {item &&
            item !== null &&
            item !== undefined &&
            item.product &&
            item.product !== null &&
            item.product !== undefined &&
            item.product.serial_number}
        </Typography>
      </Grid>
    );
  };

  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Grid container justifyContent="center" style={{ marginBottom: 30 }}>
        <Card variant="outlined" style={{ borderRadius: 20 }}>
          <Grid container direction="column" style={{ width: 400, margin: 20 }}>
            <Grid
              item
              style={{
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={
                  item.product.device_type === "UbiHouse"
                    ? UbiHouse
                    : item.product.device_type === "UbiHouse Mini"
                    ? UbiHouseMini
                    : UbiHouseMini
                }
                alt="UbiHouse Media"
                style={{
                  alignSelf: "center",
                  justifyContent: "center",
                  maxWidth: 400,
                }}
              />
            </Grid>
            <Divider variant="fullWidth" style={{ marginTop: 30 }} />
            <Grid item style={{ marginTop: 20, marginBottom: 10 }}>
              {ProductName()}
            </Grid>
            <Grid container direction="row" style={{ marginBottom: 20 }}>
              <Typography style={{ marginRight: 10, color: "grey" }}>
                Seri Numarası:
              </Typography>
              {ProductSerialNumber()}
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  href={`/device/${
                    item &&
                    item !== null &&
                    item !== undefined &&
                    item.product &&
                    item.product !== null &&
                    item.product !== undefined
                      ? item.product.id
                      : 0
                  }`}
                  endIcon={<ChevronRightIcon />}
                >
                  CİHAZ BİLGİLERİ
                </Button>
                {showRemoveProductButton()}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  } else {
    return (
      <Grid container justifyContent="center" style={{ marginBottom: 30 }}>
        <Card variant="outlined" style={{ width: 350, borderRadius: 20 }}>
          <Grid container direction="column" style={{ padding: 20 }}>
            <Grid
              item
              style={{
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={
                  item.product.device_type === "UbiHouse"
                    ? UbiHouse
                    : item.product.device_type === "UbiHouse Mini"
                    ? UbiHouseMini
                    : UbiHouseMini
                }
                alt="UbiHouse Media"
                style={{
                  alignSelf: "center",
                  justifyContent: "center",
                  maxWidth: 250,
                }}
              />
            </Grid>
            <Divider variant="fullWidth" style={{ marginTop: 20 }} />
            <Grid item style={{ marginTop: 20, marginBottom: 10 }}>
              {ProductName()}
            </Grid>
            <Grid container direction="row" style={{ marginBottom: 20 }}>
              <Typography style={{ marginRight: 10, color: "grey" }}>
                Seri Numarası:
              </Typography>
              {ProductSerialNumber()}
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  href={`/device/${
                    item &&
                    item !== null &&
                    item !== undefined &&
                    item.product &&
                    item.product !== null &&
                    item.product !== undefined
                      ? item.product.id
                      : 0
                  }`}
                  endIcon={<ChevronRightIcon />}
                >
                  CİHAZ BİLGİLERİ
                </Button>
                {showRemoveProductButton()}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
};

export default DeviceListItem;
