import React from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Button,
  Typography,
  Grid,
} from "@mui/material";

// Icon Imports
import NightsStayIcon from "@mui/icons-material/NightsStay";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import AddIcon from "@mui/icons-material/Add";

const UbiTrayCard = ({
  product,
  add_item,
  get_items,
  get_total,
  get_item_total,
}) => {
  const addToCart = async () => {
    if (
      product &&
      product !== null &&
      product !== undefined &&
      product.quantity > 0
    ) {
      await add_item(product);
      await get_items();
      await get_total();
      await get_item_total();
    }
  };

  console.log(product.quantity);

  function AddToCartButton() {
    if (product.quantity > 0) {
      return (
        <Button
          aria-label="add"
          size="medium"
          variant="contained"
          onClick={addToCart}
          style={{
            marginLeft: 20,
            height: 40,
            width: 240,
            color: "#fff",
            borderRadius: 10,
            marginTop: 10,
            backgroundColor: "#1683FC",
            textTransform: "none",
          }}
        >
          <AddIcon />
          <Typography style={{ marginLeft: 5 }}>Sepete Ekle</Typography>
        </Button>
      );
    } else {
      return (
        <Button
          aria-label="add"
          size="medium"
          disabled
          variant="contained"
          onClick={addToCart}
          style={{
            marginLeft: 20,
            height: 40,
            width: 240,
            color: "#fff",
            borderRadius: 10,
            marginTop: 10,
            backgroundColor: "#666F79",
            textTransform: "none",
          }}
        >
          <Typography style={{ marginLeft: 5 }}>Yakında</Typography>
        </Button>
      );
    }
  }

  return (
    <Grid item>
      <Card className="ProductContainer" key={product.slug}>
        <CardActionArea
          href={`/product/${product.slug}`}
          style={{ paddingTop: 10 }}
        >
          <CardMedia
            className="ProductMedia"
            image={product.image}
            title={product.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {product.name}
            </Typography>
            <Typography color="textSecondary">
              <NightsStayIcon style={{ color: "#757575" }} />{" "}
              {product.hasat_zamani}
            </Typography>
            <Typography>
              <LocalFloristIcon style={{ color: "#06AC64" }} />{" "}
              {product.adet_tohum} adet tohum içerir
            </Typography>
            <Typography style={{ marginTop: 10, fontSize: 18 }}>
              {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ₺
            </Typography>
          </CardContent>
        </CardActionArea>
        {AddToCartButton()}
      </Card>
    </Grid>
  );
};

export default UbiTrayCard;
