import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reset } from '../actions/payment';
import { Helmet } from 'react-helmet';
import { Button, Grid, Typography } from '@mui/material';
import Header from '../components/Header'
import lottie from "lottie-web";
import Success from '../assets/animations/success.json'

const ThankYou = ({ reset }) => {
    useEffect(() => {
        reset();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#success-check"),
          animationData: Success,
          loop: false
        });
      }, []);

    return (
        <div>
            <Helmet>
                <meta charSet='utf-8' />
                <meta name='description' content='Siparişiniz alındı, web sitemizi kullandığınız için teşekkür ederiz' />
                <title>Teşekkürler - Ubicro</title>
                <link rel='canonical' href='https://www.ubicro.com/thankyou' />
            </Helmet>
            <Header />
            <Grid container>
                <Grid 
                    container 
                    direction='column' 
                    justifyContent='center' 
                    alignItems='center'
                    style={{ marginTop: 50, padding: 20 }}
                    >
                        <div id='success-check' style={{ width: 200, height: 200}} />
                        <Typography gutterBottom variant='h3'>Siparişiniz Başarıyla alındı</Typography>
                        <Typography style={{ color: 'grey' }}>
                            Sipariş takibinizi kullanıcı panelinizden görebilirsiniz
                        </Typography>
                        <Button 
                            href='/dashboard'
                            variant="contained"
                            style={{
                              margin: 30, 
                              marginBottom: 100,
                              height: 50, 
                              width: 300, 
                              backgroundColor:"#1683FC", 
                              color:"#fff", 
                              borderRadius: 10,
                              textTransform: 'none'
                            }}
                        >
                            Kullanıcı Paneline git
                        </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(null, { reset })(ThankYou);