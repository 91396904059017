import React from 'react';
import { connect } from 'react-redux';
import { reset_password } from '../../actions/auth';
import { Grid, Typography, Paper, Container, Button, IconButton } from '@mui/material';
import Header from "../../components/Header"
import { Helmet } from 'react-helmet'

// Icon Imports
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const ResetPasswordInfo = ({ reset_password }) => {
  let width = window.innerWidth;
      if (width > 500) {
        return (
          <div>
            <Helmet>
                <meta charSet='utf-8' />
                <meta name='description' content='Şifre yenileme bilgi sayfası' />
                <title>Şifre Yenileme Bilgi - Ubicro</title>
                <link rel='canonical' href='https://www.ubicro.com/reset-password-info' />
            </Helmet>
            <Header />
          
            <Grid container style={{ justifyContent: "center", padding: 20 }}>
              <Paper maxWidth="xs" variant="outlined" style={{ marginTop: 40, marginBottom: 50, borderRadius: 10, padding: 50 }}>
                <Container maxWidth="xs">
                  <IconButton href='/login' color='default' style={{ marginBottom: 30 }}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <div>
                    <Typography 
                      variant="h4"
                      gutterBottom
                    >
                      Son bir adım kaldı...
                    </Typography>
                    <Typography 
                      variant="body1"
                      style={{ color: "#6E6E73" }}
                      >
                      Şifre yenileme bağlantısını görmek için email'inizi kontrol ediniz.
                    </Typography>
                      <Button 
                        fullWidth 
                        variant='contained' 
                        href='/'
                        style={{ height: 50, marginTop: 30 }}
                      >
                        Anasayfaya dön
                      </Button>
                  </div>
                </Container>
              </Paper>
            </Grid>
        </div>
        )
      } else {
        return (
          <div>
            <Helmet>
                <meta charSet='utf-8' />
                <meta name='description' content='Şifre yenileme bilgi sayfası' />
                <title>Şifre Yenileme Bilgi - Ubicro</title>
                <link rel='canonical' href='https://www.ubicro.com/reset-password-info' />
            </Helmet>
            <Header />
          
            <Grid container style={{ justifyContent: "center", padding: 10 }}>
              <Paper maxWidth="xs" variant="outlined" style={{ marginTop: 40, marginBottom: 50, borderRadius: 10, padding: 10 }}>
                <Container maxWidth="xs">
                  <IconButton href='/login' color='default' style={{ marginBottom: 20, marginTop: 20 }}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <div>
                    <Typography 
                      variant="h4"
                      gutterBottom
                    >
                      Son bir adım kaldı...
                    </Typography>
                    <Typography 
                      variant="body1"
                      style={{ color: "#6E6E73" }}
                      >
                        Şifre yenileme bağlantısını görmek için email'inizi kontrol ediniz.
                    </Typography>
                      <Button 
                        fullWidth 
                        variant='contained' 
                        href='/'
                        style={{ height: 50, marginTop: 20, marginBottom: 20 }}
                      >
                        Anasayfaya dön
                      </Button>
                  </div>
                </Container>
              </Paper>
            </Grid>
          </div>
        );
      }
    
};

export default connect(null, { reset_password })(ResetPasswordInfo);