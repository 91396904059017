import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export default function ProductDetailAdvancedDetail({ product }) {
  const width = window.innerWidth;
  if (width > 800) {
    return (
      <Fragment>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 100 }}
        >
          <Typography variant="h2">Detaylı Bilgi</Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: 60,
            marginBottom: 150,
            maxWidth: 1200,
          }}
        >
          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />
          <Grid item xs={12} style={{ maxWidth: 900, padding: 40 }}>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              Tüm yıl boyunca sezon fark etmeden istediğiniz ürünü
              yetiştirirsiniz. Ubicro teknolojisi tüm üretim sürecini kendisi
              halleder. Hasat zamanı geldiğinde akıllı cihazınıza alacağınız
              uygulama bildirimiyle ürünlerinizi toplayıp tadını
              çıkarabilirsiniz. Üretim sürecini mobil uygulama veya web sitesi
              üzerinden hesabınıza giriş yaparak takip edebilirsiniz.
              UbiTray’ler üzerindeki genetiği değiştirilmemiş %100 yerli
              tohumlar ile gönül rahatlığıyla ürün yetiştirirsiniz. böcek ilacı
              yok veya kimyasal koruyucular olmadan. Sağlıklı meyve sebzelere
              elinizin altından, tap taze dalından kopararak ulaşırsınız ve tüm
              üretim sürecini izleyebilirsiniz.
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Beraberinde gelenler
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 14,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 22,
                  }}
                >
                  {product.name}
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 14,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 22,
                  }}
                >
                  UbiEnergy (besin ve pH dengeleyici)
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 14,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 22,
                  }}
                >
                  {product.birlikte_gelenler_ubitray}
                </Typography>
              </ListItem>
            </List>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">2 Yıl garanti süresi</Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Ağırlık
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.agırlık}
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">Boyutlar</Typography>
            <Typography
              gutterBottom
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              (yükseklik, genişlik, derinlik)
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.boyutlar}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Bitkiler için ayrılmış yükseklik
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              35 cm (her katta)
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Su deposu genişliği
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.su_deposu_genislik}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              {product.su_deposu_yenileme}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Elektrik tüketimi
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.elektrik_tuketimi}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              AC 220 - 240 V
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Yetiştirme alanı
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.yetistirme_alani}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 200, marginBottom: 30 }}
          >
            <Typography variant="h2">Donanım & Otomasyon</Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              LED ışık
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              Işıklar UbiHouse'lar için özel yapılmış ışıklardır. Bitkilerin en
              kaliteli ışığı aldığından emin olabilirsiniz.
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Güneşe benzer
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              Güneşi taklit eden LED ışıklar hem spektrum olarak Güneşe benzer
              hem de doğadaki güneş hareketlerini taklit eder.
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Wi-fi modülü
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              İstediğiniz zaman istediğiniz yerden üretiminize ulaşabilirsiniz.
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">Su kontrolü</Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              Su seviyesi azaldığında haber verir
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />
          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Bitki Besini
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              Besin sıvısı özel bölmeler içerisinde yer alıyor. Ubicro otomasyon
              sistemi bu sıvıları su deposundaki değerlere göre ekliyor.
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              pH derecesi
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              Otomasyon sistemi pH ayarlamasını otomatik olarak düzenler.
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />
        </Grid>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 100 }}
        >
          <Typography variant="h5">Detaylı Bilgi</Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: 60,
            marginBottom: 150,
            maxWidth: 1200,
          }}
        >
          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />
          <Grid item xs={12} style={{ maxWidth: 900, padding: 40 }}>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              Tüm yıl boyunca sezon fark etmeden istediğiniz ürünü
              yetiştirirsiniz. Ubicro teknolojisi tüm üretim sürecini kendisi
              halleder. <br />
              <br /> Hasat zamanı geldiğinde akıllı cihazınıza alacağınız
              uygulama bildirimiyle ürünlerinizi toplayıp tadını
              çıkarabilirsiniz. Üretim sürecini mobil uygulama veya web sitesi
              üzerinden hesabınıza giriş yaparak takip edebilirsiniz. <br />
              <br />
              UbiTray’ler üzerindeki genetiği değiştirilmemiş %100 yerli
              tohumlar ile gönül rahatlığıyla ürün yetiştirirsiniz. böcek ilacı
              yok veya kimyasal koruyucular olmadan. Sağlıklı meyve sebzelere
              elinizin altından, tap taze dalından kopararak ulaşırsınız ve tüm
              üretim sürecini izleyebilirsiniz.
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Beraberinde gelenler
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 18,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 18,
                  }}
                >
                  {product.name}
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 18,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 18,
                  }}
                >
                  UbiEnergy (besin ve pH dengeleyici)
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 18,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 18,
                  }}
                >
                  {product.birlikte_gelenler_ubitray}
                </Typography>
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">2 Yıl garanti süresi</Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Ağırlık
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.agırlık}
            </Typography>
          </Grid>

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">Boyutlar</Typography>
            <Typography
              gutterBottom
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              (yükseklik, genişlik, derinlik)
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.boyutlar}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Bitkiler için ayrılmış yükseklik
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              35 cm (her katta)
            </Typography>
          </Grid>

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Su deposu genişliği
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.su_deposu_genislik}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              {product.su_deposu_yenileme}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Elektrik tüketimi
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.elektrik_tuketimi}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              AC 220 - 240 V
            </Typography>
          </Grid>

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Yetiştirme alanı
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.yetistirme_alani}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 200, marginBottom: 30 }}
          >
            <Typography variant="h5">Donanım & Otomasyon</Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              LED ışık
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              Işıklar UbiHouse'lar için özel yapılmış ışıklardır. Bitkilerin en
              kaliteli ışığı aldığından emin olabilirsiniz.
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Güneşe benzer
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              Güneşi taklit eden LED ışıklar hem spektrum olarak Güneşe benzer
              hem de doğadaki güneş hareketlerini taklit eder.
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Wi-fi modülü
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              İstediğiniz zaman istediğiniz yerden üretiminize ulaşabilirsiniz.
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">Su kontrolü</Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              Su seviyesi azaldığında haber verir
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />
          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Bitki Besini
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              Besin sıvısı özel bölmeler içerisinde yer alıyor. Ubicro otomasyon
              sistemi bu sıvıları su deposundaki değerlere göre ekliyor.
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              pH derecesi
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              Otomasyon sistemi pH ayarlamasını otomatik olarak düzenler.
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />
        </Grid>
      </Fragment>
    );
  }
}
