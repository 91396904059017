import { Card, CardActionArea, Grid, Typography } from "@mui/material";
import React from "react";

// image imports
import ubihousepromini from "../assets/images/UbiHouseProMini.jpg";
import ubihousepro from "../assets/images/UbiHousePro.jpg";
import ubihouse from "../assets/images/UbiHouse.jpg";
import ubihousemini from "../assets/images/UbiHouseMini.jpg";
import UbiTrayPaket from "../assets/images/UbiTrayPaket.jpg";
import UbiEnergy from "../assets/images/UbiEnergy.jpg";

const UrunBant = () => {
  return (
    <Grid
      container
      style={{
        backgroundColor: "#FBFBFD",
      }}
    >
      <div
        style={{
          backgroundColor: "#FBFBFD",
          padding: 20,
          overflow: "auto",
          whiteSpace: "nowrap",
          width: "100%",
        }}
      >
        <Card
          elevation={0}
          direction="column"
          style={{
            backgroundColor: "#FBFBFD",
            marginLeft: 40,
          }}
          className="scrollMenuComponent"
        >
          <CardActionArea href="/product/ubihouse-mini">
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
            >
              <img
                src={ubihousemini}
                style={{ height: 60, borderRadius: 10 }}
                alt="ubihouse mini"
              />
              <Typography>UbiHouse Mini</Typography>
            </Grid>
          </CardActionArea>
        </Card>
        <Card
          elevation={0}
          direction="column"
          style={{ backgroundColor: "#FBFBFD", marginLeft: 25 }}
          className="scrollMenuComponent"
        >
          <CardActionArea href="/product/ubihouse">
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
            >
              <img
                src={ubihouse}
                style={{ height: 60, borderRadius: 10 }}
                alt="ubihouse"
              />
              <Typography>UbiHouse</Typography>
            </Grid>
          </CardActionArea>
        </Card>
        <Card
          elevation={0}
          direction="column"
          style={{ backgroundColor: "#FBFBFD", marginLeft: 25 }}
          className="scrollMenuComponent"
        >
          <CardActionArea href="/product/ubihouse-pro">
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
            >
              <img
                src={ubihousepromini}
                style={{ height: 60, borderRadius: 10 }}
                alt="ubihouse pro"
              />
              <Typography>UbiHouse Pro</Typography>
            </Grid>
          </CardActionArea>
        </Card>
        <Card
          elevation={0}
          direction="column"
          style={{
            backgroundColor: "#FBFBFD",
            marginLeft: 25,
          }}
          className="scrollMenuComponent"
        >
          <CardActionArea href="/product/ubihouse-pro-max">
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
            >
              <img
                src={ubihousepro}
                style={{ height: 60, borderRadius: 10 }}
                alt="ubihouse pro max"
              />
              <Typography>UbiHouse Pro Max</Typography>
            </Grid>
          </CardActionArea>
        </Card>
        <Card
          elevation={0}
          direction="column"
          style={{
            backgroundColor: "#FBFBFD",
            marginLeft: 25,
          }}
          className="scrollMenuComponent"
        >
          <CardActionArea href="/ubitray">
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
            >
              <img
                src={UbiTrayPaket}
                style={{ height: 60 }}
                alt="ubihouse pro mini"
              />
              <Typography>UbiTray</Typography>
            </Grid>
          </CardActionArea>
        </Card>
        <Card
          elevation={0}
          direction="column"
          style={{ backgroundColor: "#FBFBFD", marginLeft: 25 }}
          className="scrollMenuComponent"
        >
          <CardActionArea href="/product/ubienergy">
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
            >
              <img
                src={UbiEnergy}
                style={{ height: 60, borderRadius: 10 }}
                alt="ubihouse pro mini"
              />
              <Typography>UbiEnergy</Typography>
            </Grid>
          </CardActionArea>
        </Card>
      </div>
    </Grid>
  );
};

export default UrunBant;
