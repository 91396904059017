import { DialogContentText } from "@mui/material";
import React, { Fragment } from "react";

const OnBilgilendirme = () => {
  return (
    <Fragment>
      <DialogContentText>
        <p>Son güncellenme: 11/05/2023</p>
        <h2>ÖN BİLGİLENDİRME FORMU</h2>
        <h5>1. TARAFLAR VE KONU</h5>

        <p>
          İşbu Ön Bilgilendirme Formu’nun konusu, ALICI ile SATICI arasındaki
          mesafeli sözleşmeye ilişkin Tüketicilerin Korunması Hakkındaki Kanun
          ve Mesafeli Sözleşmeler Yönetmeliği hükümleri uyarınca
          bilgilendirilmesidir. Ayrıca Mesafeli Sözleşmeler Yönetmeliği uyarınca
          5/1 a, d, g ve h maddelerindeki hususlara ilişkin bilgiler işbu Ön
          bilgilendirme Formu’nda yer almaktadır. ALICI ön bilgilendirme formu
          ve mesafeli satış sözleşmesine ilişkin bilgileri üyeliğinin bağlı
          olduğu “Hesabım” sayfasından takip edebilecek ve siparişine ilişkin
          verdiği bilgileri “Hesabım” sayfasından değiştirebilecektir. Ön
          bilgilendirme formu ve mesafeli satış sözleşmesi ubicro.com tarafından
          kayıt altına alınmakta olup, ALICI dilediği zaman “Hesabım”
          sayfasından metinlere ulaşım sağlayabilecektir.
        </p>

        <p>
          <h5>2. TANIMLAR</h5>
          İşbu sözleşmenin uygulanmasında ve yorumlanmasında aşağıda yazılı
          terimler karşılarındaki yazılı açıklamaları ifade edeceklerdir.
          <br />
          <br />
          <strong>ALICI :</strong> Bir mal veya hizmeti ticari veya mesleki
          olmayan amaçlarla edinen, kullanan veya yararlanan gerçek kişiyi,
          <br />
          <strong>Hizmet :</strong> Bir ücret veya menfaat karşılığında yapılan
          ya da yapılması taahhüt edilen mal sağlama dışındaki her türlü
          tüketici işleminin konusu,
          <br />
          <strong>Kanun :</strong> Tüketicinin Korunması Hakkında Kanun’u,
          <br />
          <strong>Platform :</strong> Ubicro’ya ait www.ubicro.com adlı internet
          sitesi ve mobil uygulaması
          <br />
          <strong>SATICI :</strong> UBİCRO TEKNOLOJİ YAZILIM SANAYİ TİCARET
          ANONİM ŞİRKETİ,
          <br />
          <strong>Sipariş Veren :</strong> Bir mal veya hizmeti Ubicro'ya ait
          www.ubicro.com adlı internet sitesi veya mobil uygulaması üzerinden
          talep eden gerçek kişiyi,
          <br />
          <strong>Sözleşme :</strong> SATICI ve ALICI arasında akdedilen işbu
          Sözleşme’yi,
          <br />
          <strong>Taraflar :</strong> SATICI ve ALICI’yı,
          <br />
          <strong>Yönetmelik :</strong> Mesafeli Satışlar Yönetmeliği’ni,
          <br />
          <strong>Ürün veya Ürünler :</strong> Alışverişe konu olan taşınır
          eşya, mallar ile elektronik ortamda kullanılmak üzere hazırlanan
          yazılım, ses, görüntü ve benzeri her türlü ürünleri, ifade eder.
        </p>

        <p>
          <h5>3. SATICI BİLGİLERİ</h5>
          <ul>
            <li>
              Satıcı İsim/Unvanı : UBİCRO TEKNOLOJİ YAZILIM SANAYİ TİCARET
              ANONİM ŞİRKETİ
            </li>
            <li>
              Satıcının Açık Adresi : Abide-i Hürriyet caddesi 211A Blackout -
              34381
            </li>
            <li>Satıcının Telefonu : 0 530 333 20 50</li>
            <li>Satıcı Mersis No : 8831035500</li>
            <li>Satıcı E-Posta : info@ubicro.com</li>
            <li>
              Alıcının İade Halinde Malı Satıcıya Göndereceği Kargo Şirketi :
              İade kodu alındığında tarafınıza bilgilendirilen kargo firmasıyla
              iadenizi gönderebilirsiniz.
            </li>
          </ul>
        </p>

        <p>
          <h5>4. ÖDEME VE TESLİM ŞARTLARI</h5>
          Teslim Şartları ürün sayfasında belirtildiği şekilde uygulanacaktır.{" "}
          <br />
          Teslim Şartları: Alıcıya Teslim
        </p>

        <p>
          <h5>5. CAYMA HAKKI</h5>
          5.1.ALICI hizmet ifasına ilişkin sözleşmelerde sözleşmenin kurulduğu
          günden, mal teslimine ilişkin sözleşmelerde ise malın kendisine
          tesliminden itibaren, hiçbir gerekçe göstermeksizin 15 (on beş) gün
          içinde cayma hakkını kullanarak mal/hizmeti iade edebilir.
          <ol type="a">
            <li>
              Cayma hakkına ilişkin sürenin hesabında tek sipariş konusu olup
              ayrı ayrı teslim edilen mallarda, ALICI’nın veya ALICI tarafından
              belirlenen üçüncü kişinin son malı teslim aldığı gün,
            </li>

            <li>
              Birden fazla parçadan oluşan mallarda, ALICI’nın veya ALICI
              tarafından belirlenen üçüncü kişinin son parçayı teslim aldığı
              gün,
            </li>

            <li>
              Belirli bir süre boyunca malın düzenli tesliminin yapıldığı
              sözleşmelerde, ALICI’nın veya ALICI tarafından belirlenen üçüncü
              kişinin ilk malı teslim aldığı gün esas alınır.
            </li>
          </ol>
          <br /> 5.2.ALICI, cayma hakkı kullanım bildirimini Websitesi üzerinden
          SATICI’ya ulaştırır. SATICI, ALICI’nın cayma beyanının kendisine
          ulaşmasından itibaren 14 (on dört) gün içinde mal/hizmet bedelini iade
          etmekle yükümlüdür.
          <br />
          <br /> 5.3. Söz konusu mal/hizmet bedeli ALICI’nın satın alırken
          kullandığı ödeme aracına uygun bir şekilde ALICI’ya herhangi bir
          masraf veya yükümlülük getirmeden yapılır.
          <br />
          <br /> 5.4. ALICI, cayma hakkına ilişkin bildirimi SATICI veya
          Ubicro'ya yönelttiği andan itibaren, 10 (on) gün içinde malı SATICI’ya
          geri gönderir.
          <br />
          <br /> 5.5. ALICI cayma süresi içinde malı, işleyişine, teknik
          özelliklerine ve kullanım talimatlarına uygun bir şekilde kullandığı
          takdirde meydana gelen değişiklik ve bozulmalardan sorumlu değildir.
          <br />
          <br /> 5.6. ALICI iade edeceği mal/hizmeti ön bilgilendirme formunda
          belirtilen SATICI’nın anlaşmalı kargo şirketi ile SATICI’ya gönderdiği
          sürece, iade kargo bedeli SATICI’ya aittir. İade için ALICI’nın
          bulunduğu yerde SATICI’nın anlaşmalı kargo şirketi şubesi bulunmaması
          halinde ALICI herhangi bir kargo şirketiyle gönderebilecektir, bu
          halde kargo bedeli SATICI’ya aittir. ALICI’nın iade edeceği malı ön
          bilgilendirme formunda belirtilen SATICI’nın anlaşmalı kargo şirketi
          dışında bir kargo şirketi ile göndermesi halinde, iade kargo bedeli ve
          malın kargo sürecinde uğrayacağı hasardan SATICI sorumlu değildir.
          <br />
          <br /> 5.7. Mal ile beraber fatura da mevcut olması halinde, ilgili
          iade bölümü de doldurularak ve ALICI tarafından imzalanarak teslim
          edilmelidir.
          <br />
          <br /> 5.8. Cayma hakkının kullanılmasıyla beraber ALICI ve SATICI
          arasındaki yan sözleşmeler masraf, tazminat veya cezai şart ödeme
          yükümlülüğü olmaksızın sona erer.
        </p>

        <p>
          <h5>6. GENEL HÜKÜMLER</h5>
          6.1. ALICI Platform üzerinden vereceği siparişlerde birden fazla
          butikten aynı sepet içerisinde sipariş verebilecektir. SATICI,
          ALICI’nın birden fazla butikten vereceği siparişler için birden fazla
          fatura kesebilecektir. ALICI, Ubicro tarafından kendisine birden fazla
          fatura keserek kendisine gönderilmesini kabul eder. Şüpheye mahal
          bırakmamak bakımından belirtilmelidir ki, SATICI, ALICI’nın farklı
          butiklerden aldığı ürünlerin teslimatını mevzuattaki yasal süre
          içerisinde kalmak koşuluyla farklı zamanlarda gerçekleştirebilecektir.
          <br />
          <br />
          6.2. Sözleşme konusu ürün, yasal 30 (otuz) günlük süreyi aşmamak
          koşulu ile her bir ürün için ALICI’nın yerleşim yerinin uzaklığına
          bağlı olarak yasal süresi içinde ALICI veya gösterdiği adresteki
          kişi/kuruluşa, SATICI’nın anlaşmalı olduğu kargo firması tarafından
          teslim edilir. SATICI sattığı ürünleri anlaşmalı kargo firmaları
          aracılığı ile ALICILAR’a göndermekte ve teslim ettirmektedir. Kargo
          firmasının ALICI’nın bulunduğu yerde şubesi olmaması halinde ALICI’nın
          Ürün’ü kargo firmasının SATICI tarafından bildirilen yakın bir diğer
          şubesinden teslim alması gerekmektedir. İnternet sitesinde "tahmini
          teslimat tarihi" şeklinde belirtilen ürünlerin, teslimat tarihi
          tahmini olarak belirtilmiş olup bu ifade herhangi bir taahhüt
          içermemektedir. Bu ürünler mevzuatta belirtildiği üzere en geç 30 gün
          içerisinde ALICI’ya teslim edilecektir.
          <br />
          <br />
          6.3. Genel olarak aksi belirtilmediği sürece teslimat masrafları
          (kargo ücreti vb.) ALICI’ya aittir.
          <br />
          <br />
          6.4. Ürünler’in, teslimatı anında ALICI’nın bizatihi adresinde
          bulunmaması durumunda, SATICI edimini tam ve eksiksiz olarak yerine
          getirmiş olarak kabul edilecektir. Adreste teslim alacak kimsenin
          olmaması durumunda kargo firması ile temas kurarak ürünlerin
          sevkiyatını takip etmek ALICI’nın sorumluluğu olacaktır. Ürün,
          ALICI’dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek
          kişi/kuruluşun adresinde bulunmamasından veya teslimatı kabul
          etmemesinden SATICI sorumlu tutulamaz. Bu hallerde ALICI’nın Ürün'ü
          geç teslim almasından kaynaklanan her türlü zarar ile Ürün'ün kargo
          şirketinde beklemiş olması ve/veya kargonun SATICI'ya iade
          edilmesinden dolayı oluşan giderler ALICI’ya aittir.
          <br />
          <br />
          6.5. ALICI, Ürün'ü teslim aldığı anda kontrol etmekle ve üründe
          kargodan kaynaklanan bir sorun gördüğünde, ürünü kabul etmemekle ve
          kargo firması yetkilisine tutanak tutturmakla sorumludur. Aksi halde
          SATICI sorumluluk kabul etmeyecektir.
          <br />
          <br />
          6.6. ALICI, SATICI tarafından aksi yazılı öngörülmemiş ise, Ürün'ü
          teslim almadan önce bedelini tamamen ödemiş olması gerekir. Peşin
          satışlarda teslimattan önce Ürün bedeli SATICI’ya tamamen ödenmediği,
          taksitli satışlarda vadesi gelen taksit tutarı tediye edilmediği
          takdirde, SATICI tek taraflı olarak sözleşmeyi iptal edebilir ve
          Ürün'ü teslim etmeyebilir. Ürün teslimatı sonrasında herhangi bir
          sebepten dolayı, işlem yapılan kredi kartının ait olduğu banka/finans
          kuruluşunun Ürün bedelini SATICI'ya ödememesi halinde, Ürün en geç 3
          gün içinde ALICI tarafından tüm giderleri ALICI’ya ait olmak üzere
          SATICI'ya iade edilir. SATICI'nın iadeyi kabul etmeksizin Ürün bedeli
          alacağını takip dahil diğer tüm akdi-kanuni hakları ayrıca ve her
          halde saklıdır. Herhangi bir sebeple banka ve/veya finans kuruluşu
          tarafından başarısız kodu gönderilen ancak banka ve/veya finans
          kuruluşu tarafından SATICI’ya yapılan ödemelere ilişkin SATICI’nın
          herhangi bir sorumluluğu bulunmayacaktır.
          <br />
          <br />
          6.7. Ürün'ün normal satış/teslimat koşulları dışında olağanüstü
          durumlar (hava muhalefeti, yoğun trafik, deprem, sel, yangın gibi)
          nedeni ile yasal 30 günlük süre zarfında teslim edilememesi halinde
          SATICI teslimat ile ilgili olarak ALICI’yı bilgilendirir. Bu durumda
          ALICI siparişi iptal edebilir, benzer bir ürün sipariş edebilir veya
          olağanüstü durum sonuna kadar bekleyebilir.
          <br />
          <br />
          6.8. Sipariş iptallerinde ürün bedeli tahsil edilmiş ise ALICI’ya iade
          edilir. Kredi kartlı ödemelerde iade işlemi de ALICI kredi kartına
          iade sureti ile yapılır ve Ürün tutarı, siparişin ALICI tarafından
          iptal edilmesinden sonra ilgili bankaya iade edilir; bu tutarın
          Bankaya iadesinden sonra ALICI hesaplarına yansıması tamamen Banka
          işlem süreci ile ilgili olduğundan, ALICI olası gecikmeler için
          SATICI’nın herhangi bir şekilde müdahalede bulunmasının ve sorumluluk
          üstlenmesinin mümkün olamayacağını şimdiden kabul eder. (Bankaların
          iadeyi ALICI hesabına yansıtma işlemleri genellikle 3 (üç) haftayı
          bulabilmektedir).
          <br />
          <br />
          6.9. SATICI olağanüstü durumlar dışında haklı bir nedenle sözleşme
          konusu ürünlerin tedarik edilemeyeceğinin anlaşılması halinde durumu
          öğrenmesinden itibaren 3 (üç) gün içinde ALICI’yı bilgilendirerek
          onayını almak sureti ile eşit kalite ve fiyatta başka bir mal/hizmeti
          tedarik edebilir ve Sözleşme konusu taahhüdünü bu suretle yerine
          getirmiş sayılır. ALICI’nın onay vermediği hallerde sipariş iptaline
          ilişkin hükümler uygulanır.
        </p>

        <p>
          <h5>
            7. KİŞİSEL VERİLERİN KORUNMASI, TİCARİ ELEKTRONİK İLETİ VE
            FİKRİ-SINAİ HAKLAR İLE İLGİLİ KURALLAR ŞARTLAR
          </h5>
          7.1. 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında kişisel
          veri olarak tanımlanabilecek ALICI’ya ait ad, soyad, e-posta adresi,
          T.C. Kimlik numarası, demografik veriler, mali veriler vb. bilgiler;
          <br />• sipariş almak, ürün ve hizmetleri sunmak, ürün ve hizmetleri
          geliştirmek, sistemsel sorunların çözümü, ödeme işlemlerini
          gerçekleştirmek, -önceden onay verilmesi halinde- siparişler, ürünler
          ve hizmetler hakkında pazarlama faaliyetlerinde kullanılmak, ALICI’ya
          ait bilgilerin güncellenmesinde ve üyeliklerin yönetimi ve
          sürdürülmesi ile ALICI ile SATICI arasında kurulan mesafeli satış
          sözleşmesi ve sair sözleşmelerin ifası amacıyla ve 3. kişilerin
          teknik, lojistik ve benzeri diğer işlevlerinin SATICI tarafından
          sağlanabilmesi için yukarıda sayılan verileri Ubicro, Ubicro
          iştirakleri ve üçüncü kişi ve/veya kuruluşlar tarafından
          süresiz/süreli kaydedilebilir, yazılı/manyetik arşivlerde muhafaza
          edilebilir, kullanılabilir, güncellenebilir, paylaşılabilir, transfer
          olunabilir ve sair suretler ile işlenebilir.
          <br />
          <br />
          7.2. ALICILAR’a, yürürlükteki mevzuata uygun şekilde, her türlü ürün
          ve hizmetlere ilişkin tanıtım, reklam, iletişim, promosyon, satış ve
          pazarlama amacıyla, kredi kartı ve üyelik bilgilendirme, işlem,
          uygulamaları için Ubicro tarafından SMS/kısa mesaj, anlık bildirim,
          otomatik arama, bilgisayar, telefon, e-posta/mail, faks, diğer
          elektronik iletişim araçları ile ticari elektronik iletişimler
          yapılabilir, ALICI kendisine ticari elektronik iletiler gönderilmesini
          kabul etmiştir.
          <br />
          <br />
          7.3. ALICI tarafından Platform’a girilen bilgilerin ve işlemlerin
          güvenliği için gerekli önlemler, Ubicro tarafındaki kendi sistem
          altyapısında, bilgi ve işlemin mahiyetine göre günümüz teknik
          imkanları ölçüsünde alınmıştır. Bununla beraber, söz konusu bilgiler
          ALICI’ya ait cihazlardan girildiğinden ALICI tarafından korunmaları ve
          ilgisiz kişilerce erişilememesi için, virüs ve benzeri zararlı
          uygulamalara ilişkin olanlar dahil, gerekli tedbirlerin alınması
          sorumluluğu ALICI’ya aittir.
          <br />
          <br />
          7.4. ALICI, Ubicro'ya belirtilen iletişim kanallarından ulaşarak veri
          kullanımı-işlenmelerini ve/veya iletişimleri her zaman için
          durdurulmasını talep edebilir. ALICI’nın bu husustaki açık bildirimine
          göre, kişisel veri işlemleri ve/veya tarafına iletişimler yasal azami
          süre içinde durdurulur; ayrıca dilerse, hukuken muhafazası gerekenler
          ve/veya mümkün olanlar haricindeki bilgileri, veri kayıt sisteminden
          silinir ya da kimliği belli olmayacak biçimde anonim hale getirilir.
          ALICI isterse kişisel verilerinin işlenmesi ile ilgili işlemler,
          aktarıldığı kişiler, eksik veya yanlış olması halinde düzeltilmesi,
          düzeltilen bilgilerin ilgili üçüncü kişilere bildirilmesi, verilerin
          silinmesi veya yok edilmesi, otomatik sistemler ile analiz edilmesi
          sureti ile kendisi aleyhine bir sonucun ortaya çıkmasına itiraz,
          verilerin kanuna aykırı olarak işlenmesi sebebi ile zarara uğrama
          halinde giderilmesi gibi konularda Ubicro'ya her zaman başvurabilir ve
          bilgi alabilir. Söz konusu başvurular incelenerek yasal süresi
          içerisinde ALICI’ya mevzuatta öngörülen sürelerde dönüş yapılacaktır.
          <br />
          <br />
          7.5. Platform’a ait her türlü bilgi ve içerik ile bunların
          düzenlenmesi, revizyonu ve kısmen/tamamen kullanımı konusunda;
          SATICI'nın anlaşmasına göre diğer üçüncü şahıslara ait olanlar hariç;
          tüm fikri-sınai haklar ve mülkiyet hakları Ubicro'ya aittir.
          <br />
          <br />
          7.6. Platform’dan ulaşılan diğer sitelerde kendilerine ait
          gizlilik-güvenlik politikaları ve kullanım şartları geçerlidir,
          oluşabilecek ihtilaflar ile menfi neticelerinden Ubicro sorumlu
          değildir.
        </p>
      </DialogContentText>
    </Fragment>
  );
};

export default OnBilgilendirme;
