import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { signup } from "../../actions/auth";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Paper,
  Card,
  CardActionArea,
  Avatar,
  CircularProgress,
} from "@mui/material";
import Header from "../../components/Header";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Alert from "../../components/Alert";
import { Helmet } from "react-helmet";

const Signup = ({ signup, isAuthenticated, loading }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const { first_name, last_name, email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    signup(first_name, last_name, email, password);
  };

  const continue_with_google = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/google`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {}
  };

  const continue_with_facebook = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_API_URL}/facebook`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {}
  };

  if (isAuthenticated) return <Redirect to="/" />;

  let width = window.innerWidth;
  if (width > 500) {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Üye ol sayfası" />
          <title>Üye ol - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/signup" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Paper
            variant="outlined"
            style={{
              marginTop: 20,
              padding: 50,
              borderRadius: 20,
              marginBottom: 30,
            }}
          >
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignContent="center"
            >
              <Grid
                item
                container
                justifyContent="center"
                alignContent="center"
              >
                <Avatar>
                  <LockOpenIcon />
                </Avatar>
              </Grid>
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
                <Typography
                  variant="h5"
                  style={{ marginTop: 20, marginBottom: 15 }}
                >
                  Ubicro Hesabı Oluşturun
                </Typography>
                <Alert />
              </Grid>
            </Grid>
            <form onSubmit={(e) => onSubmit(e)}>
              <Grid style={{ minWidth: 380 }}>
                <TextField
                  type="text"
                  name="first_name"
                  value={first_name}
                  onChange={(e) => onChange(e)}
                  required
                  autoComplete="first_name"
                  margin="normal"
                  label="Ad"
                  fullWidth
                />
              </Grid>
              <Grid>
                <TextField
                  type="text"
                  name="last_name"
                  value={last_name}
                  onChange={(e) => onChange(e)}
                  required
                  autoComplete="first_name"
                  margin="normal"
                  label="Soyad"
                  fullWidth
                />
              </Grid>
              <Grid>
                <TextField
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                  autoComplete="first_name"
                  margin="normal"
                  label="Email"
                  fullWidth
                />
              </Grid>
              <Grid>
                <TextField
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                  minLength="6"
                  required
                  autoComplete="first_name"
                  margin="normal"
                  label="Şifre"
                  fullWidth
                  helperText={
                    <>
                      Parolanız diğer kişisel bilgileriniz ile çok benzer
                      olamaz.
                      <br />
                      En az 8 karakterden oluşmalıdır.
                      <br />
                      Tamamıyla sayısal olamaz.
                    </>
                  }
                />
              </Grid>
              {loading ? (
                <Grid>
                  <CircularProgress
                    size={68}
                    style={{ marginLeft: 150, marginTop: 20 }}
                  />
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  size="large"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  Hesap Oluştur
                </Button>
              )}
            </form>
            <Typography>
              Hesabınız var mı?{" "}
              <Button href="/login" style={{ textTransform: "none" }}>
                Giriş Yapın
              </Button>
            </Typography>
          </Paper>
          {/* 
                        <Grid item style={{ marginBottom: 60 }}>
                            <Grid container>
                                <Card variant='outlined' style={{ marginRight: 10, borderRadius: 10 }}>
                                    <CardActionArea onClick={continue_with_google} style={{ padding: 20 }} >
                                        <Grid container style={{ direction:'row' }}>
                                            <GoogleIcon sx={{ color: '#D95140' }} style={{ marginRight: 10 }} />
                                            <Typography>
                                                Google ile giriş yap
                                            </Typography>
                                        </Grid>
                                    </CardActionArea>
                                </Card>
                                <Card variant='outlined' style={{ borderRadius: 10 }}>
                                    <CardActionArea onClick={continue_with_facebook} style={{ padding: 20 }}>
                                        <Grid container style={{ direction:'row' }}>
                                            <FacebookIcon sx={{ color: '#536DA4' }} style={{ marginRight: 10 }} />
                                            <Typography>
                                                Facebook ile giriş yap
                                            </Typography>
                                        </Grid>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid> */}
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Üye ol sayfası" />
          <title>Üye ol - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/signup" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignContent="center"
            style={{ marginTop: 20 }}
          >
            <Grid item container justifyContent="center" alignContent="center">
              <Avatar>
                <LockOpenIcon />
              </Avatar>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              <Typography variant="h5" style={{ marginTop: 20 }}>
                Ubicro Hesabı Oluşturun
              </Typography>
              <Alert />
            </Grid>
          </Grid>
          <Grid container style={{ padding: 20, marginBottom: 10 }}>
            <form onSubmit={(e) => onSubmit(e)}>
              <Grid>
                <TextField
                  type="text"
                  name="first_name"
                  value={first_name}
                  onChange={(e) => onChange(e)}
                  required
                  autoComplete="first_name"
                  margin="normal"
                  label="Ad"
                  fullWidth
                />
              </Grid>
              <Grid>
                <TextField
                  type="text"
                  name="last_name"
                  value={last_name}
                  onChange={(e) => onChange(e)}
                  required
                  autoComplete="first_name"
                  margin="normal"
                  label="Soyad"
                  fullWidth
                />
              </Grid>
              <Grid>
                <TextField
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                  autoComplete="first_name"
                  margin="normal"
                  label="Email"
                  fullWidth
                />
              </Grid>
              <Grid>
                <TextField
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                  minLength="6"
                  required
                  autoComplete="first_name"
                  margin="normal"
                  label="Şifre"
                  fullWidth
                  helperText={
                    <>
                      Parolanız diğer kişisel bilgileriniz ile çok benzer
                      olamaz.
                      <br />
                      En az 8 karakterden oluşmalıdır.
                      <br />
                      Tamamıyla sayısal olamaz.
                    </>
                  }
                />
              </Grid>
              {loading ? (
                <Grid>
                  <CircularProgress
                    size={68}
                    style={{ marginLeft: 150, marginTop: 20 }}
                  />
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  size="large"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  Hesap Oluştur
                </Button>
              )}
            </form>
            <Typography>
              Hesabınız var mı?{" "}
              <Button href="/login" style={{ textTransform: "none" }}>
                Giriş Yapın
              </Button>
            </Typography>
          </Grid>
          {/* 
          <Paper
            variant="outlined"
            style={{
              padding: 15,
              margin: 10,
              borderRadius: 10,
              marginBottom: 50,
              backgroundColor: "#F5F5F7",
            }}
          >
            <Grid item>
              <Grid container justifyContent="center">
                <Card
                  variant="outlined"
                  style={{ borderRadius: 10, width: 250, marginBottom: 10 }}
                >
                  <CardActionArea
                    onClick={continue_with_google}
                    style={{ padding: 15 }}
                  >
                    <Grid container style={{ direction: "row" }}>
                      <GoogleIcon
                        sx={{ color: "#D95140" }}
                        style={{ marginRight: 10 }}
                      />
                      <Typography>Google ile giriş yap</Typography>
                    </Grid>
                  </CardActionArea>
                </Card>
                <Card
                  variant="outlined"
                  style={{ borderRadius: 10, width: 250 }}
                >
                  <CardActionArea
                    onClick={continue_with_facebook}
                    style={{ padding: 15 }}
                  >
                    <Grid container style={{ direction: "row" }}>
                      <FacebookIcon
                        sx={{ color: "#536DA4" }}
                        style={{ marginRight: 10 }}
                      />
                      <Typography>Facebook ile giriş yap</Typography>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Paper> */}
        </Grid>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { signup })(Signup);
