import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { get_items, get_total, get_item_total } from "../../actions/cart";
import {
  get_device,
  add_device,
  get_device_total,
  remove_device,
} from "../../actions/devices";
import { list_orders } from "../../actions/orders";
import { get_user_profile, update_user_profile } from "../../actions/profile";
import { logout } from "../../actions/auth";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as locales from "dayjs/locale/tr";
import UserProfileForm from "../../components/UserProfileForm";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DeviceListItem from "../../components/DeviceListItem";
import AddDevicePopup from "../../components/AddDevicePopup";
import { Helmet } from "react-helmet";

// Icon Imports
import QrCodeIcon from "@mui/icons-material/QrCode";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";

dayjs.extend(localizedFormat);
dayjs.locale("tr");

const Dashboard = ({
  get_items,
  get_total,
  get_item_total,
  total_items,
  items,
  list_orders,
  orders,
  user,
  profile,
  logout,
  get_user_profile,
  update_user_profile,
  add_device,
  get_device,
  remove_device,
  get_device_total,
}) => {
  const [display, setDisplay] = useState("user_info");
  const [formData, setFormData] = useState({
    address_line_1: "",
    city: "",
    district: "",
    phone: "",
  });

  const { address_line_1, city, district, phone } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Cihaz Ekle pop-up  //
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    update_user_profile(address_line_1, city, district, phone);
  };

  useEffect(() => {
    get_user_profile();
  }, []);

  useEffect(() => {
    if (user) {
      get_items();
      get_total();
      get_item_total();
      list_orders();
    }
  }, [user]);

  const [render, setRender] = useState(false);

  useEffect(() => {
    get_device();
    get_device_total();
  }, [render]);

  useEffect(() => {
    if (profile && profile !== null && profile !== undefined) {
      setFormData({
        address_line_1: profile.address_line_1,
        city: profile.city,
        district: profile.district,
        phone: profile.phone,
      });
    }
  }, [profile]);

  const showStatus = (status) => {
    if (status === "not_processed") {
      return <Typography style={{ color: "orange" }}>Onay bekliyor</Typography>;
    } else if (status === "processed") {
      return <Typography style={{ color: "#4DA2E6" }}>Onaylandı</Typography>;
    } else if (status === "shipped") {
      return <Typography style={{ color: "#F2B34B" }}>Kargoda</Typography>;
    } else if (status === "delivered") {
      return <Typography style={{ color: "green" }}>Gönderildi</Typography>;
    } else if (status === "cancelled") {
      return <Typography style={{ color: "red" }}>İptal edildi</Typography>;
    }
  };

  const showItems = () => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Typography
          style={{
            color: "#AFAFAF",
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          Kayıtlı cihaz sayısı: {total_items}
        </Typography>
        <Divider light flexItem style={{ height: 0.1, marginBottom: 50 }} />
        {items &&
          items !== null &&
          items !== undefined &&
          items.length !== 0 &&
          items.map((item, index) => {
            let count = item.count;

            return (
              <div key={index}>
                <DeviceListItem
                  item={item}
                  count={count}
                  remove_device={remove_device}
                  setAlert={setAlert}
                  render={render}
                  setRender={setRender}
                />
              </div>
            );
          })}
      </Grid>
    );
  };

  const userInfo = () => {
    return (
      <Grid>
        <Typography variant="h6">Kullanıcı Bilgileri</Typography>
        <Paper variant="outlined" style={{ borderRadius: 20, marginTop: 30 }}>
          <List>
            <ListItem>
              <Typography style={{ marginRight: 10, fontWeight: "bold" }}>
                Ad:
              </Typography>
              <Typography style={{ textTransform: "capitalize" }}>
                {user && user !== null && user !== undefined ? (
                  user.first_name
                ) : (
                  <Fragment></Fragment>
                )}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography style={{ marginRight: 10, fontWeight: "bold" }}>
                Soyad:
              </Typography>
              <Typography style={{ textTransform: "capitalize" }}>
                {user && user !== null && user !== undefined ? (
                  user.last_name
                ) : (
                  <Fragment></Fragment>
                )}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography style={{ marginRight: 10, fontWeight: "bold" }}>
                Email:
              </Typography>
              <Typography>
                {user && user !== null && user !== undefined ? (
                  user.email
                ) : (
                  <Fragment></Fragment>
                )}
              </Typography>
            </ListItem>
            <Divider light style={{ marginTop: 10, marginBottom: 10 }} />
            <ListItem>
              <Grid container direction="row" alignItems="center">
                <Typography>Şifre değiştirmek için:</Typography>
                <Button href="/reset-password">Tıklayın</Button>
              </Grid>
            </ListItem>
          </List>
        </Paper>
      </Grid>
    );
  };

  const purchaseHistory = () => {
    let width = window.innerWidth;

    if (width > 800) {
      return (
        <div>
          <Typography variant="h6">Siparişlerim</Typography>
          <Grid container>
            {orders &&
              orders !== null &&
              orders !== undefined &&
              orders.map((order, index) => (
                <div key={index}>
                  <Card
                    variant="outlined"
                    style={{
                      marginTop: 30,
                      padding: 30,
                      borderRadius: 20,
                      width: 330,
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ color: "#DADCE0" }}>
                        Sipariş No: {index + 1}
                      </Typography>
                      <Paper
                        style={{
                          backgroundColor: "#3ABC0E",
                          color: "#fff",
                          padding: 10,
                        }}
                      >
                        <Typography>
                          {order.amount.toLocaleString()} TL
                        </Typography>
                      </Paper>
                    </Grid>
                    <List>
                      <ListItem>
                        <ListItemText>
                          Sipariş Durumu: {showStatus(order.status)}
                        </ListItemText>
                      </ListItem>
                      <List
                        subheader={
                          <ListSubheader>Sipariş Detayı:</ListSubheader>
                        }
                      >
                        <ListItem>
                          <ListItemIcon>
                            <QrCodeIcon />
                          </ListItemIcon>
                          <ListItemText>
                            Sipariş Kodu: {order.transaction_id}
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocalShippingIcon />
                          </ListItemIcon>
                          <ListItemText>
                            Kargo Tutarı:{" "}
                            {order.shipping_price.toLocaleString()} TL
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AlarmOnIcon />
                          </ListItemIcon>
                          <ListItemText>
                            Sipariş Verildi:{" "}
                            {dayjs(order.date_issued).format("LLL")}
                          </ListItemText>
                        </ListItem>
                      </List>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          href={`/dashboard/order-detail/${order.transaction_id}`}
                          style={{ color: "#196DDD" }}
                        >
                          <ListItemText primary="Sipariş Detayı" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Card>
                </div>
              ))}
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <Typography variant="h6">Siparişlerim</Typography>
          <Grid container>
            {orders &&
              orders !== null &&
              orders !== undefined &&
              orders.map((order, index) => (
                <div key={index}>
                  <Card
                    variant="outlined"
                    style={{
                      marginTop: 30,
                      padding: 30,
                      borderRadius: 20,
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ color: "#DADCE0" }}>
                        Sipariş No: {index + 1}
                      </Typography>
                      <Paper
                        style={{
                          backgroundColor: "#3ABC0E",
                          color: "#fff",
                          padding: 10,
                        }}
                      >
                        <Typography>
                          {order.amount.toLocaleString()} TL
                        </Typography>
                      </Paper>
                    </Grid>
                    <List>
                      <ListItem>
                        <ListItemText>
                          Sipariş Durumu: {showStatus(order.status)}
                        </ListItemText>
                      </ListItem>
                      <List
                        subheader={
                          <ListSubheader>Sipariş Detayı:</ListSubheader>
                        }
                      >
                        <ListItem>
                          <ListItemIcon>
                            <QrCodeIcon />
                          </ListItemIcon>
                          <ListItemText>
                            Sipariş Kodu: {order.transaction_id}
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocalShippingIcon />
                          </ListItemIcon>
                          <ListItemText>
                            Kargo Tutarı:{" "}
                            {order.shipping_price.toLocaleString()} TL
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AlarmOnIcon />
                          </ListItemIcon>
                          <ListItemText>
                            Sipariş Verildi:{" "}
                            {dayjs(order.date_issued).format("LLL")}
                          </ListItemText>
                        </ListItem>
                      </List>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          href={`/dashboard/order-detail/${order.transaction_id}`}
                          style={{ color: "#196DDD" }}
                        >
                          <ListItemText primary="Sipariş Detayı" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Card>
                </div>
              ))}
          </Grid>
        </div>
      );
    }
  };

  const devices = () => {
    if (total_items > 0) {
      return (
        <Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Cihazlar</Typography>
            <Button onClick={handleClickOpen}>CİHAZ EKLE</Button>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                {"Yeni Cihaz Kayıt"}
              </DialogTitle>
              <DialogContent>
                <AddDevicePopup />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  Kapat
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          {showItems()}
        </Grid>
      );
    } else {
      return (
        <Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Cihazlar</Typography>
            <Button onClick={handleClickOpen}>CİHAZ EKLE</Button>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                {"Yeni Cihaz Kayıt"}
              </DialogTitle>
              <DialogContent>
                <AddDevicePopup />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  Kapat
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Paper variant="outlined" style={{ borderRadius: 20, marginTop: 30 }}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography
                style={{
                  margin: 20,
                  marginTop: 30,
                  fontWeight: "bold",
                }}
              >
                Henüz ekli bir cihazınız yok.
              </Typography>
              <Typography
                style={{
                  color: "grey",
                  margin: 20,
                  marginBottom: 50,
                  textTransform: "none",
                  textAlign: "center",
                }}
              >
                UbiHouse eklemek için "Cihaz Ekle" butonunu kullanabilirsiniz.
                Ekli cihazınızı görmüyorsanız bizle support@ubicro.com mail
                adresi üzerinden iletişime geçebilirsiniz.
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      );
    }
  };

  const userProfile = () => {
    if (profile && profile !== null && profile !== undefined) {
      return (
        <UserProfileForm
          address_line_1={address_line_1}
          city={city}
          district={district}
          phone={phone}
          onChange={onChange}
          onSubmit={onSubmit}
          profile={profile}
        />
      );
    } else {
      return <Fragment></Fragment>;
    }
  };

  const renderData = () => {
    if (display === "user_info") {
      return <Fragment>{userInfo()}</Fragment>;
    } else if (display === "profile_info") {
      return <Fragment>{userProfile()}</Fragment>;
    } else if (display === "purchase_history") {
      return <Fragment>{purchaseHistory()}</Fragment>;
    } else if (display === "devices") {
      return <Fragment>{devices()}</Fragment>;
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Kullanıcı paneli sayfası" />
        <title>Kullanıcı Paneli - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/dashboard" />
      </Helmet>
      <Header />
      <Grid
        container
        justifyContent="center"
        direction="column"
        style={{ padding: 20 }}
      >
        <Grid item>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              style={{ maxWidth: 1000 }}
            >
              <Grid item>
                <Typography>Hesap</Typography>
              </Grid>
              <Grid item>
                <Button onClick={logout} href="/">
                  Oturumu Kapat
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ maxWidth: 1000 }}
            >
              <Typography variant="h5" style={{ marginRight: 8 }}>
                Merhaba,
              </Typography>
              <Typography variant="h5" style={{ textTransform: "capitalize" }}>
                {user && user !== null && user !== undefined ? (
                  user.first_name
                ) : (
                  <Fragment></Fragment>
                )}
                .
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider variant="fullWidth" light />

      <Grid container direction="row" justifyContent="center">
        <Grid
          container
          direction="row"
          style={{ padding: 20, marginTop: 50, maxWidth: 1000 }}
        >
          <Grid item xs={12} md={5}>
            <Paper
              variant="outlined"
              style={{
                padding: 20,
                borderRadius: 20,
                width: 300,
                marginBottom: 50,
              }}
            >
              <Typography variant="h6">Kullanıcı Paneli</Typography>
              <List className="list-group">
                <ListItem>
                  <ListItemButton
                    className="list-group-item"
                    onClick={() => setDisplay("user_info")}
                  >
                    {display === "user_info" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Kullanıcı Bilgilerim
                      </Typography>
                    ) : (
                      <Typography>Kullanıcı Bilgilerim</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    className="list-group-item"
                    onClick={() => setDisplay("profile_info")}
                  >
                    {display === "profile_info" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Adres Bilgilerim
                      </Typography>
                    ) : (
                      <Typography>Adres Bilgilerim</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    className="list-group-item"
                    onClick={() => setDisplay("purchase_history")}
                  >
                    {display === "purchase_history" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Siparişlerim
                      </Typography>
                    ) : (
                      <Typography>Siparişlerim</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    className="list-group-item"
                    onClick={() => setDisplay("devices")}
                  >
                    {display === "devices" ? (
                      <Typography style={{ color: "#196DDD" }}>
                        Cihazlarım
                      </Typography>
                    ) : (
                      <Typography>Cihazlarım</Typography>
                    )}
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5} style={{ marginBottom: 100 }}>
            {renderData()}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  user: state.auth.user,
  profile: state.profile.profile,
  items: state.devices.items,
  total_items: state.devices.total_items,
});

export default connect(mapStateToProps, {
  get_items,
  get_total,
  get_item_total,
  get_device,
  add_device,
  remove_device,
  get_device_total,
  list_orders,
  get_user_profile,
  update_user_profile,
  logout,
  setAlert,
})(Dashboard);
