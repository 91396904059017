import React from "react";
import UbiEnergyVideo from "../assets/videos/UbiEnergyVideo.mp4";
import UbitrayHareketi from "../assets/videos/UbitrayHareketi.mp4";
import Isik from "../assets/videos/Isik.mp4";
import { Typography, Grid, Divider } from "@mui/material";

export default function ProductDetailVideos() {
  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        direction="row"
        style={{
          backgroundColor: "#FBFBFD",
          borderRadius: 10,
          padding: 20,
          paddingTop: 60,
          paddingBottom: 100,
          maxWidth: 1300,
        }}
      >
        <Grid item xs={6}>
          <video autoPlay loop width={600} style={{ borderRadius: 20 }}>
            <source src={Isik} type="video/mp4" />
          </video>
        </Grid>
        <Grid item xs={6} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              Özel LED ışıkları
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              Kullandığımız LED ışıklar Ubicro için özel üretilmiş ışıklardır.
              Güneşe benzer ışıklar hem gözü yormaz hemde bitkilerin büyümesi
              için optimum ışık kaynağıdır
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ width: "100%", marginTop: 100, marginBottom: 100 }} />

        <Grid item xs={6} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              UbiTray
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              Seçtiğiniz bitkiyi kolayca yetiştirebilirsiniz. UbiTray’deki
              bitkiler, genetiği değiştirilmemiş (GDO’suz) tohumlardan
              gelmektedir. Tek yapmanız gereken, cihaza UbiTray’i yerleştirmek.
              Cihaz, seçtiğiniz bitkiyi otomatik algılar, ve buna göre sistemi
              çalıştırır.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <video autoPlay loop width={600} style={{ borderRadius: 20 }}>
            <source src={UbitrayHareketi} type="video/mp4" />
          </video>
        </Grid>

        <Divider style={{ width: "100%", marginTop: 100, marginBottom: 100 }} />

        <Grid item xs={6}>
          <video autoPlay loop width={600} style={{ borderRadius: 20 }}>
            <source src={UbiEnergyVideo} type="video/mp4" />
          </video>
        </Grid>
        <Grid item xs={6} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              UbiEnergy
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              UbiEnergy ile bitkinin ihtiyaç duyduğu tüm besin ve pH dengesi
              mevcuttur.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "#FBFBFD",
          borderRadius: 10,
          padding: 10,
          paddingTop: 30,
          paddingBottom: 50,
        }}
      >
        <Grid item xs={12}>
          <video autoPlay loop width={"100%"} style={{ borderRadius: 20 }}>
            <source src={Isik} type="video/mp4" />
          </video>
        </Grid>
        <Grid item xs={12} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              Özel LED ışıkları
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              Kullandığımız LED ışıklar Ubicro için özel üretilmiş ışıklardır.
              Güneşe benzer ışıklar hem gözü yormaz hemde bitkilerin büyümesi
              için optimum ışık kaynağıdır
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ width: "100%", marginTop: 100, marginBottom: 100 }} />

        <Grid item xs={12}>
          <video autoPlay loop width={"100%"} style={{ borderRadius: 20 }}>
            <source src={UbitrayHareketi} type="video/mp4" />
          </video>
        </Grid>
        <Grid item xs={12} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              UbiTray
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              Seçtiğiniz bitkiyi kolayca yetiştirebilirsiniz. UbiTray’deki
              bitkiler, genetiği değiştirilmemiş (GDO’suz) tohumlardan
              gelmektedir. Tek yapmanız gereken, cihaza UbiTray’i yerleştirmek.
              Cihaz, seçtiğiniz bitkiyi otomatik algılar, ve buna göre sistemi
              çalıştırır.
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ width: "100%", marginTop: 100, marginBottom: 100 }} />

        <Grid item xs={12}>
          <video autoPlay loop width={"100%"} style={{ borderRadius: 20 }}>
            <source src={UbiEnergyVideo} type="video/mp4" />
          </video>
        </Grid>
        <Grid item xs={12} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              UbiEnergy
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              UbiEnergy ile bitkinin ihtiyaç duyduğu tüm besin ve pH dengesi
              mevcuttur.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
