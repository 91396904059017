import React from "react";
import { Typography, Grid, Paper, Skeleton } from "@mui/material";

export default function DeviceUbiTrayCard() {
  return (
    <Paper
      style={{
        margin: 20,
        borderRadius: 20,
        width: 750,
        height: 150,
        overflow: "hidden",
      }}
    >
      <Grid container direction="row" style={{ height: 150 }}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={200}
          height={150}
        />
        <Grid style={{ marginLeft: 20 }}>
          <Grid item style={{ marginTop: 20 }}>
            <Typography variant="h6">Yedikule Marul</Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              justifyContent="space-around"
              alignContent="center"
              style={{ width: 400, height: 120, marginLeft: 50 }}
            >
              <Grid item>
                <Typography gutterBottom variant="h6">
                  01 Ağustos
                </Typography>
                <Typography style={{ color: "grey" }}>Ekim tarihi</Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h6">
                  07 Eylül
                </Typography>
                <Typography style={{ color: "grey" }}>Hasat tarihi</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
