import React from "react";
import {
  Card,
  Typography,
  Grid,
  CardActionArea,
  CardContent,
  CardMedia,
  Paper,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ListItemUbiTray from "../components/listItemUbiTray";
import ListItemUbiHouse from "../components/listItemUbiHouse";
import ListItemFaydalar from "../components/listItemFaydalar";
import ListItemUbiEnergy from "../components/listItemUbiEnergy";
import ListItemAdimlar from "../components/listItemAdimlar";
import { Helmet } from "react-helmet";
// import HomePageBanner from "../components/HomepageBanner";

//Image Imports
import trayRoots from "../assets/images/trayRoots.png";

const Anasayfa = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Tarımda Yeni Çağın Başlangıcı" />
        <title>Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com" />
      </Helmet>
      <Header />
      <Grid container direction="column" style={{ backgroundColor: "#F5F5F7" }}>
        {/* <HomePageBanner /> */}
        <ListItemUbiHouse />
        <ListItemAdimlar />
        <ListItemUbiTray />
        <ListItemUbiEnergy />
        <ListItemFaydalar />

        <div>
          <Grid container direction="row" style={{ marginBottom: 100 }}>
            <Grid item container xs={12} md={5} style={{ marginTop: 100 }}>
              <Grid sx={{ md: "hidden", width: 20 }} />
              <Card
                style={{
                  borderRadius: 20,
                  marginLeft: 20,
                  marginRight: 20,
                  maxWidth: 450,
                }}
                className="scrollMenuComponent"
              >
                <CardActionArea href="/abonelik">
                  <CardContent>
                    <Grid>
                      <Typography gutterBottom variant="h5">
                        Abonelik.
                      </Typography>
                      <Typography gutterBottom color="textSecondary">
                        Ücretsiz kargo imkanı.
                      </Typography>
                      <Typography gutterBottom color="textSecondary">
                        İstediğiniz zaman iptal edin.
                      </Typography>
                      <Typography gutterBottom color="textSecondary">
                        Her ay size yetecek kadar alın ve ona göre ödeyin.
                      </Typography>
                    </Grid>
                  </CardContent>
                  <CardMedia
                    component="img"
                    alt="trayRoots"
                    src={trayRoots}
                    title="trayRoots"
                    style={{
                      height: 250,
                      marginTop: 40,
                    }}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>

        <Paper>
          <Footer />
        </Paper>
      </Grid>
    </div>
  );
};

export default Anasayfa;
