import React, { useEffect } from "react";
import { connect } from "react-redux";
import { check_authenticated, load_user, refresh } from "../actions/auth";
import { get_items, get_total, get_item_total } from "../actions/cart";
import { get_user_profile } from "../actions/profile";
import Alert from "../components/Alert";

const Layout = ({
  check_authenticated,
  load_user,
  get_user_profile,
  refresh,
  get_items,
  get_total,
  get_item_total,
  children,
}) => {
  useEffect(() => {
    refresh();
    check_authenticated();
    load_user();
    get_user_profile();
    get_items();
    get_total();
    get_item_total();
  }, []);

  return (
    <div>
      <Alert />
      {children}
    </div>
  );
};

export default connect(null, {
  check_authenticated,
  load_user,
  get_user_profile,
  refresh,
  get_items,
  get_total,
  get_item_total,
})(Layout);
