import React, { Fragment } from "react";
import { Grid, Card, CardContent, CardMedia, Typography } from "@mui/material";

// Icon imports
import artilarIkonu from "../assets/images/artilarIkonu.svg";
import artilarIkonu2 from "../assets/images/artilarIkonu2.svg";
import artilarIkonu3 from "../assets/images/artilarIkonu3.svg";
import artilarIkonu4 from "../assets/images/artilarIkonu4.svg";
import artilarIkonu5 from "../assets/images/artilarIkonu5.svg";
import artilarIkonu6 from "../assets/images/artilarIkonu6.svg";
import artilarIkonu7 from "../assets/images/artilarIkonu7.svg";

const ListItemFaydalar = () => {
  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 40 }}
        >
          <Typography variant="h5">
            Doğaya ve insanlara avantajlarımız.
          </Typography>
        </Grid>
        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 350,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu2}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Su tasarrufu
                </Typography>
                <Typography color="textSecondary">
                  %95’e varan su tasarrufu.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 350,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu3}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Enerji tasarrufu
                </Typography>
                <Typography color="textSecondary">
                  Düşük elektrik tüketimi.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 350,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu5}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Zirai İlaç Yok
                </Typography>
                <Typography color="textSecondary">
                  Tarım ilacı ve pestisit kullanılmaz.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 350,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu6}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Taze Mevsim Bitkileri
                </Typography>
                <Typography color="textSecondary">
                  Her mevsim taptaze dalından tüketin.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 350,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu7}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Ürün çeşitliliği
                </Typography>
                <Typography color="textSecondary">
                  Yüzlerce ürünü dilediğiniz gibi yetiştirin.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 350,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu4}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Hızlı Üretim
                </Typography>
                <Typography color="textSecondary">
                  Birçok üründe daha hızlı üretim.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 350,
              borderRadius: 20,
              marginLeft: 40,
              marginRight: 50,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Düşük fiyatla maksimum verim
                </Typography>
                <Typography color="textSecondary">
                  Ekonomik fiyatlarla cebinize yardımcı olur.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 20 }}
        >
          <Typography variant="h5">
            Doğaya ve insanlara avantajlarımız.
          </Typography>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 310,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu2}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Su tasarrufu
                </Typography>
                <Typography color="textSecondary">
                  %95’e varan su tasarrufu.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 310,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu3}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Enerji tasarrufu
                </Typography>
                <Typography color="textSecondary">
                  Düşük elektrik tüketimi.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 310,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu5}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Zirai İlaç Yok
                </Typography>
                <Typography color="textSecondary">
                  Tarım ilacı ve pestisit kullanılmaz.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 310,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu6}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Taze Mevsim Bitkileri
                </Typography>
                <Typography color="textSecondary">
                  Her mevsim taptaze dalından tüketin.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 310,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu7}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Ürün çeşitliliği
                </Typography>
                <Typography color="textSecondary">
                  Yüzlerce ürünü dilediğiniz gibi yetiştirin.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 310,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu4}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Hızlı Üretim
                </Typography>
                <Typography color="textSecondary">
                  Birçok üründe daha hızlı üretim.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <Card
            style={{
              width: 330,
              borderRadius: 20,
              marginLeft: 20,
              marginRight: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardMedia
              component="img"
              alt="Su tasarrufu"
              src={artilarIkonu}
              title="Su tasarrufu"
              className="listItemArtilarIkonu"
            />
            <CardContent>
              <Grid>
                <Typography gutterBottom variant="h5">
                  Düşük fiyat maksimum verim
                </Typography>
                <Typography color="textSecondary">
                  Ekonomik fiyatlarla cebinize yardımcı.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </Fragment>
    );
  }
};

export default ListItemFaydalar;
