import React, { useState } from "react";
import { Typography, Grid, Paper, Divider, Button } from "@mui/material";
import DeviceUbiTrayCard from "./DeviceUbiTrayCard";

// Image imports
import UbiHouseMini from "../assets/images/UbiHouseMini.jpg";
import UbiHouse from "../assets/images/UbiHouse.jpg";
// Icon imports
import WifiIcon from "@mui/icons-material/Wifi";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LockIcon from "@mui/icons-material/Lock";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const DeviceDetailCard = ({ product, webSocketConnected, iotData }) => {
  const [clicked, setClicked] = useState(false);
  console.log("device Data: ", iotData);
  let width = window.innerWidth;

  function LEDInfo() {
    if (iotData.led?.N === "1") {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#479E30",
            marginTop: 10,
          }}
        >
          Açık
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#AA2116",
            marginTop: 10,
          }}
        >
          Kapalı
        </Typography>
      );
    }
  }

  function SisleyiciSolInfo() {
    if (iotData.atomizer_left?.N === "1") {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#479E30",
            marginTop: 10,
          }}
        >
          Açık
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#AA2116",
            marginTop: 10,
          }}
        >
          Kapalı
        </Typography>
      );
    }
  }

  function SisleyiciOrtaInfo() {
    if (iotData.atomizer_mid?.N === "1") {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#479E30",
            marginTop: 10,
          }}
        >
          Açık
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#AA2116",
            marginTop: 10,
          }}
        >
          Kapalı
        </Typography>
      );
    }
  }

  function SisleyiciSagInfo() {
    if (iotData.atomizer_right?.N === "1") {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#479E30",
            marginTop: 10,
          }}
        >
          Açık
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#AA2116",
            marginTop: 10,
          }}
        >
          Kapalı
        </Typography>
      );
    }
  }

  function SuSirkiulasyonInfo() {
    if (iotData.water_pump?.N === "1") {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#479E30",
            marginTop: 10,
          }}
        >
          Aktif
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#AA2116",
            marginTop: 10,
          }}
        >
          Kapalı
        </Typography>
      );
    }
  }

  function VitaminPumpInfo() {
    if (iotData.vitamin_pump?.N === "1") {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#479E30",
            marginTop: 10,
          }}
        >
          Aktif
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#AA2116",
            marginTop: 10,
          }}
        >
          Kapalı
        </Typography>
      );
    }
  }

  function PHPumpInfo() {
    if (iotData.ph_pump?.N === "1") {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#479E30",
            marginTop: 10,
          }}
        >
          Aktif
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h5"
          style={{
            color: "#AA2116",
            marginTop: 10,
          }}
        >
          Kapalı
        </Typography>
      );
    }
  }

  function DeviceImage() {
    if (width > 800) {
      if (product && product.device_type === "UbiHouse Mini") {
        return (
          <img
            src={UbiHouseMini}
            alt={product.name}
            style={{ maxWidth: 500, maxHeight: 500 }}
          />
        );
      }
      if (product && product.device_type === "UbiHouse") {
        return (
          <img
            src={UbiHouse}
            alt={product.name}
            style={{ maxWidth: 500, maxHeight: 500 }}
          />
        );
      } else {
        return null;
      }
    } else {
      if (product && product.device_type === "UbiHouse Mini") {
        return (
          <img
            src={UbiHouseMini}
            alt={product.name}
            style={{ maxWidth: 320, maxHeight: 320 }}
          />
        );
      }
      if (product && product.device_type === "UbiHouse") {
        return (
          <img
            src={UbiHouse}
            alt={product.name}
            style={{ maxWidth: 320, maxHeight: 320 }}
          />
        );
      } else {
        return null;
      }
    }
  }

  function ShowPassword() {
    return (
      <Grid item>
        <Grid container direction="row" alignItems="center">
          {!clicked === false ? (
            <Grid container alignItems="center" flexDirection="row">
              <Typography
                style={{
                  fontWeight: "bold",
                  marginRight: 10,
                }}
              >
                Şifre:
              </Typography>
              <Typography style={{ marginRight: 10 }}>
                {product &&
                  product !== null &&
                  product !== undefined &&
                  product.password}
              </Typography>
            </Grid>
          ) : null}
          {!clicked === true ? (
            <Button
              onClick={() => {
                setClicked(true);
              }}
              style={{ textTransform: "none" }}
              startIcon={<LockIcon />}
            >
              Şifreyi göster
            </Button>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  if (width > 800) {
    return (
      <div>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIosNewIcon />}
          href="/dashboard"
          style={{ textTransform: "none", position: "absolute", margin: 30 }}
        >
          Panele dön
        </Button>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20, padding: 20, maxWidth: 890 }}
        >
          {DeviceImage()}
          <Grid container style={{ marginLeft: 30 }}>
            <Typography variant="h6" style={{ color: "grey" }}>
              Cihaz Bilgileri
            </Typography>
          </Grid>
          <Paper
            variant="outlined"
            style={{
              margin: 20,
              padding: 30,
              borderRadius: 20,
              width: 750,
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Grid item>
                <Grid container direction="row">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    Cihaz adı:
                  </Typography>
                  <Typography>{product.name}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                {webSocketConnected === true ? (
                  <Grid container direction="row">
                    <WifiIcon
                      style={{
                        color: "#3ABC0E",
                        marginRight: 10,
                      }}
                    />
                    <Typography style={{ color: "#3ABC0E" }}>
                      İnternete Bağlı
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container direction="row">
                    <SignalWifiOffIcon
                      style={{
                        color: "#ACACAC",
                        marginRight: 10,
                      }}
                    />
                    <Typography style={{ color: "#ACACAC" }}>
                      İnternet Bağlıntısı Yok
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>

          <Paper
            variant="outlined"
            style={{
              margin: 20,
              padding: 30,
              borderRadius: 20,
              width: 750,
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Grid item>
                <Grid container direction="row">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    Seri Numarası:
                  </Typography>
                  <Typography>{product.serial_number}</Typography>
                </Grid>

                <Grid container direction="row" style={{ marginTop: 30 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    Model:
                  </Typography>
                  <Typography>{product.device_type}</Typography>
                </Grid>

                <Grid container direction="row" style={{ marginTop: 30 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    Model Yılı:
                  </Typography>
                  <Typography>{product.device_year}</Typography>
                </Grid>
              </Grid>
              {ShowPassword()}
            </Grid>
          </Paper>

          <Divider style={{ width: 800, marginTop: 20, marginBottom: 50 }} />

          <Grid container style={{ marginLeft: 30 }}>
            <Typography variant="h6" style={{ color: "grey" }}>
              Cihaz Durumu
            </Typography>
          </Grid>

          <Paper
            variant="outlined"
            style={{
              margin: 20,
              borderRadius: 20,
              padding: 40,
              width: 750,
              height: 70,
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      color: "#479E30",
                      fontSize: 24,
                    }}
                  >
                    {SisleyiciSolInfo()}
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>
                    Sisleyici Sol
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      color: "#479E30",
                      fontSize: 24,
                    }}
                  >
                    {SisleyiciOrtaInfo()}
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>
                    Sisleyici Orta
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      color: "#479E30",
                      fontSize: 24,
                    }}
                  >
                    {SisleyiciSagInfo()}
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>
                    Sisleyici Sağ
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Paper
              variant="outlined"
              style={{
                margin: 20,
                padding: 20,
                borderRadius: 20,
                width: 200,
                height: 60,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Typography
                        style={{
                          fontSize: 24,
                        }}
                      >
                        {SuSirkiulasyonInfo()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: "#ACACAC" }}>
                      Su sirkülasyonu
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              variant="outlined"
              style={{
                margin: 20,
                padding: 20,
                borderRadius: 20,
                width: 200,
                height: 60,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Typography
                        style={{
                          fontSize: 24,
                        }}
                      >
                        {VitaminPumpInfo()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: "#ACACAC" }}>
                      Besin motoru
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              variant="outlined"
              style={{
                margin: 20,
                padding: 20,
                borderRadius: 20,
                width: 200,
                height: 60,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Typography
                        style={{
                          fontSize: 24,
                        }}
                      >
                        {PHPumpInfo()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: "#ACACAC" }}>
                      PH motoru
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Paper
            variant="outlined"
            style={{
              margin: 20,
              borderRadius: 20,
              padding: 40,
              width: 750,
              height: 70,
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      fontSize: 24,
                    }}
                  >
                    95%
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>Su Seviyesi</Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      fontSize: 24,
                    }}
                  >
                    45%
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>
                    Besin Seviyesi
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      fontSize: 24,
                    }}
                  >
                    75%
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>PH Seviyesi</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Paper
              variant="outlined"
              style={{
                margin: 20,
                padding: 20,
                borderRadius: 20,
                width: 200,
                height: 60,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid
                  item
                  container
                  style={{
                    backgroundColor: "#EDF5FE",
                    padding: 10,
                    borderRadius: 5,
                    width: 60,
                    height: 60,
                    marginRight: 20,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThermostatIcon style={{ color: "#2A57A0", fontSize: 30 }} />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Typography
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {iotData.temperature?.N}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: "#ACACAC" }}>
                        Oda Sıcaklığı
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              variant="outlined"
              style={{
                margin: 20,
                padding: 20,
                borderRadius: 20,
                width: 200,
                height: 60,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid
                  item
                  container
                  style={{
                    backgroundColor: "#EDF5FE",
                    padding: 10,
                    borderRadius: 5,
                    width: 60,
                    height: 60,
                    marginRight: 20,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AcUnitIcon style={{ color: "#2A57A0", fontSize: 30 }} />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Typography
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {iotData.humidity?.N}%
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: "#ACACAC" }}>
                        Nem Derecesi
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              variant="outlined"
              style={{
                margin: 20,
                padding: 20,
                borderRadius: 20,
                width: 200,
                height: 60,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid
                  item
                  container
                  style={{
                    backgroundColor: "#EDF5FE",
                    padding: 10,
                    borderRadius: 5,
                    width: 60,
                    height: 60,
                    marginRight: 20,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <InvertColorsIcon
                    style={{ color: "#2A57A0", fontSize: 30 }}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Typography
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {iotData.pH?.N}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: "#ACACAC" }}>
                        PH Değeri
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Paper
            variant="outlined"
            style={{
              margin: 20,
              borderRadius: 20,
              padding: 30,
              width: 320,
              height: 80,
            }}
          >
            <Grid container alignItems="center" direction="column">
              <Typography>LED Işık</Typography>
              {LEDInfo()}
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginBottom: 5,
                      marginRight: 20,
                      color: "#ACACAC",
                    }}
                  >
                    Açılış: 07:00
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: 5, color: "#ACACAC" }}
                  >
                    Kapanış: 22:00
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Divider style={{ width: 800, marginTop: 20, marginBottom: 50 }} />
          <Grid container style={{ marginLeft: 30 }}>
            <Typography variant="h6" style={{ color: "grey" }}>
              UbiTray Bilgileri
            </Typography>
          </Grid>
          <DeviceUbiTrayCard />
          <DeviceUbiTrayCard />
          <DeviceUbiTrayCard />
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIosNewIcon />}
          href="/dashboard"
          style={{ textTransform: "none", margin: 10 }}
        >
          Panele dön
        </Button>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 10, padding: 10, width: "100%" }}
        >
          {DeviceImage()}
          <Grid container>
            <Typography variant="h6" style={{ color: "grey", marginTop: 20 }}>
              Cihaz Bilgileri
            </Typography>
          </Grid>
          <Paper
            variant="outlined"
            style={{
              padding: 20,
              marginBottom: 10,
              marginTop: 10,
              borderRadius: 20,
              width: "100%",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Grid item>
                <Grid container direction="row">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    Cihaz adı:
                  </Typography>
                  <Typography>{product.name}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                {webSocketConnected === true ? (
                  <Grid container direction="row">
                    <WifiIcon
                      style={{
                        color: "#3ABC0E",
                      }}
                    />
                    <Typography style={{ color: "#3ABC0E" }}>
                      İnternete Bağlı
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container direction="row">
                    <SignalWifiOffIcon
                      style={{
                        color: "#ACACAC",
                      }}
                    />
                    <Typography style={{ color: "#ACACAC" }}>
                      İnternet Bağlıntısı Yok
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>

          <Paper
            variant="outlined"
            style={{
              padding: 20,
              borderRadius: 20,
              width: "100%",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Grid item>
                <Grid container direction="row">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    Seri Numarası:
                  </Typography>
                  <Typography>{product.serial_number}</Typography>
                </Grid>

                <Grid container direction="row" style={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    Model:
                  </Typography>
                  <Typography>{product.device_type}</Typography>
                </Grid>

                <Grid container direction="row" style={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    Model Yılı:
                  </Typography>
                  <Typography>{product.device_year}</Typography>
                </Grid>
                {ShowPassword()}
              </Grid>
            </Grid>
          </Paper>

          <Grid container>
            <Typography variant="h6" style={{ color: "grey", marginTop: 20 }}>
              Cihaz Durumu
            </Typography>
          </Grid>

          <Paper
            variant="outlined"
            style={{
              borderRadius: 20,
              padding: 20,
              height: 70,
              width: "100%",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      color: "#479E30",
                      fontSize: 24,
                    }}
                  >
                    {SisleyiciSolInfo()}
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>
                    Sisleyici Sol
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      color: "#479E30",
                      fontSize: 24,
                    }}
                  >
                    {SisleyiciOrtaInfo()}
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>
                    Sisleyici Orta
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      color: "#479E30",
                      fontSize: 24,
                    }}
                  >
                    {SisleyiciSagInfo()}
                  </Typography>
                  <Typography style={{ marginTop: 10 }}>
                    Sisleyici Sağ
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Paper
              variant="outlined"
              style={{
                padding: 20,
                borderRadius: 20,
                height: 60,
                marginBottom: 10,
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Typography
                        style={{
                          fontSize: 24,
                        }}
                      >
                        {SuSirkiulasyonInfo()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: "#ACACAC" }}>
                      Su sirkülasyonu
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              variant="outlined"
              style={{
                padding: 20,
                borderRadius: 20,
                height: 60,
                marginBottom: 10,
                marginTop: 10,
                marginLeft: 10,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Typography
                        style={{
                          fontSize: 24,
                        }}
                      >
                        {VitaminPumpInfo()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: "#ACACAC" }}>
                      Besin motoru
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              variant="outlined"
              style={{
                padding: 20,
                borderRadius: 20,
                height: 60,
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Typography
                        style={{
                          fontSize: 24,
                        }}
                      >
                        {PHPumpInfo()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: "#ACACAC" }}>
                      PH motoru
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Paper
            variant="outlined"
            style={{
              borderRadius: 20,
              padding: 20,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      fontSize: 24,
                    }}
                  >
                    95%
                  </Typography>
                  <Typography>Su Seviyesi</Typography>
                </Grid>
              </Grid>

              <Grid item style={{ margin: 15 }}>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      fontSize: 24,
                    }}
                  >
                    45%
                  </Typography>
                  <Typography>Besin Seviyesi</Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <Typography
                    style={{
                      fontSize: 24,
                    }}
                  >
                    75%
                  </Typography>
                  <Typography>PH Seviyesi</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Paper
              variant="outlined"
              style={{
                margin: 20,
                padding: 20,
                borderRadius: 20,
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid
                  item
                  container
                  style={{
                    backgroundColor: "#EDF5FE",
                    padding: 10,
                    borderRadius: 5,
                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThermostatIcon style={{ color: "#2A57A0", fontSize: 30 }} />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Typography
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {iotData.temperature?.N}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: "#ACACAC" }}>
                        Oda Sıcaklığı
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              variant="outlined"
              style={{
                padding: 20,
                borderRadius: 20,
                marginBottom: 10,
                marginTop: 10,
                marginRight: 10,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid
                  item
                  container
                  style={{
                    backgroundColor: "#EDF5FE",
                    padding: 10,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AcUnitIcon style={{ color: "#2A57A0", fontSize: 30 }} />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Typography
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {iotData.humidity?.N}%
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: "#ACACAC" }}>
                        Nem Derecesi
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              variant="outlined"
              style={{
                padding: 20,
                borderRadius: 20,
                marginBottom: 10,
                marginTop: 10,
                marginLeft: 10,
              }}
            >
              <Grid container alignItems="center" direction="row">
                <Grid
                  item
                  container
                  style={{
                    backgroundColor: "#EDF5FE",
                    padding: 10,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <InvertColorsIcon
                    style={{ color: "#2A57A0", fontSize: 30 }}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Typography
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {iotData.pH?.N}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: "#ACACAC" }}>
                        PH Değeri
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Paper
            variant="outlined"
            style={{
              borderRadius: 20,
              padding: 20,
              height: 80,
            }}
          >
            <Grid container alignItems="center" direction="column">
              <Typography>LED Işık</Typography>
              {LEDInfo()}
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginBottom: 5,
                      marginRight: 20,
                      color: "#ACACAC",
                    }}
                  >
                    Açılış: 07:00
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: 5, color: "#ACACAC" }}
                  >
                    Kapanış: 22:00
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    );
  }
};

export default DeviceDetailCard;
