import React from "react";
import { Checkbox, Divider, Grid, TextField, Typography } from "@mui/material";

let width = window.innerWidth;

function MokaCheckoutForm({
  full_name,
  user,
  profile,
  address_line_1,
  city,
  district,
  telephone_number,
  onChange,
  buy,
  renderShipping,
}) {
  return (
    <Grid style={{ width: "100%" }}>
      <form onSubmit={(e) => buy(e)}>
        <Typography variant="h5" style={{ marginLeft: 20, marginBottom: 5 }}>
          Kargo seçimi
        </Typography>
        {renderShipping()}
        <Divider
          style={{
            marginTop: 20,
            marginBottom: 30,
            width: "100%",
            maxWidth: 800,
          }}
        />

        <Grid
          container
          direction="column"
          style={{ maxWidth: 500, padding: 20 }}
        >
          <Typography variant="h6">Teslimat Adresi Bilgileri</Typography>
          <Typography
            variant="subtitle2"
            style={{ marginTop: 30, color: "grey" }}
          >
            İletişim bilgileri
          </Typography>
          <TextField
            className="form-control"
            type="text"
            name="full_name"
            onChange={(e) => onChange(e)}
            value={full_name}
            required
            label="Ad ve soyad"
            fullWidth
            style={{ marginBottom: 20, marginTop: 20 }}
          />
          <TextField
            className="form-control"
            type="tel"
            name="telephone_number"
            onChange={(e) => onChange(e)}
            value={telephone_number}
            required
            label="Telefon"
            fullWidth
            style={{ marginBottom: 20 }}
          />
          <Divider light style={{ marginBottom: 30, marginTop: 10 }} />
          <Typography
            variant="subtitle2"
            style={{ marginBottom: 20, color: "grey" }}
          >
            Adres bilgileri
          </Typography>
          <TextField
            className="form-control"
            type="text"
            name="city"
            onChange={(e) => onChange(e)}
            value={city}
            required
            label="Şehir"
            fullWidth
            style={{ marginBottom: 20 }}
          />
          <TextField
            className="form-control"
            type="text"
            name="district"
            onChange={(e) => onChange(e)}
            value={district}
            required
            label="İlçe"
            fullWidth
            style={{ marginBottom: 20 }}
          />
          <TextField
            className="form-control"
            type="text"
            name="address_line_1"
            onChange={(e) => onChange(e)}
            value={address_line_1}
            required
            label="Adres"
            fullWidth
            style={{ marginBottom: 20 }}
            multiline
            rows={4}
          />
        </Grid>

        <Divider
          style={{
            marginTop: 10,
            marginBottom: 30,
            width: "100%",
            maxWidth: 800,
          }}
        />
        <Grid item container direction="column" style={{ maxWidth: 800 }}>
          <Typography variant="h5" style={{ marginLeft: 20 }}>
            Fatura bilgileri
          </Typography>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 15 }}
          >
            <Checkbox
              defaultChecked
              color="primary"
              style={{ color: "#1683FC", marginLeft: 10, marginRight: 10 }}
            />
            {width > 800 ? (
              <Typography>
                Fatura bilgilerim, teslimat adres bilgilerim ile aynı
              </Typography>
            ) : (
              <Typography>
                Fatura bilgilerim, <br /> teslimat adres bilgilerim ile aynı
              </Typography>
            )}
          </Grid>
        </Grid>
        <Divider
          style={{
            marginTop: 10,
            marginBottom: 30,
            width: "100%",
            maxWidth: 800,
          }}
        />
      </form>
    </Grid>
  );
}

export default MokaCheckoutForm;
