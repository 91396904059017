import { combineReducers } from 'redux';
import auth from './auth';
import alert from './alert';
import cart from './cart';
import categories from './categories';
import orders from './orders';
import payment from './payment';
import products from './products';
import shipping from './shipping';
import coupons from './coupons'
import profile from './profile';
import devices from './devices'

export default combineReducers({
    auth,
    alert,
    cart,
    coupons,
    categories,
    devices,
    orders,
    payment,
    products,
    profile,
    shipping,
});