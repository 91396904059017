import React, { Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
} from "@mui/material";

// Image imports
import UbiEnergyGorsel from "../assets/images/UbiEnergy.jpg";

const ListItemUbiEnergy = () => {
  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 40 }}
        >
          <Grid item container direction="row">
            <Typography variant="h5" style={{ marginRight: 5 }}>
              UbiEnergy.
            </Typography>
            <Typography variant="h5" style={{ color: "#6E6E73" }}>
              Üretim için gerekli her şey.
            </Typography>
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/ubienergy-nedir">
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiEnergy Nedir?
                  </Typography>
                  <Typography color="textSecondary">
                    Bitkilerinizin ihtiyacı olan her şey.
                  </Typography>
                </Grid>
              </CardContent>
              <CardMedia
                component="img"
                alt="UbiEnergy Nedir"
                src={UbiEnergyGorsel}
                title="UbiEnergy Nedir"
                style={{
                  height: 250,
                  width: 220,
                  marginTop: 70,
                  marginLeft: 80,
                }}
              />
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 30,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubienergy">
              <CardMedia
                component="img"
                alt="UbiEnergy"
                src={UbiEnergyGorsel}
                title="UbiEnergy"
                style={{
                  height: 250,
                  width: 220,
                  marginBottom: 20,
                  marginLeft: 80,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiEnergy
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    200 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 20 }}
        >
          <Grid item container direction="row">
            <Typography variant="h5" style={{ marginRight: 5 }}>
              UbiEnergy.
            </Typography>
            <Typography variant="h5" style={{ color: "#6E6E73" }}>
              Üretim için gerekli her şey.
            </Typography>
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/ubienergy-nedir">
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiEnergy Nedir?
                  </Typography>
                  <Typography color="textSecondary">
                    Bitkilerinizin ihtiyacı olan her şey.
                  </Typography>
                </Grid>
              </CardContent>
              <CardMedia
                component="img"
                alt="UbiEnergy Nedir"
                src={UbiEnergyGorsel}
                title="UbiEnergy Nedir"
                style={{
                  height: 200,
                  width: 200,
                  marginTop: 70,
                  marginLeft: 50,
                }}
              />
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
              marginRight: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubienergy">
              <CardMedia
                component="img"
                alt="UbiEnergy"
                src={UbiEnergyGorsel}
                title="UbiEnergy"
                style={{
                  height: 200,
                  width: 200,
                  marginBottom: 60,
                  marginLeft: 50,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiEnergy
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    200 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Fragment>
    );
  }
};

export default ListItemUbiEnergy;
