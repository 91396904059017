import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import Partners from "./Partners";
import AppCard from "./AppCard";

// Icon imports
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import instagramSvg from "../assets/images/instagramSvg.svg";
import PrivacyPolicy from "./PrivacyPolicy";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [openGiz, setOpenGiz] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenGiz = () => {
    setOpenGiz(true);
  };

  const handleCloseGiz = () => {
    setOpenGiz(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const openPrivacyPolicy = urlParams.get("openPrivacyPolicy");
    if (openPrivacyPolicy === "true") {
      setOpenGiz(true);
    }
  }, []);

  return (
    <div>
      <Partners />
      <AppCard />
      <Grid
        container
        style={{ height: 100, width: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Button
            color="inherit"
            href="/hakkimizda"
            variant="text"
            style={{ textTransform: "none" }}
          >
            Hakkımızda
          </Button>
        </Grid>
        <Divider orientation="vertical" style={{ height: 30 }} />
        <Grid item>
          <Button
            color="inherit"
            variant="text"
            href="/iletisim"
            style={{ textTransform: "none" }}
          >
            İletişim
          </Button>
        </Grid>
        <Divider orientation="vertical" style={{ height: 30 }} />
        <Grid item>
          <Button
            variant="text"
            color="inherit"
            onClick={handleClickOpenGiz}
            style={{ textTransform: "none" }}
          >
            Gizlilik Politikası
          </Button>

          <Dialog open={openGiz} onClose={handleCloseGiz}>
            <DialogTitle id="alert-dialog-title1">
              {"Gizlilik Politikası"}
            </DialogTitle>
            <DialogContent>
              <PrivacyPolicy />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseGiz} autoFocus>
                Tamam
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Divider orientation="vertical" style={{ height: 30 }} />

        <Grid item>
          <Button
            color="inherit"
            variant="text"
            onClick={handleClickOpen}
            style={{ textTransform: "none" }}
          >
            Kullanım Koşulları
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title2">
              {"Kullanım Koşulları"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description2">
                Bu internet sitesine girmeniz veya bu internet sitesindeki
                herhangi bir bilgiyi kullanmanız aşağıdaki koşulları kabul
                ettiğiniz anlamına gelir. <br />
                <br />
                Bu internet sitesine girilmesi, sitenin ya da sitedeki
                bilgilerin ve diğer verilerin programların vs. kullanılması
                sebebiyle, sözleşmenin ihlali, haksız fiil gibi sebeplerden
                doğabilecek doğrudan ya da dolaylı hiçbir zarardan Ubicro
                Teknoloji A.Ş sorumlu değildir. <br />
                <br />
                Ubicro, sözleşmenin ihlali, haksız fiil, ihmal veya diğer
                sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal
                hususunda herhangi bir sorumluluk kabul etmez.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Tamam
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        spacing={1}
        className="FooterSocial"
        style={{ height: 50, width: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Button
            item
            href="https://www.instagram.com/ubicro/"
            target="_blank"
            variant="outlined"
            className="SocialMB"
            style={{ borderRadius: 40 }}
          >
            <img
              src={instagramSvg}
              alt="instagramSvg"
              className="instagramSvg"
            />
          </Button>
        </Grid>
        <Grid item>
          <Button
            item
            href="https://m.facebook.com/people/Ubicro/100087123534973/"
            variant="outlined"
            className="SocialMB"
            style={{ borderRadius: 40 }}
          >
            <FacebookIcon style={{ color: "#2177E8" }} />
          </Button>
        </Grid>
        <Grid item>
          <Button
            item
            href="https://www.linkedin.com/company/ubicro"
            target="_blank"
            variant="outlined"
            className="SocialMB"
            style={{ borderRadius: 40 }}
          >
            <LinkedInIcon style={{ color: "#1075AF" }} />
          </Button>
        </Grid>
        <Grid item>
          <Button
            item
            href="https://www.youtube.com/channel/UC2WgLKkqLlKQ5qbqzqeG3pA"
            variant="outlined"
            className="SocialMB"
            style={{ borderRadius: 40 }}
          >
            <YouTubeIcon style={{ color: "#F40D1A" }} />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ height: 80, width: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>Ubicro © 2024 Her Hakkı Saklıdır.</Typography>
      </Grid>
    </div>
  );
};

export default Footer;
