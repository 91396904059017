import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login } from "../../actions/auth";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Avatar,
  Paper,
  CardActionArea,
  Card,
  CircularProgress,
} from "@mui/material";
import Header from "../../components/Header";
import Alert from "../../components/Alert";
import { Helmet } from "react-helmet";

// Icon imports
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";

const Login = ({ login, isAuthenticated, loading }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    login(email, password);
  };

  const continue_with_google = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/google`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {}
  };

  const continue_with_facebook = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_API_URL}/facebook`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {}
  };

  if (isAuthenticated) return <Redirect to="/" />;

  let width = window.innerWidth;
  if (width > 500) {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Ubicro üye giriş sayfası" />
          <title>Üye Giriş - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/login" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Paper
            variant="outlined"
            style={{
              marginTop: 20,
              marginBottom: 30,
              padding: 50,
              borderRadius: 20,
            }}
          >
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignContent="center"
            >
              <Grid
                item
                container
                justifyContent="center"
                alignContent="center"
              >
                <Avatar>
                  <LockOpenIcon />
                </Avatar>
              </Grid>
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
                <Typography
                  variant="h5"
                  style={{ marginTop: 20, marginBottom: 15 }}
                >
                  Ubicro Hesabınıza Giriş Yapın
                </Typography>
                <Alert />
              </Grid>
            </Grid>
            <form onSubmit={(e) => onSubmit(e)}>
              <Grid style={{ minWidth: 380 }}>
                <TextField
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                  autoComplete="email"
                  margin="normal"
                  label="Email Adresi"
                  fullWidth
                />
              </Grid>
              <Grid>
                <TextField
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => onChange(e)}
                  minLength="6"
                  required
                  autoComplete="current-password"
                  margin="normal"
                  label="Şifre"
                  fullWidth
                />
              </Grid>
              {loading ? (
                <div>
                  <CircularProgress
                    size={68}
                    style={{ marginLeft: 150, marginTop: 20 }}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  style={{ marginBottom: 30, marginTop: 15 }}
                >
                  GİRİŞ YAP
                </Button>
              )}
            </form>
            <Typography>
              Hesabınız yok mu?{" "}
              <Button href="/signup" style={{ textTransform: "none" }}>
                Hesap Oluştur
              </Button>
            </Typography>
            <Typography>
              Şifrenizi mi unuttunuz?{" "}
              <Button href="/reset-password" style={{ textTransform: "none" }}>
                Şifre Yenile
              </Button>
            </Typography>
          </Paper>
          {/* 
          <Grid item style={{ marginBottom: 60 }}>
            <Grid container>
              <Card
                variant="outlined"
                style={{ marginRight: 10, borderRadius: 10 }}
              >
                <CardActionArea
                  onClick={continue_with_google}
                  style={{ padding: 20 }}
                >
                  <Grid container style={{ direction: "row" }}>
                    <GoogleIcon
                      sx={{ color: "#D95140" }}
                      style={{ marginRight: 10 }}
                    />
                    <Typography>Google ile giriş yap</Typography>
                  </Grid>
                </CardActionArea>
              </Card>
              <Card variant="outlined" style={{ borderRadius: 10 }}>
                <CardActionArea
                  onClick={continue_with_facebook}
                  style={{ padding: 20 }}
                >
                  <Grid container style={{ direction: "row" }}>
                    <FacebookIcon
                      sx={{ color: "#536DA4" }}
                      style={{ marginRight: 10 }}
                    />
                    <Typography>Facebook ile giriş yap</Typography>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid> */}
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Ubicro üye giriş sayfası" />
          <title>Üye Giriş - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/login" />
        </Helmet>
        <Header />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ marginBottom: 30 }}
        >
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignContent="center"
            style={{ marginTop: 20 }}
          >
            <Grid container justifyContent="center" alignContent="center">
              <Avatar>
                <LockOpenIcon />
              </Avatar>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              <Typography
                variant="h5"
                style={{ marginTop: 20, marginBottom: 15 }}
              >
                Ubicro Hesabınıza Giriş Yapın
              </Typography>
              <Alert />
            </Grid>
          </Grid>

          <form onSubmit={(e) => onSubmit(e)}>
            <Grid>
              <TextField
                type="email"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
                autoComplete="email"
                margin="normal"
                label="Email Adresi"
                fullWidth
              />
            </Grid>
            <Grid>
              <TextField
                type="password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
                autoComplete="current-password"
                margin="normal"
                label="Şifre"
                fullWidth
              />
            </Grid>
            {loading ? (
              <div>
                <CircularProgress
                  size={68}
                  style={{ marginLeft: 60, marginTop: 20 }}
                />
              </div>
            ) : (
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                style={{ marginBottom: 30, marginTop: 15, width: 310 }}
              >
                GİRİŞ YAP
              </Button>
            )}
          </form>
          <Typography>
            Hesabınız yok mu?{" "}
            <Button href="/signup" style={{ textTransform: "none" }}>
              Hesap Oluştur
            </Button>
          </Typography>
          <Typography>
            Şifrenizi mi unuttunuz?{" "}
            <Button href="/reset-password" style={{ textTransform: "none" }}>
              Şifre Yenile
            </Button>
          </Typography>
        </Grid>
        {/* 
        <Paper
          variant="outlined"
          style={{
            padding: 15,
            margin: 10,
            borderRadius: 10,
            backgroundColor: "#F5F5F7",
          }}
        >
          <Grid item>
            <Grid container justifyContent="center">
              <Card
                variant="outlined"
                style={{ borderRadius: 10, width: 250, marginBottom: 10 }}
              >
                <CardActionArea
                  onClick={continue_with_google}
                  style={{ padding: 15 }}
                >
                  <Grid container style={{ direction: "row" }}>
                    <GoogleIcon
                      sx={{ color: "#D95140" }}
                      style={{ marginRight: 10 }}
                    />
                    <Typography>Google ile giriş yap</Typography>
                  </Grid>
                </CardActionArea>
              </Card>
              <Card variant="outlined" style={{ borderRadius: 10, width: 250 }}>
                <CardActionArea
                  onClick={continue_with_facebook}
                  style={{ padding: 15 }}
                >
                  <Grid container style={{ direction: "row" }}>
                    <FacebookIcon
                      sx={{ color: "#536DA4" }}
                      style={{ marginRight: 10 }}
                    />
                    <Typography>Facebook ile giriş yap</Typography>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Paper> */}
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { login })(Login);
