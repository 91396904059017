import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../../actions/auth';
import { TextField, Grid, Typography, Paper, Container, Button, IconButton, CircularProgress } from '@mui/material';
import Header from "../../components/Header"
import { Helmet } from 'react-helmet'

// Icon Imports
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const ResetPassword = ({ reset_password, loading }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });

    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        reset_password(email);
        setRequestSent(true);
    };

    if (requestSent && !loading) {
        return <Redirect to='reset-password-info' />
    }

    let width = window.innerWidth;
      if (width > 500) {
        return (
          <div>
          <Helmet>
              <meta charSet='utf-8' />
              <meta name='description' content='Şifre yenileme sayfası' />
              <title>Şifre Yenileme - Ubicro</title>
              <link rel='canonical' href='https://www.ubicro.com/reset-password' />
          </Helmet>
          <Header />
    
          <Grid container style={{ justifyContent: "center", padding: 20 }}>
            <Paper maxWidth="xs" variant="outlined" style={{ marginTop: 40, marginBottom: 50, borderRadius: 10, padding: 50 }}>
              <Container maxWidth="xs">
                <IconButton href='/login' color='default' style={{ marginBottom: 30 }}>
                  <ArrowBackIosNewIcon />
                </IconButton>
                <div>
                  <Typography 
                    variant="h4"
                    gutterBottom
                  >
                    Şifre yenileme
                  </Typography>
                  <Typography 
                    variant="body1"
                    style={{ color: "#6E6E73" }}
                    >
                    Şifre yenileme bağlantısını gönderebilmemiz için e-posta adresinize ihtiyacımız var.
                  </Typography>
                  <form onSubmit={e => onSubmit(e)}>
                    <TextField
                        variant="outlined"
                        type='email'
                        label='E-posta adresi'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                        fullWidth
                        margin="normal"
                    />
                    {
                        loading ? (
                            <div>
                                <CircularProgress
                                  size={68}
                                  style={{ marginLeft: 170, marginTop: 20 }}
                                />
                            </div>
                      ) : (
                        <Button 
                          fullWidth 
                          variant='contained' 
                          type='submit'
                          style={{ height: 50, marginTop: 30 }}
                          >
                            Devam et
                        </Button>
                      )
                    }
                  </form>
                </div>
              </Container>
            </Paper>
          </Grid>
    
        </div>
        );
      } else {
        return (
          <div>
          <Helmet>
              <meta charSet='utf-8' />
              <meta name='description' content='Şifre yenileme sayfası' />
              <title>Şifre Yenileme - Ubicro</title>
              <link rel='canonical' href='https://www.ubicro.com/reset-password' />
          </Helmet>
          <Header />
    
          <Grid container style={{ justifyContent: "center", padding: 10 }}>
            <Paper maxWidth="xs" variant="outlined" style={{ marginTop: 40, marginBottom: 50, borderRadius: 10, padding: 10 }}>
              <Container maxWidth="xs">
                <IconButton href='/login' color='default' style={{ marginBottom: 20, marginTop: 20 }}>
                  <ArrowBackIosNewIcon />
                </IconButton>
                <div>
                  <Typography 
                    variant="h4"
                    gutterBottom
                  >
                    Şifre yenileme
                  </Typography>
                  <Typography 
                    variant="body1"
                    style={{ color: "#6E6E73" }}
                    >
                    Şifre yenileme bağlantısını gönderebilmemiz için e-posta adresinize ihtiyacımız var.
                  </Typography>
                  <form onSubmit={e => onSubmit(e)}>
                    <TextField
                        variant="outlined"
                        type='email'
                        label='E-posta adresi'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                        fullWidth
                        margin="normal"
                    />
                    {
                        loading ? (
                            <div>
                                <CircularProgress
                                  size={68}
                                  style={{ marginLeft: 120, marginTop: 20, marginBottom: 20 }}
                                />
                            </div>
                      ) : (
                        <Button 
                          fullWidth 
                          variant='contained' 
                          type='submit'
                          style={{ height: 50, marginTop: 20, marginBottom: 20 }}
                          >
                            Devam et
                        </Button>
                      )
                    }
                  </form>
                </div>
              </Container>
            </Paper>
          </Grid>
    
        </div>
        );
      }
    
};

const mapStateToProps = state => ({
  loading: state.auth.loading
});

export default connect(mapStateToProps, { reset_password })(ResetPassword);