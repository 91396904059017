import React, { useEffect } from "react";
import { Button, Grid, Typography, Alert } from "@mui/material";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

const GoToCart = ({ isAuthenticated }) => {
  let width = window.innerWidth;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const checkoutButton = () => {
    if (!isAuthenticated & (width > 500)) {
      return (
        <Button
          variant="contained"
          href="/login"
          style={{
            margin: 30,
            height: 60,
            width: 250,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
            textTransform: "none",
          }}
        >
          Satın Alma Sayfasına İlerleyin
        </Button>
      );
    }
    if (isAuthenticated & (width > 500)) {
      return (
        <Button
          variant="contained"
          href="/checkout"
          style={{
            margin: 30,
            height: 60,
            width: 250,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
            textTransform: "none",
          }}
        >
          Satın Alma Sayfasına İlerleyin
        </Button>
      );
    }
    if (!isAuthenticated & (width < 500)) {
      return (
        <Button
          variant="contained"
          href="/login"
          style={{
            margin: 10,
            height: 60,
            width: 150,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
            textTransform: "none",
            textAlign: "center",
          }}
        >
          Ödemeye İlerle
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          href="/checkout"
          style={{
            margin: 10,
            height: 60,
            width: 150,
            backgroundColor: "#1683FC",
            color: "#fff",
            borderRadius: 10,
            textTransform: "none",
            textAlign: "center",
          }}
        >
          Ödemeye İlerle
        </Button>
      );
    }
  };

  if (width > 500) {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ürün sepete atıldı nasıl ilerlemek istersiniz?"
          />
          <title>Sepete İlerle - Ubicro</title>
          <link
            rel="canonical"
            href="https://www.ubicro.com/cart-or-continue-shopping"
          />
        </Helmet>
        <Header />
        <Alert severity="success" style={{ justifyContent: "center" }}>
          Ürün sepetinize eklendi, satın alma sayfasında kullanıcı tanımlamanız
          istenilecektir
        </Alert>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{
            marginTop: 50,
            padding: 20,
            marginBottom: 100,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Ürün sepetinize eklendi
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ color: "grey", marginBottom: 20 }}
          >
            Sepetinizi gözden geçirmek istermisiniz?
          </Typography>
          <Grid item style={{ marginTop: 20 }}>
            <Button
              href="/sepetim"
              variant="contained"
              style={{
                margin: 30,
                height: 60,
                width: 250,
                backgroundColor: "#1683FC",
                color: "#fff",
                borderRadius: 10,
                textTransform: "none",
              }}
            >
              Sepetinizi Gözden Geçirin
            </Button>
            {checkoutButton()}
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ürün depete atıldı nasıl ilerlemek istersiniz?"
          />
          <title>Sepete İlerle - Ubicro</title>
          <link
            rel="canonical"
            href="https://www.ubicro.com/cart-or-continue-shopping"
          />
        </Helmet>
        <Header />
        <Alert severity="success" style={{ justifyContent: "center" }}>
          Ürün sepetinize eklendi, satın alma sayfasında kullanıcı tanımlamanız
          istenilecektir
        </Alert>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{
            marginTop: 10,
            padding: 10,
            marginBottom: 50,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Ürün sepetinize eklendi
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ color: "grey", marginBottom: 20 }}
          >
            Sepetinizi gözden geçirmek istermisiniz?
          </Typography>
          <Grid item>
            <Button
              href="/sepetim"
              variant="contained"
              style={{
                margin: 10,
                height: 60,
                width: 150,
                backgroundColor: "#1683FC",
                color: "#fff",
                borderRadius: 10,
                textTransform: "none",
                textAlign: "center",
              }}
            >
              Sepete İlerle
            </Button>
            {checkoutButton()}
          </Grid>
        </Grid>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(GoToCart);
