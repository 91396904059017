import React, { Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
} from "@mui/material";

// Image imports
import TumUrunler from "../assets/images/TumUrunler.jpg";
import UbiHouse from "../assets/images/UbiHouse.jpg";
import UbiHouseMini from "../assets/images/UbiHouseMini.jpg";
import UbiHouseProMini from "../assets/images/UbiHouseProMini.jpg";
import UbiHousePro from "../assets/images/UbiHousePro.jpg";

const ListItemUbiHouse = () => {
  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 40 }}
        >
          <Grid item container direction="row">
            <Typography variant="h5" style={{ marginRight: 5 }}>
              UbiHouse.
            </Typography>
            <Typography variant="h5" style={{ color: "#6E6E73" }}>
              İstediğiniz yerde üretin!
            </Typography>
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 40,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/ubihouse-nedir">
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse Nedir?
                  </Typography>
                  <Typography color="textSecondary">
                    Daha sağlıklı, hem sizin hem de dünyamız için.
                  </Typography>
                </Grid>
              </CardContent>
              <CardMedia
                component="img"
                alt="Tum UbiHouselar"
                src={TumUrunler}
                title="Tum UbiHouselar"
                style={{
                  height: 240,
                  marginTop: 70,
                }}
              />
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 30,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubihouse-mini">
              <CardMedia
                component="img"
                alt="UbiHouse Mini"
                src={UbiHouseMini}
                title="UbiHouse Mini"
                style={{
                  height: 250,
                  width: 400,
                  marginBottom: 20,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse Mini
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    13,500 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 30,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubihouse">
              <CardMedia
                component="img"
                alt="UbiHouse"
                src={UbiHouse}
                title="UbiHouse"
                style={{
                  height: 250,
                  width: 400,
                  marginBottom: 20,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    24,000 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 30,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubihouse-pro">
              <CardMedia
                component="img"
                alt="UbiHousePro"
                src={UbiHouseProMini}
                title="UbiHousePro"
                style={{
                  height: 250,
                  width: 400,
                  marginBottom: 20,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse Pro
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    32,000 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 400,
              borderRadius: 20,
              marginLeft: 30,
              marginRight: 50,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubihouse-pro-max">
              <CardMedia
                component="img"
                alt="UbiHouseProMax"
                src={UbiHousePro}
                title="UbiHouseProMax"
                style={{
                  height: 250,
                  width: 400,
                  marginBottom: 20,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse Pro Max
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    58,000 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          style={{ marginTop: 100, paddingLeft: 20 }}
        >
          <Grid item container direction="row">
            <Typography variant="h5" style={{ marginRight: 5 }}>
              UbiHouse.
            </Typography>
            <Typography variant="h5" style={{ color: "#6E6E73" }}>
              Evinizde üretime başlayın.
            </Typography>
          </Grid>
        </Grid>

        <div
          style={{
            backgroundColor: "#F5F5F7",
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            marginTop: 10,
          }}
        >
          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/ubihouse-nedir">
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse Nedir?
                  </Typography>
                  <Typography color="textSecondary">
                    Daha sağlıklı, hem sizin hemde <br /> dünyamız için.
                  </Typography>
                </Grid>
              </CardContent>
              <CardMedia
                component="img"
                alt="Tum UbiHouselar"
                src={TumUrunler}
                title="Tum UbiHouselar"
                style={{
                  height: 190,
                  width: 320,
                  marginTop: 70,
                }}
              />
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubihouse-mini">
              <CardMedia
                component="img"
                alt="UbiHouse Mini"
                src={UbiHouseMini}
                title="UbiHouse Mini"
                style={{
                  height: 220,
                  width: 300,
                  marginBottom: 40,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse Mini
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    13,500 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubihouse">
              <CardMedia
                component="img"
                alt="UbiHouse"
                src={UbiHouse}
                title="UbiHouse"
                style={{
                  height: 220,
                  width: 300,
                  marginBottom: 40,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    24,000 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubihouse-pro">
              <CardMedia
                component="img"
                alt="UbiHouseProMini"
                src={UbiHouseProMini}
                title="UbiHouseProMini"
                style={{
                  height: 220,
                  width: 300,
                  marginBottom: 40,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse Pro
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    32,000 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card
            style={{
              width: 310,
              height: 400,
              borderRadius: 20,
              marginLeft: 20,
              marginRight: 20,
            }}
            className="scrollMenuComponent"
          >
            <CardActionArea href="/product/ubihouse-pro-max">
              <CardMedia
                component="img"
                alt="UbiHousePro"
                src={UbiHousePro}
                title="UbiHousePro"
                style={{
                  height: 220,
                  width: 300,
                  marginBottom: 40,
                }}
              />
              <CardContent>
                <Grid>
                  <Typography gutterBottom variant="h5">
                    UbiHouse Pro Max
                  </Typography>
                  <Typography color="textSecondary" style={{ marginTop: 50 }}>
                    58,000 TL
                  </Typography>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Fragment>
    );
  }
};

export default ListItemUbiHouse;
